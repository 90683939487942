import { Fragment, useMemo } from "react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UncontrolledTooltip } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import "./CountyLabel.scss";

export const CountyLabel = ({ label = '', ...county }) => {
    const uuid = useMemo(() => uuidv4(), []);

    if (!county || !county.name) {
        return label;
    }

    if (!county.usCountyGroups || !county.usCountyGroups.length) {
        return (
            <span>
                {county.name}
            </span>
        );
    }

    const id = `county-details-${county.id}-${uuid}`;

    return (
        <>
            <span
                id={id}
                className="county-label"
                aria-describedby={`${id}-tooltip`}
            >
                {county.name}

                <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="ml-1"
                />
            </span>

            <UncontrolledTooltip
                placement="bottom"
                target={id}
                id={`${id}-tooltip`}
            >
                    This county is subject to the following local agreement(s).
                <br />

                {county.usCountyGroups.map(group => {
                    const groupType = group.groupType || group.usCountyGroupType?.description;

                    return (
                        <Fragment key={group.id}>
                            <br />
                            {groupType}: {group.description}
                        </Fragment>
                    );
                })}
            </UncontrolledTooltip>
        </>
    );
};