import React from 'react';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import cls from 'classnames';
import { ApiRoutes } from '../Common';
import FileLink from '../common/forms/FileLink';
import { util } from '../Util';

export default class JobFileAttachments extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showConfirmation: false, selectedFile: null };
    }

    closeConfirmation = () => this.setState({ showConfirmation: false });

    render() {
        const { showConfirmation } = { ...this.state };
        const { job } = this.props;
        const { onRemoveAttachment } = this.props;
        return (
            <div className={cls('file-attachments', this.props.className)}>
                {
                    (job.uploads ?? []).map((file) => (
                        <FileLink
                            showFileSize
                            key={file.name}
                            onDownload={() => {
                                const url = ApiRoutes.job.attachment(job.id);
                                util.fetch.downloadFile(url, file, file.name);
                            }}
                            file={file}
                            remove={() => {
                                this.setState({ selectedFile: file, showConfirmation: true });
                            }}
                            viewOnly={this.props.viewOnly}
                        />
                    ))
                }
                {
                    !!onRemoveAttachment
                    && (
                        <Modal keyboard={false} backdrop="static" isOpen={showConfirmation}>
                            <ModalHeader>Delete File Attachment</ModalHeader>
                            <ModalBody>
                                <span>
                          Are you sure you want to delete
                                    <strong>{`${(this.state.selectedFile ?? {}).name ?? ''}?`}</strong>
                                </span>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        onRemoveAttachment(this.state.selectedFile);
                                        this.closeConfirmation();
                                    }}
                                >
                          Yes, remove the attachment
                                </Button>
                                <Button color="secondary" onClick={this.closeConfirmation}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                    )
                }
            </div>
        );
    }
}
