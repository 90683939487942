export class PaySchedule {
    id = null;

    governingBody = '';

    stateId = 0;

    Counties = '';

    Reg = 0.0;

    OT = 0.0;

    Fringe = 0.0;
}
