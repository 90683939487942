import React from 'react';
import cls from 'classnames';
import { CircularProgress } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSave,
    faMinusCircle,
    faTimes,
    faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Row, Col, FormGroup } from 'reactstrap';
import {
    FormLabel,
    FormValidated,
    SmallButton,
} from '../common/forms/FormElements';
import CommonContext, { ApiRoutes } from '../Common';
import { util } from '../Util';
import ValidationMessageDisplay from '../common/forms/ValidationMessageDisplay';
import ValidatedSelect from '../common/forms/ValidatedSelect';

export class EmployeeRestriction {
    id = null;

    employeeId = null;

    companyId = null;

    companyName = '';

    foremenId = null;

    foremenName = '';

    blockAll = 'true';
}

export class EmployeeRestrictionForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            formValidated: false,
            errors: {},
            companies: [],
            foremen: [],
            restriction: this.props.restriction ?? new EmployeeRestriction(),
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onCompanyChanged = this.onCompanyChanged.bind(this);
    }

    componentDidMount() { return this.populateState(); }

    async componentDidUpdate(prevProps) {
        if (!prevProps.restriction && !!this.props.restriction) {
            const { restriction } = this.props;
            if (restriction.companyId) {
                await this.onCompanyChanged({ id: restriction.companyId });
            }
        }
    }

    onClose(response) {
        const { onClose } = this.props;
        this.resetForm();
        this.context.setFormOpened(false);
        onClose();
    }

    onCompanyChanged = async (value) => {
        const { companies } = this.state;
        const { onCompanyChanged } = this.props;

        value = companies.find((c) => c.id == value.id);
        const data = await util.fetch.js(ApiRoutes.company.foremen(value.id));

        this.setState((state) => {
            let { foremen } = this.state;
            foremen = data;
            return { foremen };
        });

        onCompanyChanged(value);
    };

    onSubmit = async (e) => {
        const { restriction, onSaveCallback } = this.props;

        if (restriction.blockAll == 'true') {
            restriction.foremenId = null;
            restriction.foremenName = '';
        }

        onSaveCallback();
    };

    async populateState() {
        const { user } = this.context;
        // get customer companies under the parent company of this users dispatch location?
        const data = await Promise.all([
            util.fetch.js(ApiRoutes.company.customerCompanies(user.tenantId)),
        ]);

        this.setState((state) => ({
            companies: data[0],
            loading: false,
        }));
    }

    resetForm = () => this.setState({ formValidated: false });

    restrictionExistsInDatabase = () => {
        const { restriction } = this.state;

        return Boolean(restriction && restriction.id);
    };

    render() {
        const { loading } = this.state;

        if (loading) {
            return (
                <div className="d-flex flex-row h-100 align-items-center justify-content-center">
                    <CircularProgress
                        variant="indeterminate"
                        color="secondary"
                    />
                </div>
            );
        }

        const {
            errors, validationMessage, companies, foremen,
        } = this.state;
        const {
            restriction,
            setIsValidated,
            isValidated,
            show,
            onForemenChanged,
            onChange,
        } = this.props;

        const isRestrictionAlreadySaved = this.restrictionExistsInDatabase();

        if (!restriction) {
            return '';
        }

        const classNames = cls('slide-form col-xl-3 col-md-6 col-sm-12', {
            show,
        });

        return (
            <div className={classNames}>
                <FormValidated
                    ref={this.formRef}
                    setIsValidated={setIsValidated}
                    isValidated={isValidated}
                    className="m-0 pb-2 w-100"
                    id="restrictionForm"
                    name="restrictionForm"
                    onSubmit={this.onSubmit}
                >
                    <header className="border-bottom d-flex flex-column position-relative mb-3">
                        <span
                            id="restrictionFormClose"
                            className="cursor-pointer site-form-close"
                            onClick={this.onClose}
                        >
                            <FontAwesomeIcon size="lg" icon={faTimes} />
                        </span>
                        <h5>
                            <div className="d-flex flex-row align-items-center mb-2">
                                <FontAwesomeIcon
                                    className="mr-2 text-muted"
                                    size="lg"
                                    icon={faMinusCircle}
                                />
                                <span>
                                    {restriction.id
                                        ? 'Edit Restriction'
                                        : 'New Restriction'}
                                </span>
                            </div>
                        </h5>
                        <div className="d-flex flex-row align-items-center text-muted">
                            <SmallButton
                                type="submit"
                                name=""
                                onClick={this.onSave}
                            >
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faSave}
                                />
                Save
                            </SmallButton>
                            {isRestrictionAlreadySaved ? (
                                <SmallButton onClick={this.onDelete}>
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faTrashAlt}
                                    />
                  Remove
                                </SmallButton>
                            ) : (
                                ''
                            )}
                        </div>
                    </header>
                    <ValidationMessageDisplay
                        onClear={this.onClearErrorNotificationClicked}
                        errors={errors}
                        message={validationMessage}
                    />
                    <Row className="ml-0 mr-0">
                        <Col>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="customer"
                                    text="Customer"
                                    required
                                />
                                <ValidatedSelect
                                    id="customer"
                                    name="customer"
                                    required
                                    options={companies}
                                    value={
                                        (companies ?? []).find(
                                            (s) => s.id === restriction.companyId,
                                        ) ?? ''
                                    }
                                    onChange={this.onCompanyChanged}
                                    getOptionLabel={(option) => option.companyName}
                                    getOptionValue={(option) => option.id}
                                    validationMessage="A customer selection is required."
                                />
                            </FormGroup>
                            <FormGroup>
                                <div className="ml-3 mt-1 d-flex flex-row align-items-center">
                                    <span className="pr-2">Block All</span>
                                    <input
                                        type="checkbox"
                                        className="d-none chk-i"
                                        name="blockAll"
                                        id="blockAll"
                                        checked={
                                            restriction.blockAll.toString()
                                            == 'true'
                                        }
                                        onChange={onChange}
                                    />
                                    <label
                                        className="chk p-0 mb-0"
                                        htmlFor="blockAll"
                                    />
                                </div>
                            </FormGroup>
                            {restriction.blockAll.toString() !== 'true' && (
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="foremen"
                                        text="Foremen"
                                        required
                                    />
                                    <ValidatedSelect
                                        id="foremen"
                                        name="foremen"
                                        required
                                        options={foremen}
                                        value={
                                            (foremen ?? []).find(
                                                (s) => s.id
                                                    === restriction.foremenId,
                                            ) ?? ''
                                        }
                                        onChange={onForemenChanged}
                                        getOptionLabel={(option) => option.fullName}
                                        getOptionValue={(option) => option.id}
                                        validationMessage="A foremen selection is required."
                                    />
                                </FormGroup>
                            )}
                        </Col>
                    </Row>
                </FormValidated>
            </div>
        );
    }
}
