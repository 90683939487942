import React from 'react';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import CommonContext, { ApiRoutes } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import HistoricalChangeCollection from './HistoricalChangeCollection';

export default class EmployeeHistoryForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        this.state = {
            employee: {},
            employeeHistory: {},
            employeePayRateHistory: {},
            employeeLocationHistory: {},
            employeeRestrictionHistory: {},
            employeeLongTermStatusHistory: {},
            employeeGroupHistory: {},
            formTitle: 'Employee History',
            loading: true,
            show: false,
            saveDisabled: true,
        };

        this.onCloseClick = this.onCloseClick.bind(this);
    }

    componentDidMount() { return this.populateState(); }

    onCloseClick = () => {
        const { onClose } = this.props;

        this.context.setFormOpened(false);
        this.setState({ show: false, employee: {}, employeeHistory: {} });

        if (onClose) {
            onClose();
        }
    };

    open = async (employee) => {
        this.context.setFormOpened(true);

        await this.setState({ show: true, loading: true });

        const [
            employeeHistory,
            employeePayRateHistory,
            employeeLocationHistory,
            employeeRestrictionHistory,
            employeeLongTermStatusHistory,
            employeeGroupHistory,
        ] = await Promise.all([
            util.fetch.js(ApiRoutes.employeeHistory.byId(employee.id)),
            util.fetch.js(ApiRoutes.employeePayRateHistory.byId(employee.id)),
            util.fetch.js(ApiRoutes.employeeLocationHistory.byId(employee.id)),
            util.fetch.js(
                ApiRoutes.employeeRestrictionHistory.byId(employee.id),
            ),
            util.fetch.js(
                ApiRoutes.employeeLongTermStatusHistory.byId(employee.id),
            ),
            util.fetch.js(ApiRoutes.employeeGroupHistory.byId(employee.id)),
        ]);

        this.setState({
            employee,
            employeeHistory,
            employeePayRateHistory,
            employeeLocationHistory,
            employeeRestrictionHistory,
            employeeLongTermStatusHistory,
            employeeGroupHistory,
            loading: false,
        });
    };

    populateState = async () => {
        this.setState({
            loading: false,
        });
    };

    render() {
        const {
            employee,
            employeeHistory,
            employeePayRateHistory,
            employeeLocationHistory,
            employeeRestrictionHistory,
            employeeLongTermStatusHistory,
            employeeGroupHistory,
            saveDisabled,
            formTitle,
            loading,
            show,
        } = this.state;

        if (!employee) {
            return null;
        }

        return (
            <SlideForm
                loading={loading}
                show={show}
                id={this.props.id ?? 'employeeHistoryForm'}
                formIcon={faTasks}
                formTitle={formTitle}
                ref={this.formRef}
                onClose={this.onCloseClick}
                saveDisabled={saveDisabled}
            >
                <HistoricalChangeCollection
                    heading="Groups"
                    historicalChangeCollection={employeeGroupHistory}
                />

                <HistoricalChangeCollection
                    heading="General Information"
                    historicalChangeCollection={employeeHistory}
                />

                <HistoricalChangeCollection
                    heading="Pay Rate"
                    historicalChangeCollection={employeePayRateHistory}
                    renderOptions={{ simpleDate: true }}
                />

                <HistoricalChangeCollection
                    heading="Addresses"
                    historicalChangeCollection={employeeLocationHistory}
                />

                <HistoricalChangeCollection
                    heading="Restrictions"
                    historicalChangeCollection={employeeRestrictionHistory}
                />

                <HistoricalChangeCollection
                    heading="Long-Term Statuses"
                    historicalChangeCollection={employeeLongTermStatusHistory}
                    renderOptions={{ simpleDate: true }}
                />
            </SlideForm>
        );
    }
}
