import React from 'react';
import {
    Button,
    FormGroup,
} from 'reactstrap';
import SignatureCanvas from 'react-signature-canvas';
import throttle from 'lodash-es/throttle';
import { FormLabel, FlexBetweenRow, FlexEndRow } from './FormElements';

export default class SignatureButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSignatureModal: false,
            signatureValid: false,
            signatureValidated: false,
        };

        // Make our in-memory canvas
        this.inMemCanvas = document.createElement('canvas');
        this.inMemCtx = this.inMemCanvas.getContext('2d');

        // debounce the resize event, or it gets fired way too many times.
        this.resizeSigCanvas = throttle(this.resizeSigCanvas, 1000);

        window.onresize = () => {
            this.resizeSigCanvas();
        };
    }

    componentWillUnmount() {
        window.onresize = null;
    }

    onCancel = () => {
        this.cSigPad.clear();
        this.setState({
            showSignatureModal: false,
        });
    };

    onClear = () => {
        this.cSigPad.clear();
    };

    onSaveSignature = (e) => {
        if (!this.isSigBlank()) {
            this.props.onSigSavedCallback(this.getSignatureData());
            this.setState({ signatureValidated: true, signatureValid: true, showSignatureModal: false });
        } else {
            this.props.onSigSavedCallback('');
            this.setState({ signatureValidated: true, signatureValid: false });
        }
    };

    getSignatureData = () => this.cSigPad.getCanvas().toDataURL('image/png');

    setRef = (ref) => this.ref = ref;

    setSignatureData = (signatureData) => {
        this.cSigPad.fromDataURL(signatureData ?? null);
    };

    cSigPad = {}; // sig pad canvas instance.

    inMemCanvas = {}; // mem canvas create in the DOM for resizing.

    inMemCtx = {}; // mem canvas context.

    isMemCanvasBlank() {
        if (this.inMemCanvas.width === 0 || this.inMemCanvas.height === 0) return true;

        const pixelBuffer = new Uint32Array(
            this.inMemCtx.getImageData(0, 0, this.inMemCanvas.width, this.inMemCanvas.height).data.buffer,
        );
        return !pixelBuffer.some((color) => color !== 0);
    }

    isSigBlank() {
        const canvas = document.querySelector(`#${this.props.id} canvas`);
        if ((canvas?.width ?? 0) === 0 || (canvas?.height ?? 0) === 0) return true;
        const context = canvas.getContext('2d');
        const pixelBuffer = new Uint32Array(
            context.getImageData(0, 0, canvas.width, canvas.height).data.buffer,
        );
        return !pixelBuffer.some((color) => color !== 0);
    }

    isSignatureValid = () => this.state.signatureValid;

    isSignatureValidated = () => this.state.signatureValidated;

    // RLC: HTML5 canvas must be resized explicitly on viewport//parent resizing.
    //     In order to acheive this, we create a ghost canvas and then copy the sig
    //     to it, and transfer back after the original canvas is resized.
    //     React-signature-canvas doesn't handle this.
    resizeSigCanvas = () => {
        setTimeout(() => {
            const canvas = document.querySelector(`#${this.props.id} canvas`);
            const canvasCtx = canvas.getContext('2d');
            const canvasParent = canvas.parentElement;

            // RLC: Only resize if unequal - otherwise, canvas will wipe the
            //     image (and the context) on resize.
            if (canvas) {
                this.inMemCanvas.width = canvas.width;
                this.inMemCanvas.height = canvas.height;

                this.inMemCanvas.width > 0 && this.inMemCanvas.height > 0
                    && this.inMemCtx.drawImage(canvas, 0, 0);

                let resized = false;
                if (canvas.width !== canvasParent.clientWidth) {
                    canvas.width = canvasParent.clientWidth;
                    resized = true;
                }
                if (canvas.height !== canvasParent.clientHeight) {
                    canvas.height = canvasParent.clientHeight;
                    resized = true;
                }
                if (!!resized && !this.isMemCanvasBlank()) {
                    const img = this.inMemCanvas;
                    canvasCtx.drawImage(
                        img,
                        0,
                        0,
                        img.width,
                        img.height, // source rectangle
                        0,
                        0,
                        canvas.width,
                        canvas.height,
                    ); // destination rectangle
                }
            }
        }, 0);
    };

    render() {
        const {
            showSignatureModal,
            signatureValid,
            signatureValidated,
        } = { ...this.state };

        const validSigId = `validSignature_${this.props.id}`;

        return (
            <FormGroup>
                <FormLabel for={validSigId} text="Signature:" required />
                <Button
                    type="button"
                    color={!signatureValid ? 'primary' : 'success'}
                    onClick={() => {
                        if (this.props.sigData) this.setSignatureData(this.props.sigData);
                        this.resizeSigCanvas();
                        this.setState({ showSignatureModal: true });
                    }}
                >
                    <span>
                        {!!signatureValid && <span className="fa fa-check-circle mr-2" />}
                        {!signatureValid
                            && (
                                <span className="fa fa-signature mr-2" />
                            )}
                        {!!this.props.signatureLabel && <span className="mr-1">{this.props.signatureLabel}</span>}
            Signature
                    </span>
                </Button>
                <input id={validSigId} type="text" className="hidden-input-validation" defaultValue={signatureValid ? 'true' : ''} required />
                <small className="invalid-feedback text-danger w-100 text-right">
                    <b>Error:&nbsp;</b>
          A signature is required.
                </small>
                <div
                    className="signature-modal h-100 w-100"
                    hidden={!showSignatureModal}
                >
                    <div className="signature-modal-dialog">
                        <div className="signatureWrapper">
                            <FormLabel style={{}} for={this.props.id} text="Signature:" required />
                            <div id={this.props.id} className={`cSignature ${signatureValidated && !signatureValid ? 'border border-danger' : 'border border-dark'}`}>
                                <SignatureCanvas
                                    clearOnResize={false}
                                    ref={(ref) => this.cSigPad = ref}
                                />
                            </div>
                        </div>

                        <FlexBetweenRow className="signature-modal-toolbar">
                            <div>
                                {!!signatureValidated && !signatureValid && <small className="text-danger">A signature is required.</small>}
                            </div>
                            <FlexEndRow>
                                <Button size="sm" color="primary" className="mr-3" onClick={() => this.onSaveSignature()}>Save</Button>
                                <Button size="sm" className="btn mr-3" onClick={this.onClear}>Clear</Button>
                                <Button size="sm" color="outline-danger" onClick={this.onCancel}>Cancel</Button>
                            </FlexEndRow>
                        </FlexBetweenRow>
                    </div>
                </div>
            </FormGroup>
        );
    }
}
