export class EmployeeLongTermStatus {
    id = 0;

    effectiveDate = new Date();

    expirationDate = null;

    longTermStatusId = 0;

    employeeId = 0;
}
