import React, { Component } from 'react';
import { Can } from './Can';
import {
    Navbar,
    NavbarBrand,
    NavLink,
    ListGroupItem,
    Collapse,
    Badge,
    Button,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import '../style/NavMenu.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMoneyCheck,
    faFileInvoice,
    faCog,
    faBars,
    faFileContract,
    faWarehouse,
    faAngleUp,
    faAngleDown,
    faUser,
    faToolbox,
    faCalendar,
    faTasks,
    faCalendarDay,
    faCheckCircle,
    faClock,
    faAddressBook,
    faComments,
    faUserClock,
} from '@fortawesome/free-solid-svg-icons';
import authService from './api-authorization/AuthorizeService';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import CommonContext, {
    AppNavPaths,
    ApplicationPermissions,
    ApiRoutes,
    LocalizationKeys as l,
} from './Common';
import {
    FlexCenterRow,
    FlexEndRow,
    FlexStartRow,
} from './common/forms/FormElements';
import { util } from './Util';
import { getTenantUserProfile } from './common/TenantUserProfile';
import GitInfo from 'react-git-info/macro';

class NavMenuItem extends Component {
    static contextType = CommonContext;

    render() {
        const { permissions } = { ...this.context };
        const perms = (permissions ?? []).map(
            (x) => `${x.permission}.${x.action}`
        );

        if (!perms.includes(`${this.props.entity}.${this.props.action}`))
            return null;

        const label_className = `mr-2 cursor-pointer${
            !this.props.isChild ? ' font-weight-bold' : ' text-info'
        }`;

        return (
            <ListGroupItem className="flex-row-nowrap-left p-1 pr-2">
                <NavLink
                    tag={Link}
                    className="text-light p-1 flex-fill cursor-pointer d-flex flex-row flex-nowrap align-items-center"
                    to={this.props.searchUrl}
                    title={this.props.searchTitle}
                    onClick={this.props.onNavLinkClicked}
                >
                    {this.props.icon && (
                        <FontAwesomeIcon
                            icon={this.props.icon}
                            className="menu-icon cursor-pointer"
                        />
                    )}
                    {!this.props.icon && <i className="fa fa-fw menu-icon"></i>}
                    <label className={label_className}>
                        {this.props.label}
                    </label>
                    {!!this.props.notificationCount && (
                        <span className="nav-menu-notification-badge badge badge-lg badge-danger ml-1">
                            {this.props.notificationCount}
                        </span>
                    )}
                </NavLink>
                {!this.props.hideNew && (
                    <Can I="create" a={this.props.entity}>
                        <NavLink
                            tag={Link}
                            className="text-light p-1 cursor-pointer"
                            to={this.props.newUrl}
                            title={this.props.newTitle}
                            onClick={this.props.onNavLinkClicked}
                        >
                            <span className=" fa fa-plus-circle fa-lg text-muted cursor-pointer"></span>
                        </NavLink>
                    </Can>
                )}
                {this.props.children}
            </ListGroupItem>
        );
    }
}

//Actions that require admins.
const adminEntitiesActions = [
    ApplicationPermissions.chargetype_view,
    ApplicationPermissions.company_view,
    ApplicationPermissions.chargetypeunit_view,
    ApplicationPermissions.contractstatus_view,
    ApplicationPermissions.contractTemplate_view,
    ApplicationPermissions.employeeaccountstatus_view,
    ApplicationPermissions.equipmenttype_view,
    ApplicationPermissions.holiday_view,
    ApplicationPermissions.equipmentstatus_view,
];

class NavMenu extends Component {
    static displayName = NavMenu.name;
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            userName: null,
            collapseId: '',
            companySwitchOpen: false,
            notifications: null,
        };
        this.onLogoutClicked = this.onLogoutClicked.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user, strings] = await Promise.all([
            authService.isAuthenticated(),
            authService.getUser(),
            util.l10n.getStrings([l.billing]),
        ]);

        let hasSelfDispatchingAccess = false;
        let hasNonFlaggingAccess = false;
        let hasMyTimesheetsAccess = false;
        var notifications;

        let userProfile = null;

        if (isAuthenticated) {
            const [access, tenantUserProfile] = await Promise.all([
                util.fetch.js(ApiRoutes.contract.userHasSelfDispatching()),
                getTenantUserProfile(),
            ]);

            userProfile = tenantUserProfile;

            hasSelfDispatchingAccess =
                !!access.hasSelfDispatchingAccess &&
                !!userProfile.currentUser.canCreateSelfDispatchTimesheet;
            hasNonFlaggingAccess =
                !!access.hasNonFlaggingAccess &&
                !!userProfile.currentUser.canCreateNonFlagTimesheet;

            if (
                userProfile.userTenant.tenantSettings
                    .enableSelfDispatchTimesheets
            ) {
                var count =
                    (await util.fetch.js(
                        ApiRoutes.selfDispatchTimesheet.count()
                    )) ?? 0;
                hasMyTimesheetsAccess = count > 0;
            }

            const [
                selfDispatchTimesheetNotifications,
                nonFlagTimesheetNotifications,
            ] = await Promise.all([
                hasSelfDispatchingAccess
                    ? util.fetch.js(
                        ApiRoutes.selfDispatchTimesheet.selfDispatchNotifications()
                    )
                    : { count: 0 },
                hasNonFlaggingAccess
                    ? util.fetch.js(
                        ApiRoutes.selfDispatchTimesheet.nonFlagNotifications()
                    )
                    : { count: 0 },
            ]);

            notifications = {
                selfDispatch: selfDispatchTimesheetNotifications.count,
                nonFlagging: nonFlagTimesheetNotifications.count,
            };
        }

        //TODO: Get notifictions here?

        this.setState({
            isAuthenticated,
            userName: user && user.name,
            hasSelfDispatchingAccess,
            hasNonFlaggingAccess,
            notifications,
            hasMyTimesheetsAccess,
            strings,
            userProfile,
        });
    }

    toggleMenu = (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.toggleMenuClasses();
    };

    toggleMenuClasses = () => {
        var offCanvasMenu = document.getElementById('offCanvasMenu');
        offCanvasMenu.classList.toggle('show');
        document.body.classList.toggle('offcanvas-active');
    };

    toggleMenuClosed = () => {
        var offCanvasMenu = document.getElementById('offCanvasMenu');
        offCanvasMenu.classList.remove('show');
        document.body.classList.remove('offcanvas-active');
    };

    toggleCollapse = (collapseId) => () => {
        this.setState((prevState) => ({
            collapseId: prevState.collapseId !== collapseId ? collapseId : '',
        }));
    };

    onNavLinkClicked = (event) => {
        event.preventDefault();
        let navLink = event.currentTarget;
        this.toggleMenuClosed();
        this.props.history.push(navLink.getAttribute('href'));
    };

    onLogoutClicked = (event) => {
        event.preventDefault();
        this.toggleMenuClosed();
        const logoutPath = {
            pathname: `${ApplicationPaths.LogOut}`,
            state: { local: true },
        };
        this.props.history.push(logoutPath.pathname, logoutPath.state);
    };

    onClickImpersonation = async () => {
        try {
            let response = await util.fetch.put(
                ApiRoutes.employee.toggleImpersonation(),
                { EmployeeId: null },
                util.fetch.format.none
            );
            
            if (response.redirected) {
                window.location.href = response.url;
            } else if (response.ok) {
                this.populateState();
                window.location.href = '/';
            }
        } catch (error) {
            console.warn(error);
        }
    };

    renderMenuButton() {
        return (
            <button
                data-trigger="offCanvasMenu"
                type="button shadow-none p-2"
                className="offCanvasMenuButton btn p-0 m-0"
                onClick={this.toggleMenu}
            >
                <FontAwesomeIcon size="lg" icon={faBars} />
            </button>
        );
    }

    userHasAdminFunction = () =>
        (this.context.permissions ?? [])
            .map((p) => `${p.permission}.${p.action}`)
            .some((p) => adminEntitiesActions.indexOf(p) >= 0);

    renderMenu = () => {
        /* 
         *  Remap permissions back to their original form for quick lookup.  
            If the user has at least one admin function,
            show the admin collapse menu. 
        */
        const { notifications, strings, userProfile } = { ...this.state };

        const billing = strings ? strings[l.billing] : '';

        const userHasAdminFunction = this.userHasAdminFunction();

        const { tenantSettings } = (this.context ?? {}).tenant ?? {};

        const complianceEnabled = Boolean(tenantSettings.complianceEnabled);

        const contactScreenEnabled = Boolean(
            tenantSettings.contactScreenEnabled
        );
        const contactActivitiesEnabled = Boolean(
            tenantSettings.contactActivitiesEnabled
        );

        const billingRatesEnabled = Boolean(tenantSettings.billingRatesEnabled);
        const invoicingEnabled = Boolean(tenantSettings.invoicingEnabled);
        const chargeTypeGroupsEnabled = Boolean(
            tenantSettings.chargeTypeGroupsEnabled
        );

        const activeEmployeeListEnabled = Boolean(
            tenantSettings.activeEmployeeListEnabled
        );

        const countyCaptureEnabled = Boolean(
            tenantSettings.captureCounty,
        );

        const timesheetNotificationCount =
            (notifications?.nonFlagging ?? 0) + notifications?.selfDispatch;

        //2022-10-10 - M. Nicol - Similar logic in place in Dashboard.js
        const payrollEnabled = !!(tenantSettings ?? {}).payrollEnabled;
        const hasIndiv = !!userProfile?.userPermissions?.includes(
            'payroll.viewindividual'
        );
        const payCatUrl = hasIndiv
            ? AppNavPaths.PayrollIndividual
            : AppNavPaths.Payroll;
        const payCatAction = hasIndiv ? 'viewindividual' : 'view';

        const employeeLongTermStatusManagementEnabled = Boolean(
            tenantSettings.employeeLongTermStatusManagementEnabled
        );

        const attendanceEventsEnabled = Boolean(
            tenantSettings.attendanceEventsEnabled
        );

        const gitInfo = GitInfo();

        return (
            <>
                <aside className="offcanvas offcanvas-left" id="offCanvasMenu">
                    <header className="offcanvas-header p-0 m-0 border-bottom">
                        <div className="d-flex flex-row flex-nowrap align-items-center menu-button-wrapper">
                            {this.renderMenuButton()}
                        </div>
                    </header>

                    <nav className="list-group list-group-flush">
                        <NavMenuItem
                            action={'view'}
                            entity={'contract'}
                            onNavLinkClicked={this.onNavLinkClicked}
                            label="Contracts"
                            icon={faFileContract}
                            newTitle="Add a Contract"
                            newUrl={`${AppNavPaths.ContractNew}`}
                            searchTitle="View Contracts"
                            searchUrl={`${AppNavPaths.Contracts}`}
                        />

                        <NavMenuItem
                            action={'view'}
                            entity={'job_daily_view'}
                            onNavLinkClicked={this.onNavLinkClicked}
                            label="Daily Jobs View"
                            icon={faCalendarDay}
                            searchTitle="Daily Jobs View"
                            searchUrl={`${AppNavPaths.JobView}`}
                        />

                        <NavMenuItem
                            action={'view'}
                            entity={'employee_daily_roster'}
                            onNavLinkClicked={this.onNavLinkClicked}
                            label="Roster View"
                            icon={faCalendarDay}
                            searchTitle="Roster View"
                            searchUrl={`${AppNavPaths.EmployeeDailyRoster}`}
                        />

                        <NavMenuItem
                            action={'edit'}
                            entity={'dispatch'}
                            onNavLinkClicked={this.onNavLinkClicked}
                            label="Dispatch"
                            icon={faUser}
                            searchTitle="View Dispatch"
                            searchUrl={`${AppNavPaths.Dispatch}`}
                            hideNew={true}
                        />

                        <NavMenuItem
                            action={'view'}
                            entity={'employee'}
                            onNavLinkClicked={this.onNavLinkClicked}
                            label="Employees"
                            icon={faUser}
                            hideNew={true}
                            searchTitle="View Employees"
                            searchUrl={`${AppNavPaths.Employees}`}
                        >
                            <Can I="add" a="employee">
                                <NavLink
                                    tag={Link}
                                    className="text-light p-1 cursor-pointer"
                                    to={AppNavPaths.EmployeeNew}
                                    title={'Add a new employee'}
                                    onClick={this.onNavLinkClicked}
                                >
                                    <span className="text-muted cursor-pointer fa fa-plus-circle fa-lg" />
                                </NavLink>
                            </Can>
                            <Can I="onboard" a="employee">
                                <NavLink
                                    tag={Link}
                                    className="ml-2 text-light p-1 cursor-pointer"
                                    to={AppNavPaths.EmployeeNewHire}
                                    title={'New Hire'}
                                    onClick={this.onNavLinkClicked}
                                >
                                    <span className="text-muted cursor-pointer fa fa-user-plus fa-lg" />
                                </NavLink>
                            </Can>
                        </NavMenuItem>

                        {complianceEnabled && (
                            <NavMenuItem
                                action={'view'}
                                entity={'applicationusercompliance'}
                                onNavLinkClicked={this.onNavLinkClicked}
                                label="Employee Compliances"
                                icon={faCheckCircle}
                                newUrl={`${AppNavPaths.EmployeeCompliances}`}
                                searchTitle="View Employee Compliances"
                                searchUrl={`${AppNavPaths.EmployeeCompliances}`}
                                hideNew={true}
                            />
                        )}

                        <NavMenuItem
                            action={'view'}
                            entity={'equipment'}
                            onNavLinkClicked={this.onNavLinkClicked}
                            icon={faToolbox}
                            label="Equipment"
                            newTitle="Add Equipment"
                            newUrl={`${AppNavPaths.Equipment}?new=true`}
                            searchTitle="View Equipment"
                            searchUrl={`${AppNavPaths.Equipment}`}
                        />

                        {complianceEnabled && (
                            <NavMenuItem
                                action={'view'}
                                entity={'equipmentcompliance'}
                                onNavLinkClicked={this.onNavLinkClicked}
                                label="Equipment Compliances"
                                icon={faCheckCircle}
                                newUrl={`${AppNavPaths.EquipmentCompliances}`}
                                searchTitle="View Equipment Compliances"
                                searchUrl={`${AppNavPaths.EquipmentCompliances}`}
                                hideNew={true}
                            />
                        )}

                        <NavMenuItem
                            action={'view'}
                            entity={'job'}
                            onNavLinkClicked={this.onNavLinkClicked}
                            label="Jobs"
                            icon={faFileContract}
                            searchTitle="View Jobs"
                            searchUrl={`${AppNavPaths.Jobs}`}
                            hideNew={true}
                        />

                        <NavMenuItem
                            action={'view'}
                            entity={'location'}
                            onNavLinkClicked={this.onNavLinkClicked}
                            label="Organizations"
                            icon={faWarehouse}
                            newTitle="Add Organization"
                            newUrl={`${AppNavPaths.OrganizationNew}`}
                            searchTitle="View Organizations"
                            searchUrl={`${AppNavPaths.Organizations}`}
                        />

                        {contactScreenEnabled && (
                            <NavMenuItem
                                action={'view'}
                                entity={'location_contact'}
                                onNavLinkClicked={this.onNavLinkClicked}
                                label="Contacts"
                                icon={faAddressBook}
                                searchTitle="View Contacts"
                                searchUrl={`${AppNavPaths.Contacts}`}
                                hideNew={true}
                            />
                        )}

                        {contactActivitiesEnabled && (
                            <NavMenuItem
                                action={'view'}
                                entity={'contactactivity'}
                                onNavLinkClicked={this.onNavLinkClicked}
                                label="Contact Activities"
                                icon={faComments}
                                searchTitle="View Contact Activities"
                                searchUrl={`${AppNavPaths.ContactActivities}`}
                                hideNew={true}
                            />
                        )}

                        <NavMenuItem
                            action={'view'}
                            entity={'calendar'}
                            onNavLinkClicked={this.onNavLinkClicked}
                            label="Calendar"
                            icon={faCalendar}
                            searchTitle="View Calendar"
                            searchUrl={`${AppNavPaths.AssignmentCalendar}`}
                            hideNew={true}
                        />
                        {!!tenantSettings?.enableSelfDispatchTimesheets &&
                            !!this.state.hasSelfDispatchingAccess && (
                            <NavMenuItem
                                action={'view'}
                                entity={'selfdispatchtimesheet'}
                                onNavLinkClicked={this.onNavLinkClicked}
                                label="New Self-Dispatch Timesheet"
                                icon={faUserClock}
                                newUrl={`${AppNavPaths.SelfDispatchTimesheetNew}`}
                                searchUrl={`${AppNavPaths.SelfDispatchTimesheets}`}
                            />
                        )}
                        {!!tenantSettings?.enableSelfDispatchTimesheets &&
                            !!this.state.hasNonFlaggingAccess && (
                            <NavMenuItem
                                action={'view'}
                                entity={'nonflaggingtimesheet'}
                                onNavLinkClicked={this.onNavLinkClicked}
                                label="New Non-Flagging Timesheet"
                                icon={faUserClock}
                                newUrl={`${AppNavPaths.NonFlaggingTimesheetNew}`}
                                searchUrl={`${AppNavPaths.NonFlaggingTimesheetNew}`}
                                hideNew={!this.state.hasNonFlaggingAccess}
                            />
                        )}
                        {!!tenantSettings?.enableSelfDispatchTimesheets &&
                            !!this.state.hasMyTimesheetsAccess && (
                            <NavLink
                                tag={Link}
                                className="text-light p-1 flex-fill cursor-pointer d-flex flex-row flex-nowrap align-items-center"
                                to={AppNavPaths.EmployeeTimesheets}
                                title={'My Timesheets'}
                                onClick={this.onNavLinkClicked}
                            >
                                <FontAwesomeIcon
                                    icon={faClock}
                                    className="menu-icon cursor-pointer"
                                />
                                <label>
                                    <b>My Timesheets</b>
                                </label>
                                {!!timesheetNotificationCount && (
                                    <span className="nav-menu-notification-badge badge badge-lg badge-danger ml-2">
                                        {timesheetNotificationCount}
                                    </span>
                                )}
                            </NavLink>
                        )}
                        <NavMenuItem
                            action={'view'}
                            entity={'dispatch_review'}
                            onNavLinkClicked={this.onNavLinkClicked}
                            label="Timesheet Management"
                            icon={faTasks}
                            searchTitle="View Timesheet Management"
                            searchUrl={`${AppNavPaths.TimesheetManagement}`}
                            hideNew={true}
                        />

                        <NavMenuItem
                            action={'view'}
                            entity={'dispatch_review'}
                            onNavLinkClicked={this.onNavLinkClicked}
                            label="Charge View"
                            icon={faTasks}
                            searchTitle="Charge View"
                            searchUrl={`${AppNavPaths.ChargeView}`}
                            hideNew={true}
                        />

                        {!!(tenantSettings ?? {}).billingEnabled && (
                            <>
                                <NavMenuItem
                                    action={'view'}
                                    entity={'billing'}
                                    onNavLinkClicked={this.onNavLinkClicked}
                                    label={billing}
                                    icon={faFileInvoice}
                                    searchTitle={billing}
                                    searchUrl={`${AppNavPaths.Billing}`}
                                    hideNew={true}
                                />

                                {invoicingEnabled && (
                                    <>
                                        <NavMenuItem
                                            action={'view'}
                                            entity={'billing'}
                                            onNavLinkClicked={
                                                this.onNavLinkClicked
                                            }
                                            label="Billing: Charge Categories"
                                            icon={faFileInvoice}
                                            searchTitle="Billing Charge Categories"
                                            searchUrl={`${AppNavPaths.BillingChargeCategory}`}
                                            hideNew={true}
                                        />

                                        <NavMenuItem
                                            action={'view'}
                                            entity={'invoice'}
                                            onNavLinkClicked={
                                                this.onNavLinkClicked
                                            }
                                            label="Invoices"
                                            icon={faFileInvoice}
                                            searchTitle="Invoices"
                                            searchUrl={`${AppNavPaths.Invoice}`}
                                            hideNew={true}
                                        />
                                    </>
                                )}

                                {userHasAdminFunction && invoicingEnabled && (
                                    <>
                                        <NavMenuItem
                                            action={'view'}
                                            entity={'billing'}
                                            onNavLinkClicked={
                                                this.onNavLinkClicked
                                            }
                                            label="Billing: Charges"
                                            icon={faFileInvoice}
                                            searchTitle="Billing Charges"
                                            searchUrl={`${AppNavPaths.BillingCharge}`}
                                            hideNew={true}
                                        />

                                        {billingRatesEnabled && (
                                            <NavMenuItem
                                                action={'view'}
                                                entity={'billing'}
                                                onNavLinkClicked={
                                                    this.onNavLinkClicked
                                                }
                                                label="Billing Rates"
                                                icon={faFileInvoice}
                                                searchTitle="Billing Rates"
                                                searchUrl={`${AppNavPaths.BillingRate}`}
                                                hideNew={true}
                                            />
                                        )}

                                        <NavMenuItem
                                            action={'view'}
                                            entity={'invoice'}
                                            onNavLinkClicked={
                                                this.onNavLinkClicked
                                            }
                                            label="Invoice Generation"
                                            icon={faFileInvoice}
                                            searchTitle="Invoice Generation"
                                            searchUrl={`${AppNavPaths.InvoiceGeneration}`}
                                            hideNew={true}
                                        />
                                    </>
                                )}
                            </>
                        )}

                        {!!(tenantSettings ?? {}).payrollEnabled && (
                            <NavMenuItem
                                action={'view'}
                                entity={'payroll'}
                                onNavLinkClicked={this.onNavLinkClicked}
                                label="Payroll: Charges"
                                icon={faMoneyCheck}
                                searchTitle="Payroll: Charges"
                                searchUrl={`${AppNavPaths.PayrollManagement}`}
                                hideNew={true}
                            />
                        )}

                        {payrollEnabled && (
                            <NavMenuItem
                                action={payCatAction}
                                entity={'payroll'}
                                onNavLinkClicked={this.onNavLinkClicked}
                                label="Payroll: Pay Categories"
                                icon={faMoneyCheck}
                                searchTitle="Payroll: Pay Categories"
                                searchUrl={payCatUrl}
                                hideNew={true}
                            />
                        )}

                        {userHasAdminFunction ? (
                            <>
                                <ListGroupItem
                                    className="flex-row-nowrap-left p-1 pr-2 cursor-pointer"
                                    onClick={this.toggleCollapse(
                                        'adminCollapse'
                                    )}
                                >
                                    <div className="flex-fill p-1 cursor-pointer">
                                        <FontAwesomeIcon
                                            icon={faCog}
                                            className="menu-icon"
                                        />
                                        <label className="mr-2 font-weight-bold">
                                            Administrative
                                        </label>
                                    </div>
                                    <FontAwesomeIcon
                                        icon={
                                            this.state.collapseId ===
                                            'adminCollapse'
                                                ? faAngleUp
                                                : faAngleDown
                                        }
                                        className="mr-2 text-muted"
                                        size="lg"
                                    />
                                </ListGroupItem>

                                <Collapse
                                    isOpen={
                                        this.state.collapseId ===
                                        'adminCollapse'
                                    }
                                >
                                    <NavMenuItem
                                        isChild={true}
                                        action={'view'}
                                        entity={'chargetype'}
                                        onNavLinkClicked={this.onNavLinkClicked}
                                        label="Charge Types"
                                        newTitle="Add a Charge Type"
                                        newUrl={`${AppNavPaths.ChargeTypeNew}`}
                                        searchTitle="View Charge Types"
                                        searchUrl={`${AppNavPaths.ChargeTypes}`}
                                    />
                                    {
                                        //Charge Type Groups setting is dependent on invoicingEnabled.
                                        invoicingEnabled &&
                                            chargeTypeGroupsEnabled && (
                                            <NavMenuItem
                                                isChild={true}
                                                action={'view'}
                                                entity={'chargetype'}
                                                onNavLinkClicked={
                                                    this.onNavLinkClicked
                                                }
                                                label="Charge Type Groups"
                                                newTitle="Add a Charge Type Group"
                                                newUrl={`${AppNavPaths.ChargeTypeGroupNew}`}
                                                searchTitle="View Charge Type Groups"
                                                searchUrl={`${AppNavPaths.ChargeTypeGroups}`}
                                            />
                                        )
                                    }
                                    <NavMenuItem
                                        isChild={true}
                                        action={'view'}
                                        entity={'contract_template'}
                                        onNavLinkClicked={this.onNavLinkClicked}
                                        label="Contract Templates"
                                        newTitle="Add a Contract Template"
                                        newUrl={`${AppNavPaths.ContractTemplateNew}`}
                                        searchTitle="View Contract Templates"
                                        searchUrl={`${AppNavPaths.ContractTemplateIndex}`}
                                    />
                                    <NavMenuItem
                                        isChild={true}
                                        action={'view'}
                                        entity={'user_group'}
                                        onNavLinkClicked={this.onNavLinkClicked}
                                        label="User Groups"
                                        newTitle="Add a User Group"
                                        newUrl={`${AppNavPaths.UserGroupNew}`}
                                        searchTitle="View User Groups"
                                        searchUrl={`${AppNavPaths.UserGroups}`}
                                    />
                                    <NavMenuItem
                                        isChild={true}
                                        action={'view'}
                                        entity={'group'}
                                        onNavLinkClicked={this.onNavLinkClicked}
                                        label="Groups"
                                        newTitle="Add a Group"
                                        newUrl={`${AppNavPaths.GroupNew}`}
                                        searchTitle="View Groups"
                                        searchUrl={`${AppNavPaths.Groups}`}
                                    />
                                    <NavMenuItem
                                        isChild={true}
                                        action={'view'}
                                        entity={'role'}
                                        onNavLinkClicked={this.onNavLinkClicked}
                                        label="Roles"
                                        newTitle="Add a Role"
                                        newUrl={`${AppNavPaths.RoleNew}`}
                                        searchTitle="View Roles"
                                        searchUrl={`${AppNavPaths.Roles}`}
                                    />
                                    {/*<NavMenuItem*/}
                                    {/*    isChild={true}*/}
                                    {/*    action={"view"}*/}
                                    {/*    entity={"permission"}*/}
                                    {/*    onNavLinkClicked={this.onNavLinkClicked}*/}
                                    {/*    label="Permissions"*/}
                                    {/*    newTitle='Add a Permission'*/}
                                    {/*    newUrl={`${AppNavPaths.PermissionNew}`}*/}
                                    {/*    searchTitle="View Permissions"*/}
                                    {/*    searchUrl={`${AppNavPaths.Permissions}`}*/}
                                    {/*/>*/}
                                    {/*<NavMenuItem*/}
                                    {/*    isChild={true}*/}
                                    {/*    action={"view"}*/}
                                    {/*    entity={'holiday'}*/}
                                    {/*    onNavLinkClicked={this.onNavLinkClicked}*/}
                                    {/*    label={'Holidays'}*/}
                                    {/*    newTitle=""*/}
                                    {/*    newUrl=""*/}
                                    {/*    searchTitle="View Holidays"*/}
                                    {/*    searchUrl={`${AppNavPaths.Holidays}`}*/}
                                    {/*    hideNew={true}*/}
                                    {/*/>*/}
                                    <NavMenuItem
                                        isChild={true}
                                        action={'view'}
                                        entity={'equipmenttype'}
                                        onNavLinkClicked={this.onNavLinkClicked}
                                        label={'Equipment Types'}
                                        newTitle=""
                                        newUrl=""
                                        searchTitle="View Equipment Types"
                                        searchUrl={`${AppNavPaths.EquipmentTypes}`}
                                        hideNew={true}
                                    />
                                    {complianceEnabled && (
                                        <NavMenuItem
                                            isChild={true}
                                            action={'view'}
                                            entity={'compliancetype'}
                                            label={'Compliance Types'}
                                            searchUrl={`${AppNavPaths.ComplianceType}`}
                                            title={'View Compliance Types'}
                                            onNavLinkClicked={
                                                this.onNavLinkClicked
                                            }
                                            newUrl={`${AppNavPaths.ComplianceTypeNew}`}
                                        />
                                    )}
                                    <NavMenuItem
                                        isChild={true}
                                        action={'view'}
                                        entity={'quickBooksInventoryItem'}
                                        onNavLinkClicked={this.onNavLinkClicked}
                                        label={'View Inventory Items'}
                                        searchTitle="View Inventory Items"
                                        searchUrl={`${AppNavPaths.QuickBooksInventoryItems}`}
                                        newTitle="Add Inventory Item"
                                        newUrl={`${AppNavPaths.QuickBooksInventoryItemNew}`}
                                    />

                                    {employeeLongTermStatusManagementEnabled && (
                                        <NavMenuItem
                                            isChild={true}
                                            action={'view'}
                                            entity={'longtermstatus'}
                                            onNavLinkClicked={
                                                this.onNavLinkClicked
                                            }
                                            label={'Long Term Statuses'}
                                            newTitle=""
                                            newUrl=""
                                            searchTitle="View Long Term Statuses"
                                            searchUrl={`${AppNavPaths.LongTermStatuses}`}
                                            hideNew={true}
                                        />
                                    )}

                                    {attendanceEventsEnabled && (
                                        <NavMenuItem
                                            isChild={true}
                                            action={'view'}
                                            entity={'dailyattendanceevent'}
                                            onNavLinkClicked={
                                                this.onNavLinkClicked
                                            }
                                            label={'Daily Attendance Events'}
                                            newTitle=""
                                            newUrl=""
                                            searchTitle="View Daily Attendance Events"
                                            searchUrl={`${AppNavPaths.DailyAttendanceEvents}`}
                                            hideNew={true}
                                        />
                                    )}

                                    <NavMenuItem
                                        isChild={true}
                                        action={'view'}
                                        entity={'jobassignmentcancellationtype'}
                                        onNavLinkClicked={this.onNavLinkClicked}
                                        label={
                                            'Job Assignment Cancellation Types'
                                        }
                                        newTitle=""
                                        newUrl=""
                                        searchTitle="View Job Assignment Cancellation Types"
                                        searchUrl={`${AppNavPaths.JobAssignmentCancellationTypes}`}
                                        hideNew={true}
                                    />

                                    <NavMenuItem
                                        isChild={true}
                                        action={'view'}
                                        entity={'jobassignmentcancellationtype'}
                                        onNavLinkClicked={this.onNavLinkClicked}
                                        label={'Company Billing Groups'}
                                        newTitle=""
                                        newUrl=""
                                        searchTitle="View Company Billing Groups"
                                        searchUrl={`${AppNavPaths.CompanyBillingGroup}`}
                                        hideNew={true}
                                    />

                                    <NavMenuItem
                                        isChild={true}
                                        action={'view'}
                                        entity={'jobassignmentcancellationtype'}
                                        onNavLinkClicked={this.onNavLinkClicked}
                                        label={'Pay Schedules'}
                                        newTitle=""
                                        newUrl=""
                                        searchTitle="View Pay Schedules"
                                        searchUrl={`${AppNavPaths.PaySchedule}`}
                                        hideNew={true}
                                    />

                                    {activeEmployeeListEnabled && (
                                        <NavMenuItem
                                            isChild={true}
                                            action={'view'}
                                            entity={'active_employee_list'}
                                            onNavLinkClicked={
                                                this.onNavLinkClicked
                                            }
                                            label={'Active Employees'}
                                            newTitle=""
                                            newUrl=""
                                            searchTitle="View Active Employees"
                                            searchUrl={`${AppNavPaths.ActiveEmployees}`}
                                            hideNew={true}
                                        />
                                    )}

                                    <NavMenuItem
                                        isChild={true}
                                        action={'view'}
                                        entity={'collected_reporting'}
                                        onNavLinkClicked={this.onNavLinkClicked}
                                        label={'Collected Reporting'}
                                        newTitle=""
                                        newUrl=""
                                        searchTitle="View Collected Reporting"
                                        searchUrl={`${AppNavPaths.CollectedReporting}`}
                                        hideNew={true}
                                    />

                                    {countyCaptureEnabled && (
                                        <NavMenuItem
                                            isChild={true}
                                            action={'view'}
                                            entity={'county_group'}
                                            onNavLinkClicked={this.onNavLinkClicked}
                                            label={'County Groups'}
                                            newTitle="Add County Group"
                                            newUrl={`${AppNavPaths.USCountyGroupNew}`}
                                            searchTitle="View County Groups"
                                            searchUrl={`${AppNavPaths.USCountyGroups}`}
                                        />
                                    )}
                                </Collapse>
                            </>
                        ) : (
                            ''
                        )}

                        <footer>
                            <div className="selectable-hidden-text">
                                {gitInfo.branch} - [{gitInfo.commit.shortHash}]
                            </div>
                        </footer>
                    </nav>
                </aside>
            </>
        );
    };

    render() {
        const { isAuthenticated } = { ...this.state };
        const { common, tenant, user, permissions, pageTitle } = {
            ...this.context,
        };

        const { showBrandLabel } = !!{
            ...tenant?.tenantSettings?.defaultTheme,
        };
        document.title = tenant?.companyName ?? '';

        const bannerMessage = tenant?.tenantSettings?.bannerMessage;

        return (
            <header id="siteHeader">
                <Navbar
                    className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom p-0 m-0"
                    color={user?.isImpersonating ? 'warning' : 'light'}
                    light
                >
                    <div className="container-fluid m-0 p-0">
                        <div className="menu-button-wrapper">
                            {!!isAuthenticated &&
                                !!user &&
                                !!permissions &&
                                this.renderMenuButton()}
                        </div>
                        <FlexStartRow className="p-0">
                            <NavbarBrand tag={Link} to="/">
                                <img
                                    src={ApiRoutes.tenant.getLogo()}
                                    alt="Logo"
                                />
                                {!!showBrandLabel && (
                                    <span className="brand-label">
                                        {tenant?.companyName ?? ''}
                                    </span>
                                )}
                            </NavbarBrand>
                            <h1>{pageTitle}</h1>
                            {Boolean(bannerMessage) && (
                                <h2>
                                    <Badge color="danger" fade="false">
                                        {bannerMessage}
                                    </Badge>
                                </h2>
                            )}
                        </FlexStartRow>
                        <FlexCenterRow>
                            {!!common?.version && `v.${common.version}`}

                            {user?.isImpersonating && (
                                <Button className="ml-5" color="secondary" onClick={this.onClickImpersonation}>
                                    Stop Impersonating {user?.userName}
                                </Button>
                            )}
                        </FlexCenterRow>
                        {!!user && (
                            <FlexEndRow>
                                <h6 className="mb-0 flex-column text-dark text-right d-none d-xl-flex d-lg-flex d-md-flex d-xl-none">
                                    <FlexEndRow>
                                        <span>{user?.userName}</span>
                                    </FlexEndRow>
                                </h6>
                                <span className="user-stacked-icon fa-stack fa-2x mr-2">
                                    <i className="fas fa-circle fa-stack-2x"></i>
                                    <i className="fas fa-user fa-stack-1x fa-inverse"></i>
                                </span>
                                <span
                                    title="Logout"
                                    className="fa fa-sign-out-alt fa-lg cursor-pointer mr-3"
                                    onClick={this.onLogoutClicked}
                                ></span>
                            </FlexEndRow>
                        )}
                        {!!user &&
                            !!permissions &&
                            !!tenant?.tenantSettings &&
                            this.renderMenu()}
                    </div>
                </Navbar>
            </header>
        );
    }
}

export default withRouter(NavMenu);