export class JobAssignmentCancellationType {
    id = 0;

    description = '';

    canBeDeleted = true;

    canBeModified = true;

    isDeleted = false;

    preventsReassignment = false;

    includeOnRosterView = false;

    treatAsUnassigned = false;
}
