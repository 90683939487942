import React from 'react';
import _ from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUserClock,
    faRecycle,
    faCircleNotch,
    faSave,
    faPrint,
    faSort,
    faFileContract,
    faArrowAltCircleLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
    FormGroup,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    FormText,
    Badge,
} from 'reactstrap';
import Select from 'react-select';
import { flatten, uniqBy } from 'lodash';
import { CircularProgress } from '@mui/material';
import { v4 as uuid } from 'uuid';
import * as moment from 'moment';
import { BaseFormViewModel } from '../common/ViewModel';
import {
    FormCheckbox,
    CollapseUnderlineHeader,
    CollapseUnderlineHeaderWithIcon,
    CollapseTableElement,
    FormLabel,
    onFieldChange,
    onReactSelectChanged,
    FlexStartRow,
    FlexCenterRow,
    toasty,
    FlexEndRow,
    ValidationErrorMessage,
    SmallButton,
} from '../common/forms/FormElements';
import CommonContext, {
    ApiRoutes,
    MomentDateFormat,
    LocalizationKeys as l,
} from '../Common';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import {
    Invoice,
    InvoiceStatus,
    InvoiceChargeDetailType,
    InvoiceChargeDetailAdjustment,
} from './Invoice';
import ValidatedSelect, {
    CompactSelectStyles,
    SuperCompactSelectStyles,
} from '../common/forms/ValidatedSelect';
import TimesheetDetails from '../timesheet/TimesheetDetails';
import { ChargeTypeUnits } from '../chargeType/ChargeType';
import NumericInput from '../common/forms/NumericInput';
import ReorderListWidget from '../widgets/ReorderListWidget';
import { getTenantUserProfile } from '../common/TenantUserProfile';
import { CountyLabel } from '../uscounties/CountyLabel';

const api_date_format = 'YYYYMMDD';

export default class InvoiceSlideout extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.timesheetDetailsRef = React.createRef();
        this.timesheetsWidgetRef = React.createRef();
        this.invoiceDetailsWidgetRef = React.createRef();
        this.editChargeTypeGroupRef = React.createRef();
        this.addChargeTypeGroupRef = React.createRef();

        const stateBase = {
            originalDetails: '',
            originalAdjustments: JSON.stringify([]),
            invoice: {},
            adjustments: [],
            customers: [],
            contracts: [],
            contractsFiltered: [],
            timesheetDetailsOpen: false,
            invoiceDetailsOpen: true,
            hourlyChargesOpen: true,
            chargeTypeGroupsOpen: true,
            flatChargesOpen: true,
            chargeDetailsOpen: true,
            invoiceSummaryOpen: true,
            reviewDetailsOpen: true,
            isPrinting: false,
            isNew: false,
            reverting: false,
            current: {},
            billValid: true,
            contractTimesheets: [],
            showAddTimesheetModal: false,
            showCustomerOverrideModal: false,
            showDeleteInvoiceModal: false,
            showRestartInvoiceModal: false,
            showAdjustmentsModal: false,
            showAddChargeTypeGroupsForm: false,
            showEditChargeTypeGroupsForm: false,
            toggleConfirmDraftModal: false,
            showRemoveTimesheetModal: false,
            showAddChargeTypeGroupModal: false,
            selectedContractTimesheet: {},
            selectedTimesheetToDelete: {},
            saving: false,
            approving: false,
            printing: false,
            drafting: false,
            deleting: false,
            selectedSortableInvoiceDetails: [],
            selectedSortableTimesheets: [],
            showReorderWidget: false,
            showTimesheetsReorderWidget: false,
            inventoryItems: [],
            selectedCTGroup: null,
            addCTGroupDate: null,
            associateInventoryItem: false,
            overrideChargeTypeRules: false,
            adjustmentHelper: {
                date: '',
                useChargeTypeGroups: false,
                chargeType: null,
                resource: '',
                rate: '',
                qbInventoryItem: '',
            },
            contract: null,
            captureCounty: false,
            usCounties: [],
            ...new BaseFormViewModel(),
        };

        this.state = stateBase;
        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() { return this.populateState(); }

    getEditedGroupDetails = () => {
        return this.state?.editedGroupDetails?.filter((x) => x.invoiceChargeTypeGroupUID != null) || [];
    };

    onAddAdjustmentDescription = async () => {
        this.toggleAdjustmentsModal();
    };

    onAddChargeTypeGroup = () => {
        const invoiceChargeTypeGroupUID = uuid();

        const { invoice, addCTGroupDate } = this.state;

        const unusedCharges = flatten(
            (invoice.invoiceTimesheets ?? []).map((x) => x.invoiceCharges),
        ).filter((x) => x.invoiceChargeTypeGroupUID == null && x.chargeTypeGroupId == null && x.dateDisplay == addCTGroupDate);

        const ctGroupCharges = flatten(
            (invoice.invoiceTimesheets ?? []).map((x) => x.invoiceCharges),
        ).filter((x) => x.invoiceChargeTypeGroupUID != null && x.chargeTypeGroupId == null);

        const details = ctGroupCharges.filter((x) => x.invoiceChargeTypeGroupUID == invoiceChargeTypeGroupUID);

        const clonedDetails = util.object.clone(details);
        const clonedUnusedCharges = util.object.clone(unusedCharges);

        this.setState({
            showAddChargeTypeGroupsForm: true,
            originalGroupDetails: details,
            editedGroupDetails: clonedDetails,
            originalUnusedCharges: unusedCharges,
            editedUnusedCharges: clonedUnusedCharges,
            AddCTGroupUID: invoiceChargeTypeGroupUID,
            addCTGroupDate: null,
            selectedChargeTypeGroup: null,
            overrideChargeTypeRules: false,
        });
    };

    onAddChargeTypeGroupCharge_Add = () => {
        const {
            invoice, AddCTGroupUID, addCTGroupDate, selectedChargeTypeGroup,
        } = this.state;

        const charpeTypeIds = [];
        let billingRateTypeId = 1;
        for (let i = 0; i < selectedChargeTypeGroup.chargeTypeGroupChargeTypes.length; i++) {
            charpeTypeIds.push(selectedChargeTypeGroup.chargeTypeGroupChargeTypes[i].chargeTypeId);
            billingRateTypeId = selectedChargeTypeGroup.chargeTypeGroupChargeTypes[i].billingRateTypeId;
        }

        const unusedCharges = flatten(
            (invoice.invoiceTimesheets ?? []).map((x) => x.invoiceCharges),
        ).filter((x) => x.invoiceChargeTypeGroupUID == null
            && x.chargeTypeGroupId == null
            && x.dateDisplay == addCTGroupDate
            && x.billingRateTypeId == billingRateTypeId
            && charpeTypeIds.includes(x.chargeTypeId));

        const ctGroupCharges = flatten(
            (invoice.invoiceTimesheets ?? []).map((x) => x.invoiceCharges),
        ).filter((x) => x.invoiceChargeTypeGroupUID != null && x.chargeTypeGroupId == null);

        const details = ctGroupCharges.filter((x) => x.invoiceChargeTypeGroupUID == AddCTGroupUID);

        const clonedDetails = util.object.clone(details);
        const clonedUnusedCharges = util.object.clone(unusedCharges);

        this.setState({
            showAddChargeTypeGroupModal: true,
            originalGroupDetails: details,
            editedGroupDetails: clonedDetails,
            originalUnusedCharges: unusedCharges,
            editedUnusedCharges: clonedUnusedCharges,
            isEdit_ChargeTypeGroup: false,
        });
    };

    onAddChargeTypeGroupCharge_Edit = () => {
        this.setState({
            showAddChargeTypeGroupModal: true,
            isEdit_ChargeTypeGroup: true,
        });
    };

    onAddChargesToGroup = async () => {
        let {
            selectedCTGroup, editedUnusedCharges, editedGroupDetails, isEdit_ChargeTypeGroup, AddCTGroupUID,
        } = this.state;

        const uid = isEdit_ChargeTypeGroup ? selectedCTGroup.invoiceChargeTypeGroupUID : AddCTGroupUID;

        const addCharges = editedUnusedCharges.filter((x) => x.isSelected);
        addCharges.map((x) => x.invoiceChargeTypeGroupUID = uid);

        // Add
        for (let i = 0; i < addCharges.length; i++) {
            editedGroupDetails.push(addCharges[i]);
        }

        // Remove
        editedUnusedCharges = editedUnusedCharges.filter((x) => !x.isSelected);

        this.setState({
            editedGroupDetails,
            editedUnusedCharges,
            showAddChargeTypeGroupModal: false,
        });
    };

    onAddTimesheetClicked = async () => {
    // get latest ts's
        const { invoice } = this.state;

        const { contractId } = invoice;
        let workorderNumber = invoice.workOrderNumber ?? '';

        workorderNumber = workorderNumber.replaceAll('/', '*');

        if (contractId) {
            await this.silentSave();

            const weekDate = moment(invoice.invoiceDate).startOf('week').subtract(6, 'days').format(api_date_format);

            let [ts] = await Promise.all([
                util.fetch.js(
                    ApiRoutes.invoice.timesheets(contractId, workorderNumber, weekDate),
                ),
            ]);
            // get invoice timesheet id
            // let ids = invoice.invoiceTimesheets.map(x => x.id);

            ts = this.filterContractTimesheets(invoice, ts);

            this.setState({ contractTimesheets: ts });
            this.toggleAddTimesheetModal();
        }
    };

    onAdjustmentChanged = (i, ev) => {
        const { adjustments } = { ...this.state };
        const val = ev.target.value;
        const { name } = ev.target;

        adjustments[i][name] = val;
        adjustments[i].chargeAmount = adjustments[i].chargeQty * adjustments[i].billingRate;

        this.setState({ adjustments });
    };

    onApprove = async () => {
        const { invoice, adjustments } = {
            ...this.state,
        };

        // Validate that QB setting are present on each charge
        const { invoiceChargeDetails } = { ...invoice };
        const qbInventoryItemsValid = invoiceChargeDetails.filter(
            (x) => x.userQuickBooksInventoryItem == 'NOT CONFIGURED',
        ).length == 0
            && adjustments.filter(
                (x) => !Object.prototype.hasOwnProperty.call(x, 'userQuickBooksInventoryItem'),
            ).length == 0;
        const qbSalesItemsValid = invoiceChargeDetails.filter(
            (x) => x.userQuickBooksSalesAccount == 'NO SALES ACCOUNT',
        ).length == 0
            && adjustments.filter(
                (x) => !Object.prototype.hasOwnProperty.call(x, 'userQuickBooksSalesAccount'),
            ).length == 0;

        if (!qbInventoryItemsValid) {
            toasty.error(
                'All inventory items must be assigned in charge details before approving',
            );
            return;
        }

        if (!qbSalesItemsValid) {
            toasty.error(
                'All sales accounts must be assigned in charge details before approving',
            );
            return;
        }

        // const contractRequireGLString = (contract?.requireGLString === true);
        // if (glStringsEnabled && contractRequireGLString && (invoice.glString == null || invoice.glString.length == 0)) {
        //    toasty.error(
        //        'GL String cannot be empty'
        //    );
        //    return;
        // }

        await this.setState({ approving: true });

        try {
            await this.save();
            await util.fetch.post(ApiRoutes.invoice.approve(invoice.id));
            toasty.success('Approved');
            this.onRefresh();
        } catch {
            toasty.error(
                'There was an issue approving the edited record.  Please try your request again or contact support for assistance.',
            );
        } finally {
            this.resetForm();
            this.setState({ approving: false });
            this.onClose();
        }
    };

    onChange = onFieldChange;

    onClose = () => {
    // let { saving, originalEdit, edit } = { ...this.state }
    // if (!saving && !isEqual({ ...originalEdit }, { ...edit })) {
    //    let confirmed = window.confirm("You have unsaved changes, are you sure you want to leave?");
    //    if (!confirmed)
    //        return;
    // }

        this.resetForm();
        this.props.toggleShow(false);
        this.context.setFormOpened(false);
        this.props.onClose();
    };

    onDelete = async () => {
        const { deleting, invoice } = this.state;

        this.toggleDeleteInvoiceModal();

        try {
            if ((invoice ?? {}).id) {
                await util.fetch.delete(ApiRoutes.invoice.delete(invoice.id));

                toasty.success('Deleted.');
                this.onClose();
            }
        } catch {
            toasty.error(
                'There was an issue deleting the record.  Please try your request again or contact support for assistance.',
            );
        } finally {
            this.setState({
                deleting: !deleting,
            });
        }
    };

    onDeleteChargeTypeGroup = async (ctGroup) => {
        const { invoice } = this.state;

        // Remove charge detail from group
        const charges = flatten(
            (invoice.invoiceTimesheets ?? []).map((x) => x.invoiceCharges),
        ).filter((x) => x.chargeTypeGroupId == null && x.invoiceChargeTypeGroupUID == ctGroup.invoiceChargeTypeGroupUID);

        charges.map((x) => x.invoiceChargeTypeGroupUID = null);

        // Remove group
        for (let i = 0; i < invoice.invoiceTimesheets.length; i++) {
            const it = invoice.invoiceTimesheets[i].invoiceCharges;
            invoice.invoiceTimesheets[i].invoiceCharges = it.filter((x) => x.invoiceChargeTypeGroupUID != ctGroup.invoiceChargeTypeGroupUID);
        }

        this.setState({
            invoice,
        });
    };

    onDraft = async () => {
        await this.setState({ drafting: true });
        const { invoice } = { ...this.state };

        if (
            invoice.useTCNumber
            && (invoice.tcNumber == null || invoice.tcNumber.length == 0)
        ) {
            toasty.error('TC # must be provided.');
            return;
        }

        if (invoice.useWorkRequest) {
            if (
                invoice.purchaseOrderNumber == null
                || invoice.purchaseOrderNumber.length == 0
            ) {
                toasty.error('PO # must be provided.');
                return;
            }

            if (
                invoice.workRequest == null
                || invoice.workRequest.length == 0
            ) {
                toasty.error('Work Request must be provided.');
                return;
            }
        }

        this.toggleConfirmDraftModal();

        try {
            await this.silentSave();
            await util.fetch.post(ApiRoutes.invoice.draft(invoice.id));
            toasty.success('Draft Created');
            this.onRefresh();
        } catch {
            toasty.error(
                'There was an issue creating the draft.  Please try your request again or contact support for assistance.',
            );
        } finally {
            this.resetForm();
            this.setState({ drafting: false });
            // this.onClose();
        }
    };

    onEditChargeTypeGroup = (ctGroup) => {
        const { invoice, chargeTypeGroups } = this.state;

        const chargeTypeGroup = chargeTypeGroups.filter((x) => x.id == ctGroup.chargeTypeGroupId)[0];

        let billingRateTypeId = 1;

        const charpeTypeIds = [];
        for (let i = 0; i < chargeTypeGroup.chargeTypeGroupChargeTypes.length; i++) {
            charpeTypeIds.push(chargeTypeGroup.chargeTypeGroupChargeTypes[i].chargeTypeId);
            billingRateTypeId = chargeTypeGroup.chargeTypeGroupChargeTypes[i].billingRateTypeId;
        }

        const unusedCharges = flatten(
            (invoice.invoiceTimesheets ?? []).map((x) => x.invoiceCharges),
        ).filter((x) => x.invoiceChargeTypeGroupUID == null
            && x.chargeTypeGroupId == null
            && x.dateDisplay == ctGroup.dateDisplay
            && x.billingRateTypeId == billingRateTypeId
            && charpeTypeIds.includes(x.chargeTypeId));

        const ctGroupCharges = flatten(
            (invoice.invoiceTimesheets ?? []).map((x) => x.invoiceCharges),
        ).filter((x) => x.invoiceChargeTypeGroupUID != null && x.chargeTypeGroupId == null);

        const details = ctGroupCharges.filter((x) => x.invoiceChargeTypeGroupUID == ctGroup.invoiceChargeTypeGroupUID);

        const clonedDetails = util.object.clone(details);
        const clonedUnusedCharges = util.object.clone(unusedCharges);

        this.setState({
            showEditChargeTypeGroupsForm: true,
            isEdit_ChargeTypeGroup: true,
            selectedCTGroup: ctGroup,
            originalGroupDetails: details,
            editedGroupDetails: clonedDetails,
            originalUnusedCharges: unusedCharges,
            editedUnusedCharges: clonedUnusedCharges,
            overrideChargeTypeRules: false,
        });
    };

    onOverrideContractChanged = async (selection) => {
        const { invoice } = this.state;
        invoice.overrideContractId = selection.value;
        invoice.overrideContractNumber = selection.label;

        this.setState({
            invoice,
        });
    };

    onOverrideCustomerChanged = async (selection) => {
        let { invoice, contracts, contractsFiltered } = this.state;
        invoice.overrideCustomerCompanyId = selection.value;
        invoice.overrideCustomerCompanyName = selection.label;
        invoice.overrideContractId = null;
        invoice.overrideContractNumber = null;

        contractsFiltered = contracts.filter(
            (x) => x.companyId == selection.value,
        );

        this.setState({
            invoice,
            contractsFiltered,
        });
    };

    onOverrideDelete = async (selection) => {
        const { invoice } = this.state;
        invoice.overrideCustomerCompanyId = null;
        invoice.overrideCustomerCompanyName = null;
        invoice.overrideContractId = null;
        invoice.overrideContractNumber = null;

        this.setState({
            invoice,
        });
    };

    onPrint = async () => {
        await this.setState({ printing: true });
        const { invoice } = { ...this.state };
        const route = ApiRoutes.report.invoice(invoice.id);

        await this.setState({ printing: false });
        window.open(route, '_self');
    };

    onRefresh = (event) => {
        const { invoice } = this.state;
        const model = {
            invoiceId: invoice.id,
        };
        this.open(model);
    };

    onRemoveDraftTimesheet = async () => {
        const { invoice, selectedTimesheetToDelete } = this.state;

        this.toggleRemoveTimesheetModal();

        try {
            const model = {
                invoiceId: invoice.id,
                timesheetId: selectedTimesheetToDelete.timesheetId,
            };
            await util.fetch.post(
                ApiRoutes.invoice.draftTimesheetDelete(),
                model,
            );
            toasty.success('Timesheet Removed');
            this.onRefresh();
        } catch {
            toasty.error(
                'There was an issue removing the timesheet.  Please try your request again or contact support for assistance.',
            );
        } finally {
            this.resetForm();
            this.setState({ drafting: false });
            // this.onClose();
        }
    };

    onRemoveInvoiceChargeDetailClicked = (icd) => {
        const { invoice } = { ...this.state };
        const { invoiceChargeDetails } = { ...invoice };

        const index = invoiceChargeDetails.findIndex((x) => x.id === icd.id);
        const chargeDetail = { ...invoiceChargeDetails[index] };
        chargeDetail.removed = !chargeDetail.removed;
        chargeDetail.show = !chargeDetail.isAdjustment;
        invoice.invoiceChargeDetails[index] = { ...chargeDetail };
        this.setState({ invoice: { ...invoice } });
    };

    onRemoveTimesheetClicked = (ts) => {
        const { invoice } = this.state;

        if (invoice.invoiceStatusId == InvoiceStatus.Draft) {
            this.setState({
                selectedTimesheetToDelete: ts,
            });

            this.toggleRemoveTimesheetModal();
        } else {
            invoice.invoiceTimesheets = invoice.invoiceTimesheets.filter(
                (x) => x !== ts,
            );
        }

        this.setState({
            invoice,
        });
    };

    onReorderInvoiceChargeDetails = () => {
    // clone the list of charge types, to avoid mutating state.
    // pass to the widget.
        this.setState((state) => {
            const { invoice } = { ...this.state };
            const { invoiceChargeDetails } = { ...invoice };

            const selectedSortableInvoiceDetails = invoiceChargeDetails;

            return {
                showReorderWidget: true,
                selectedSortableInvoiceDetails,
            };
        });
    };

    onReorderTimesheets = () => {
    // clone the list of timesheets, to avoid mutating state.
    // pass to the widget.
        this.setState((state) => {
            const { invoice } = { ...this.state };

            let selectedSortableTimesheets = invoice.invoiceTimesheets;
            selectedSortableTimesheets = selectedSortableTimesheets.map(
                (obj) => ({
                    ...obj,
                    sortableText:
                        `${obj.timesheetNumber
                        } - ${
                            moment(obj.jobDate).format('M/D/YYYY')}`,
                }),
            );

            return {
                showTimesheetsReorderWidget: true,
                selectedSortableTimesheets,
            };
        });
    };

    onRestart = async () => {
        const { invoice } = this.state;

        this.setState({
            restarting: true,
        });

        try {
            if ((invoice ?? {}).id) {
                const resp = await util.fetch.post(
                    ApiRoutes.invoice.restart(invoice.id),
                );

                invoice.id = resp.data;

                toasty.success('Invoice restarted.');

                this.setState({
                    invoice,
                    restarting: false,
                });
                this.onRefresh();
            }
        } catch {
            toasty.error(
                'There was an issue restarting the invoice.  Please try your request again or contact support for assistance.',
            );

            this.setState({
                restarting: false,
            });
        } finally {
            this.toggleRestartInvoiceModal();
        }
    };

    onRevert = async () => {
        await this.setState({ reverting: true });

        const { invoice } = { ...this.state };

        try {
            await this.save();
            await util.fetch.post(ApiRoutes.invoice.revert(invoice.id));
            toasty.success('Reverted to Draft');
            this.onRefresh();
        } catch {
            toasty.error(
                'There was an error returning this invoice to draft status.  Please try your request again or contact support for assistance.',
            );
        } finally {
            this.resetForm();
            this.setState({ reverting: false });
        }
    };

    onSaveChargeTypeGroup = async () => {
        const { selectedChargeTypeGroup, overrideChargeTypeRules } = this.state;

        const editedGroupDetails = this.getEditedGroupDetails();
    
        // Is there anything to do?
        if (editedGroupDetails.length == 0) {
            toasty.error('No charges are in this group.');
            return;
        }

        // Validate against group rules
        if (!overrideChargeTypeRules) {
            const chargeTypeGroup = selectedChargeTypeGroup;

            for (let i = 0; i < chargeTypeGroup.chargeTypeGroupChargeTypes.length; i++) {
                const chargeType = chargeTypeGroup.chargeTypeGroupChargeTypes[i];

                const items = editedGroupDetails.filter((x) => x.chargeTypeId == chargeType.chargeTypeId);
                if (items.length !== chargeType.quantity) {
                    toasty.error(`Charge group must contain (${chargeType.quantity}) "${chargeType.chargeType.description}" charges`);
                    return;
                }
            }
        }

        this.saveChargeTypeGroup(overrideChargeTypeRules);
    };

    onSelectChange = onReactSelectChanged;

    onSelectedContractTimesheetChanged = (selection) => {
        this.setState({
            selectedContractTimesheet: selection,
        });
    };

    onSubmit = async () => {
        await this.setState({ saving: true });

        try {
            await this.save();
        } catch {
            toasty.error(
                'There was an issue saving the edited record.  Please try your request again or contact support for assistance.',
            );
        } finally {
            this.setState({ saving: false });
        }
    };

    onTimesheetDetailsClosed = async () => {
        await this.context.setFormOpened(false);
    };

    onViewTimesheetClicked = async (ts) => {
        await this.context.setFormOpened(true);
        this.timesheetDetailsRef.current.open(ts.timesheetId);
    };

    addContractTimesheet = () => {
    // Need to check if its in draft, if it is, add the timesheet and then reset the status to new
    // otherise use current method
        let { invoice, selectedContractTimesheet, contractTimesheets } = this.state;

        if (selectedContractTimesheet) invoice.invoiceTimesheets.push(selectedContractTimesheet);

        contractTimesheets = this.filterContractTimesheets(
            invoice,
            contractTimesheets,
        );

        this.setState({
            invoice,
            selectedContractTimesheet: {},
            contractTimesheets,
        });
    };

    buildAdjustmentDescription = async (i) => {
        const { adjustments, adjustmentHelper } = this.state;

        let chargeType = '';
        let rateDesc = '';
        let resource = '';

        if (
            adjustmentHelper.useChargeTypeGroups
            && adjustmentHelper.chargeTypeGroup !== null
        ) {
            chargeType = ` ${adjustmentHelper.chargeTypeGroup.invoiceDescription}`;
            adjustments[i].chargeQty = adjustmentHelper.chargeTypeGroup.billQuantity;
            adjustments[i].billingRate = adjustmentHelper.chargeTypeGroup.billRate;
            adjustments[i].userQuickBooksInventoryItem = adjustmentHelper.chargeTypeGroup.quickBooksInventoryItem?.name;
            adjustments[i].userQuickBooksSalesAccount = adjustmentHelper.chargeTypeGroup.quickBooksInventoryItem?.salesAccountName;
        } else {
            chargeType = adjustmentHelper.chargeType == null
                ? ''
                : ` ${adjustmentHelper.chargeType.description}`;
            resource = adjustmentHelper.resource.length > 0
                ? ` - ${adjustmentHelper.resource}`
                : '';

            if (adjustmentHelper.billingRate != null) {
                adjustments[i].billingRate = parseFloat(
                    adjustmentHelper.billingRate.rate,
                );
                adjustments[i].userQuickBooksInventoryItem = adjustmentHelper.billingRate.qbInventoryItem;
                adjustments[i].userQuickBooksSalesAccount = adjustmentHelper.billingRate.qbSalesAccount;
                rateDesc = adjustmentHelper.billingRate.description;
            } else {
                adjustments[i].billingRate = 0;
                adjustments[i].userQuickBooksInventoryItem = '';
                adjustments[i].userQuickBooksSalesAccount = '';
            }
        }

        adjustments[i].chargeAmount = adjustments[i].chargeQty * adjustments[i].billingRate;

        if (rateDesc.length > 20)
        // custom
        { adjustments[i].description = `${adjustmentHelper.date} ${rateDesc}${resource}`; } else adjustments[i].description = adjustmentHelper.date + chargeType + resource;

        adjustments[i].jobStartDate = adjustmentHelper.date;
        adjustments[i].jobEndDate = adjustmentHelper.date;

        this.setState({
            adjustments,
        });
    };

    filterContractTimesheets = (invoice, contractTimesheets) => {
        const ids = invoice.invoiceTimesheets.map((x) => x.timesheetId);

        return contractTimesheets.filter((x) => !ids.includes(x.timesheetId));
    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    open = async (model) => {
    // reset adjustments and validation on the way back in.
        this.props.toggleShow(true);
        this.context.setFormOpened(true);

        await this.setState({
            loading: true,
            formValidated: false,
        });

        await this.populateState();
    
        let { isNew } = { ...this.state };
        let invoice = new Invoice();

        if ((model ?? {}).invoiceId) {
            [invoice] = await Promise.all([
                util.fetch.js(ApiRoutes.invoice.byId(model.invoiceId)),
            ]);
        } else {
            // get related timesheets
            try {
                [invoice] = await Promise.all([
                    util.fetch.post(
                        ApiRoutes.invoice.create(),
                        model.billingIds,
                    ),
                ]);
            } catch (err) {
                toasty.error(
                    'Unable to load the invoice due to an internal error.',
                );
            } finally {
                isNew = true;
            }
        }

        const dispatchLocations = uniqBy(
            invoice.invoiceTimesheets.map((x) => ({
                value: x.dispatchLocationId,
                label: x.dispatchLocationName,
            })),
            'value',
        );

        invoice.invoiceChargeDetails = invoice.invoiceChargeDetails.map((c) => {
            if (c.id === 0) c.id = uuid();
            return c;
        });

        invoice.invoiceSummaries = invoice.invoiceSummaries.map((c) => {
            if (c.id === 0) c.id = uuid();
            return c;
        });

        const weekStart = moment(invoice.invoiceDate)
            .subtract(6, 'days')
            .format('YYYYMMDD');

        const [
            contract,
            staff,
            equipment,
            chargeTypes,
            chargeTypeGroups,
            inventoryItemsRaw,
            salesAccountsRaw,
            serviceItemsRaw,
            customers,
            contractsRaw,
        ] = await Promise.all([
            util.fetch.js(
                ApiRoutes.contract.filteredRates(invoice.contractId, weekStart),
            ),
            util.fetch.js(
                ApiRoutes.invoice.slideoutStaff(
                    invoice.dispatchOfficeLocationId,
                ),
            ),
            util.fetch.js(
                ApiRoutes.invoice.slideoutEquipment(
                    invoice.dispatchOfficeLocationId,
                ),
            ),
            util.fetch.js(ApiRoutes.chargeType.byContract(invoice.contractId)),
            util.fetch.js(
                ApiRoutes.chargeTypeGroup.byContract(invoice.contractId),
            ),
            util.fetch.js(ApiRoutes.typeAheads.quickBooksInventoryItems()),
            util.fetch.js(ApiRoutes.typeAheads.quickBooksSalesAccounts()),
            util.fetch.js(ApiRoutes.typeAheads.serviceItems()),
            util.fetch.js(ApiRoutes.typeAheads.customers()),
            util.fetch.js(ApiRoutes.typeAheads.contracts()),
        ]);

        let inventoryItems = [];

        if ((inventoryItemsRaw ?? []).length) {
            inventoryItems = inventoryItemsRaw.map((x) => ({ label: x.name, value: x.name }));
        }

        let salesAccounts = [];
        if ((salesAccountsRaw ?? []).length) {
            salesAccounts = salesAccountsRaw.map((x) => ({ label: x.name, value: x.name }));
        }

        let serviceItems = [];
        if ((serviceItemsRaw ?? []).length) {
            serviceItems = serviceItemsRaw.map((x) => ({
                label: `${x.itemNumber} - ${x.description}`,
                value: x.itemNumber,
            }));
        }

        let contracts = [];
        if ((contractsRaw ?? []).length) {
            contracts = contractsRaw.map((x) => ({ label: x.number, value: x.id, companyId: x.companyId }));
        }

        const { userTenant } = await getTenantUserProfile();
        const { tenantSettings } = { ...userTenant };

        this.resetForm();

        await this.setState({
            invoice,
            dispatchLocations,
            loading: false,
            restarting: false,
            isNew,
            inventoryItems,
            salesAccounts,
            serviceItems,
            associateInventoryItem: tenantSettings.useQuickBooksInvoicing,
            captureCounty: tenantSettings.captureCounty,
            chargeTypes,
            chargeTypeGroups,
            staff,
            equipment,
            contract,
            customers,
            contracts,
            glStringsEnabled: tenantSettings.glStringsEnabled,
        });
    };

    async populateState() {
        const [strings, usCounties] = await Promise.all([
            util.l10n.getStrings([l.crewLead]),
            util.fetch.js(ApiRoutes.USCounties.all()),
        ]);

        this.setState({ strings, usCounties });
    }

    resetForm = () => {
        this.setState({
            adjustments: [],
            formValidated: false,
            deleting: false,
            saving: false,
            approving: false,
            printing: false,
            isNew: false,
        });
    };

    save = async () => {
        let { invoice, adjustments } = util.object.clone({ ...this.state });

        invoice.invoiceChargeDetails = invoice.invoiceChargeDetails.map(
            (cd) => {
                if (cd.id !== null) cd.id = cd.id.constructor === String ? null : cd.id;
                return cd;
            },
        );

        invoice.invoiceSummaries = invoice.invoiceSummaries.map((cd) => {
            if (cd.id !== null) cd.id = cd.id.constructor === String ? null : cd.id;
            return cd;
        });

        adjustments = adjustments.map((a) => {
            a.id = a.id.constructor === String ? null : a.id;
            return a;
        });

        if (adjustments.length) invoice.invoiceChargeDetails.push(...adjustments);

        const resp = await util.fetch.put(
            ApiRoutes.invoice.update(invoice.id),
            invoice,
        );
        if (resp.data) {
            invoice.id = resp.data;

            this.setState({ invoice, isNew: false }, () => {
                toasty.success('Edit saved.');
                this.resetForm();
                this.onRefresh();
            });
        } else {
            // error
            toasty.error(resp.message);
        }
    };

    saveChargeTypeGroup = async () => {
        const {
            selectedCTGroup, selectedChargeTypeGroup, originalGroupDetails, addCTGroupDate,
            invoice, AddCTGroupUID, isEdit_ChargeTypeGroup,
        } = this.state;

        const editedGroupDetails = this.getEditedGroupDetails();

        const chargeTypeGroup = selectedChargeTypeGroup;
        const uid = isEdit_ChargeTypeGroup ? selectedCTGroup.invoiceChargeTypeGroupUID : AddCTGroupUID;

        // If this is an add - Add new charge row for ct group
        if (!isEdit_ChargeTypeGroup) {
            invoice.invoiceTimesheets[0].invoiceCharges.push({
                id: 0,
                timesheetId: invoice.invoiceTimesheets[0].timesheetId,
                chargeTypeName: `${moment(addCTGroupDate).format('MM/DD/YY')} ${chargeTypeGroup.invoiceDescription}`,
                billingDetailId: 0, // header, not used
                chargeTypeId: 17, // header, not used
                billQty: chargeTypeGroup.billQuantity,
                chargeQty: chargeTypeGroup.billQuantity,
                billingRateTypeId: 2,
                quickBooksInventoryItem: chargeTypeGroup.quickBooksInventoryItem.name,
                quickBooksSalesAccount: chargeTypeGroup.quickBooksInventoryItem.salesAccountName,
                invoiceChargeTypeGroupUID: uid,
                chargeTypeGroupId: chargeTypeGroup.id,
                current: true,
                billingRate: chargeTypeGroup.billRate,
                chargeAmount: chargeTypeGroup.billRate,
                date: addCTGroupDate,
                dateDisplay: addCTGroupDate,
            });
        }

        // Remove deleted charges
        for (let d = 0; d < originalGroupDetails.length; d += 1) {
            const { billingDetailId } = originalGroupDetails[d];

            const editedCharge = editedGroupDetails.find((x) => x.billingDetailId == billingDetailId && x.billingRateTypeId == editedGroupDetails[d].billingRateTypeId);
            if (!editedCharge) {
                originalGroupDetails[d].invoiceChargeTypeGroupUID = null;
            }
        }

        // Add charges
        for (let d = 0; d < editedGroupDetails.length; d += 1) {
            const { billingDetailId } = editedGroupDetails[d];

            const charge = flatten(invoice.invoiceTimesheets.map((x) => x.invoiceCharges)).find((x) => x.billingDetailId == billingDetailId && x.billingRateTypeId == editedGroupDetails[d].billingRateTypeId);
            charge.invoiceChargeTypeGroupUID = uid;
        }

        this.setState({
            invoice,
            showEditChargeTypeGroupsForm: false,
            showAddChargeTypeGroupsForm: false,
            overrideChargeTypeRules: false,
        });
    };

    silentSave = async () => {
        let { invoice, adjustments } = util.object.clone({ ...this.state });

        invoice.invoiceChargeDetails = invoice.invoiceChargeDetails.map(
            (cd) => {
                if (cd.id !== null) cd.id = cd.id.constructor === String ? null : cd.id;
                return cd;
            },
        );

        invoice.invoiceSummaries = invoice.invoiceSummaries.map((cd) => {
            if (cd.id !== null) cd.id = cd.id.constructor === String ? null : cd.id;
            return cd;
        });

        adjustments = adjustments.map((a) => {
            a.id = a.id.constructor === String ? null : a.id;
            return a;
        });

        if (adjustments.length) invoice.invoiceChargeDetails.push(...adjustments);

        const resp = await util.fetch.put(
            ApiRoutes.invoice.update(invoice.id),
            invoice,
        );
        if (resp.data) {
            invoice.id = resp.data;
            this.setState({ invoice });

            // toasty.success('Edit saved.');
            this.resetForm();
            // this.onRefresh();
        } else {
            // error
            // toasty.error(resp.message);
        }
    };

    sortChargeDetailsByTimesheetOrder = () => {
        const { invoice } = { ...this.state };
        const timesheets = invoice.invoiceTimesheets;
        const charges = invoice.invoiceChargeDetails;
        invoice.invoiceChargeDetails = [];

        let index = 1;
        for (let i = 0; i < timesheets.length; i++) {
            const ts = timesheets[i];

            const tsCharges = charges.filter(
                (x) => x.timesheetNumber == ts.timesheetNumber,
            );
            for (let r = 0; r < tsCharges.length; r++) {
                tsCharges[r].order = index++;
                invoice.invoiceChargeDetails.push(tsCharges[r]);
            }
        }

        this.setState({ invoice });
    };

    toggleAddChargeTypeGroupModal = () => {
        const { showAddChargeTypeGroupModal } = this.state;
        this.setState({
            showAddChargeTypeGroupModal: !showAddChargeTypeGroupModal,
        });
    };

    toggleAddTimesheetModal = () => {
        const { showAddTimesheetModal } = this.state;
        this.setState({ showAddTimesheetModal: !showAddTimesheetModal });
    };

    toggleAdjustmentsModal = () => {
        const { showAdjustmentsModal, adjustmentHelper } = this.state;

        adjustmentHelper.date = '';
        adjustmentHelper.useChargeTypeGroups = false;
        adjustmentHelper.chargeTypeGroup = null;
        adjustmentHelper.chargeType = null;
        adjustmentHelper.resource = '';
        adjustmentHelper.rate = '';

        this.setState({
            showAdjustmentsModal: !showAdjustmentsModal,
            adjustmentHelper,
        });
    };

    toggleConfirmDraftModal = () => {
        const { invoice } = { ...this.state };

        if (
            invoice.useTCNumber
            && (invoice.tcNumber == null || invoice.tcNumber.length == 0)
        ) {
            toasty.error('TC # must be provided.');
            return;
        }

        if (invoice.useWorkRequest) {
            if (
                invoice.purchaseOrderNumber == null
                || invoice.purchaseOrderNumber.length == 0
            ) {
                toasty.error('PO # must be provided.');
                return;
            }

            if (
                invoice.workRequest == null
                || invoice.workRequest.length == 0
            ) {
                toasty.error('Work Request must be provided.');
                return;
            }
        }

        const { showConfirmDraftModal } = this.state;
        this.setState({
            showConfirmDraftModal: !showConfirmDraftModal,
        });
    };

    toggleDeleteInvoiceModal = () => {
        const { showDeleteInvoiceModal } = this.state;
        this.setState({
            showDeleteInvoiceModal: !showDeleteInvoiceModal,
        });
    };

    toggleRemoveTimesheetModal = () => {
        const { showRemoveTimesheetModal } = this.state;
        this.setState({
            showRemoveTimesheetModal: !showRemoveTimesheetModal,
        });
    };

    toggleRestartInvoiceModal = () => {
        const { showRestartInvoiceModal } = this.state;
        this.setState({
            showRestartInvoiceModal: !showRestartInvoiceModal,
        });
    };

    getCounty = () => {
        const { usCounties, invoice } = this.state;

        if (!invoice.usCounty?.id && !invoice.usCountyId) {
            return null;
        }

        const usCounty = usCounties.find(
            (c) => c.id === (invoice.usCountyId || invoice.usCounty?.id),
        );

        return usCounty;
    };

    render() {
        let {
            invoice,
            customers,
            contractsFiltered,
            formValidated,
            validationMessage,
            invoiceDetailsOpen,
            hourlyChargesOpen,
            chargeTypeGroupsOpen,
            flatChargesOpen,
            chargeDetailsOpen,
            invoiceSummaryOpen,
            loading,
            saving,
            cancelling,
            approving,
            printing,
            showAddTimesheetModal,
            showDeleteInvoiceModal,
            showRestartInvoiceModal,
            selectedContractTimesheet,
            isAddingContractTimesheet,
            showAddChargeTypeGroupModal,
            contractTimesheets,
            isNew,
            reverting,
            deleting,
            restarting,
            drafting,
            showConfirmDraftModal,
            showAdjustmentsModal,
            showRemoveTimesheetModal,
            adjustments,
            showReorderWidget,
            showTimesheetsReorderWidget,
            selectedSortableInvoiceDetails,
            selectedSortableTimesheets,
            dispatchLocations,
            associateInventoryItem,
            inventoryItems,
            salesAccounts,
            serviceItems,
            chargeTypes,
            chargeTypeGroups,
            staff,
            equipment,
            adjustmentHelper,
            contract,
            strings,
            overrideChargeTypeRules,
            usCounties,
        } = this.state;

        const editedGroupDetails = this.getEditedGroupDetails();

        const contractRequireGLString = contract?.requireGLString === true;

        const tenantSettings = ((this.context ?? {}).tenant ?? {}).tenantSettings ?? {};
        let chargeDetailTotal = 0;

        const crewLead = strings ? strings[l.crewLead] : '';

        const ctGroupCharges = flatten(
            (invoice.invoiceTimesheets ?? []).map((x) => x.invoiceCharges),
        ).filter((x) => x.invoiceChargeTypeGroupUID != null && x.chargeTypeGroupId == null);

        let charges = flatten(
            (invoice.invoiceTimesheets ?? []).map((x) => x.invoiceCharges),
        ).filter((x) => x.chargeTypeUnitsId == ChargeTypeUnits.Hours && x.invoiceChargeTypeGroupUID == null);

        if (charges.length > 0) {
            charges = _.orderBy(
                charges,
                ['date', 'resourceTypeId', 'resourceName', 'billingRateTypeId'],
                ['asc', 'asc', 'asc', 'desc'],
            );
        }

        let invoiceChargeTypeGroups = flatten(
            (invoice.invoiceTimesheets ?? []).map((x) => x.invoiceCharges),
        ).filter((x) => x.chargeTypeGroupId > 0);

        invoiceChargeTypeGroups = invoiceChargeTypeGroups.sort((a, b) => (a.billingRate > b.billingRate ? -1 : 1));

        const dispatchObj = (dispatchLocations ?? []).find((x) => x.value === invoice.dispatchOfficeLocationId) ?? null;
        const dispatchOfficeLocationName = dispatchObj != null ? dispatchObj.label : '';

        const county = this.getCounty();

        return (
            tenantSettings.billingEnabled
            && tenantSettings.invoicingEnabled && (
                <>
                    <SlideForm
                        size="col-xl-12 col-md-12 col-xs-12"
                        loading={loading || !tenantSettings}
                        show={this.props.show}
                        id="timesheetForm"
                        formIcon={faUserClock}
                        formTitle="Invoice"
                        ref={this.formRef}
                        setIsValidated={(value) => {
                            this.setState({ formValidated: value });
                        }}
                        isValidated={formValidated}
                        className="pb-2 w-100"
                        onSubmit={this.onSubmit}
                        onClose={this.onClose}
                        errors={this.state.errors}
                        onClearErrors={this.onClearErrors}
                        validationMessage={validationMessage}
                        readOnly={!!saving}
                        hideToolbar
                    >
                        <CollapseUnderlineHeader
                            headerText="Invoice Details"
                            isOpen={invoiceDetailsOpen}
                            toggleCollapse={() => this.setState({
                                invoiceDetailsOpen: !invoiceDetailsOpen,
                            })}
                        >
                            <div className="invoice-details">
                                <FlexEndRow>
                                    {invoice?.invoiceStatusId
                                        == InvoiceStatus.New && (
                                        <button
                                            type="button"
                                            className="btn shadow-none"
                                            onClick={this.onAddTimesheetClicked}
                                        >
                                            <span className="cursor-pointer">
                                                <i className="fa fa-plus mr-2" />
                                                <small>Add Timesheet</small>
                                            </span>
                                        </button>
                                    )}
                                </FlexEndRow>

                                <FlexStartRow>
                                    <div>
                                        <FormLabel
                                            htmlFor="invoiceDate"
                                            text="Invoice Date"
                                        />
                                    </div>

                                    <div>
                                        {invoice?.invoiceStatusId
                                            <= InvoiceStatus.Draft && (
                                            <>
                                                <input
                                                    id="invoiceDate"
                                                    name="invoiceDate"
                                                    className="form-control ml-3 w-auto"
                                                    value={
                                                        moment(
                                                            invoice?.invoiceDate,
                                                        ).format(
                                                            MomentDateFormat.DateOnlyUTCHyphenated,
                                                        ) ?? ''
                                                    }
                                                    onChange={(ev) => {
                                                        const val = ev?.target?.value;
                                                        this.setState(
                                                            (state) => (
                                                                (state.invoice.invoiceDate = moment(
                                                                    val,
                                                                ).format(
                                                                    MomentDateFormat.DateOnlyUTCHyphenated,
                                                                )),
                                                                state
                                                            ),
                                                        );
                                                    }}
                                                    required
                                                    type="date"
                                                />
                                                <ValidationErrorMessage>
                                                &nbsp;Invoice Date is
                                                required.
                                                </ValidationErrorMessage>
                                            </>
                                        )}

                                        {invoice?.invoiceStatusId
                                            > InvoiceStatus.Draft && (
                                            <span
                                                id="invoiceDate"
                                                name="invoiceDate"
                                                className="form-control-sm form-control-plaintext"
                                            >
                                                {moment(
                                                    invoice?.invoiceDate,
                                                ).format(
                                                    MomentDateFormat.MonthDayYearSlashesLeading,
                                                ) ?? ''}
                                            </span>
                                        )}
                                    </div>

                                    <div className="ml-5">
                                        <FormLabel
                                            htmlFor="accountingDate"
                                            text="Accounting Date"
                                        />
                                    </div>
                                    <div>
                                        {invoice?.invoiceStatusId
                                            <= InvoiceStatus.Draft && (
                                            <input
                                                id="accountingDate"
                                                name="accountingDate"
                                                className="form-control ml-3 w-auto"
                                                value={invoice?.accountingDate ? 
                                                    moment(
                                                        invoice?.accountingDate,
                                                    ).format(
                                                        MomentDateFormat.DateOnlyUTCHyphenated,
                                                    ) : ''
                                                }
                                                onChange={(ev) => {
                                                    const val = ev?.target?.value;
                                                    this.setState(
                                                        (state) => (
                                                            (state.invoice.accountingDate = val
                                                                ? moment(
                                                                    val,
                                                                ).format(
                                                                    MomentDateFormat.DateOnlyUTCHyphenated,
                                                                )
                                                                : ''),
                                                            state
                                                        ),
                                                    );
                                                }}
                                                type="date"
                                            />
                                        )}

                                        {invoice?.invoiceStatusId
                                            > InvoiceStatus.Draft && (
                                            <span
                                                id="accountingDate"
                                                name="accountingDate"
                                                className="form-control-sm form-control-plaintext"
                                            >
                                                {moment(
                                                    invoice?.accountingDate
                                                        || invoice?.invoiceDate,
                                                ).format(
                                                    MomentDateFormat.MonthDayYearSlashesLeading,
                                                ) ?? ''}
                                            </span>
                                        )}
                                    </div>

                                    {Boolean(
                                        invoice?.invoiceStatusId
                                        <= InvoiceStatus.Draft
                                        && !invoice.accountingDate
                                        && invoice.invoiceDate,
                                    ) && (
                                        <div className="ml-3">
                        The current date will be used for accounting.
                                        </div>
                                    )}
                                    <div
                                        style={{
                                            marginLeft: 'auto',
                                            display: 'flex',
                                        }}
                                    >
                                        <FormLabel
                                            htmlFor="lastModified"
                                            text="Last Modified:"
                                        />
                                        <span
                                            style={{ whiteSpace: 'nowrap' }}
                                            className="form-control-sm form-control-plaintext"
                                        >
                                            {invoice?.lastModifiedBy}
                                        </span>
                                        <span
                                            style={{ whiteSpace: 'nowrap' }}
                                            className="form-control-sm form-control-plaintext"
                                        >
                                            {invoice?.lastModifiedOn}
                                        </span>
                                    </div>
                                </FlexStartRow>

                                {invoice?.invoiceNumber != null && (
                                    <FlexStartRow>
                                        <FormLabel
                                            htmlFor="invoiceNumber"
                                            text="Invoice #"
                                        />

                                        <span
                                            id="customer"
                                            name="customer"
                                            className="form-control-sm form-control-plaintext"
                                        >
                                            {invoice?.invoiceNumber ?? ''}
                                            {invoice?.invoiceVersion > 1 ? `-${invoice?.invoiceVersion}` : ''}
                                        </span>
                                    </FlexStartRow>
                                )}

                                {!invoice?.allowMultipleWONumbers && (
                                    <FlexStartRow>
                                        <FormLabel
                                            htmlFor="workOrderNumber"
                                            text="Work Order #"
                                        />

                                        <Input
                                            id="workOrderNumber"
                                            name="invoice.workOrderNumber"
                                            className="ml-3 w-auto"
                                            readOnly={false}
                                            value={
                                                (invoice ?? {})
                                                    .workOrderNumber ?? ''
                                            }
                                            onChange={this.onChange}
                                            type="text"
                                        />
                                    </FlexStartRow>
                                )}

                                {invoice.useWorkRequest && (
                                    <>
                                        <FlexStartRow>
                                            <FormLabel
                                                htmlFor="purchargeOrderNumber"
                                                text="PO #"
                                            />
                                            {invoice?.invoiceStatusId
                                                == InvoiceStatus.New && (
                                                <Input
                                                    id="purchaseOrderNumber"
                                                    name="invoice.purchaseOrderNumber"
                                                    className="ml-3 w-auto"
                                                    readOnly={false}
                                                    value={
                                                        (invoice ?? {})
                                                            .purchaseOrderNumber
                                                            ?? ''
                                                    }
                                                    onChange={this.onChange}
                                                    type="text"
                                                />
                                            )}

                                            {invoice?.invoiceStatusId
                                                > InvoiceStatus.New && (
                                                <span
                                                    id="purchaseOrderNumber"
                                                    name="invoice.purchaseOrderNumber"
                                                    className="form-control-sm form-control-plaintext"
                                                >
                                                    {invoice?.purchaseOrderNumber
                                                            ?? ''}
                                                </span>
                                            )}
                                        </FlexStartRow>
                                        <FlexStartRow>
                                            <FormLabel
                                                htmlFor="workRequest"
                                                text="Work Request"
                                            />
                                            {invoice?.invoiceStatusId
                                                == InvoiceStatus.New && (
                                                <Input
                                                    id="workRequest"
                                                    name="invoice.workRequest"
                                                    className="ml-3 w-auto"
                                                    readOnly={false}
                                                    value={
                                                        (invoice ?? {})
                                                            .workRequest ?? ''
                                                    }
                                                    onChange={this.onChange}
                                                    type="text"
                                                />
                                            )}

                                            {invoice?.invoiceStatusId
                                                > InvoiceStatus.New && (
                                                <span
                                                    id="workRequest"
                                                    name="invoice.workRequest"
                                                    className="form-control-sm form-control-plaintext"
                                                >
                                                    {invoice?.workRequest ?? ''}
                                                </span>
                                            )}
                                        </FlexStartRow>
                                    </>
                                )}

                                {invoice.useTCNumber && (
                                    <FlexStartRow>
                                        <FormLabel
                                            htmlFor="tcNumber"
                                            text="TC #"
                                        />
                                        {invoice?.invoiceStatusId
                                            == InvoiceStatus.New && (
                                            <Input
                                                id="tcNumber"
                                                name="invoice.tcNumber"
                                                className="ml-3 w-auto"
                                                readOnly={false}
                                                value={
                                                    (invoice ?? {}).tcNumber
                                                        ?? ''
                                                }
                                                onChange={this.onChange}
                                                type="text"
                                            />
                                        )}

                                        {invoice?.invoiceStatusId
                                            > InvoiceStatus.New && (
                                            <span
                                                id="tcNumber"
                                                name="invoice.tcNumber"
                                                className="form-control-sm form-control-plaintext"
                                            >
                                                {invoice?.tcNumber ?? ''}
                                            </span>
                                        )}
                                    </FlexStartRow>
                                )}

                                {tenantSettings.glStringsEnabled
                                    && contractRequireGLString && (
                                    <FlexStartRow>
                                        <FormLabel
                                            htmlFor="glString"
                                            text="GL String"
                                        />

                                        <Input
                                            id="glString"
                                            name="invoice.glString"
                                            className="ml-3 w-auto"
                                            readOnly={false}
                                            value={
                                                (invoice ?? {}).glString
                                                    ?? ''
                                            }
                                            onChange={this.onChange}
                                            type="text"
                                        />
                                    </FlexStartRow>
                                )}
                                <FlexStartRow>
                                    <FormLabel htmlFor="memo" text="Memo" />

                                    {invoice?.invoiceStatusId
                                        <= InvoiceStatus.Draft && (
                                        <Input
                                            id="memo"
                                            name="invoice.memo"
                                            className="ml-3 w-auto"
                                            readOnly={false}
                                            value={(invoice ?? {}).memo ?? ''}
                                            onChange={this.onChange}
                                            type="text"
                                        />
                                    )}

                                    {invoice?.invoiceStatusId
                                        > InvoiceStatus.Draft && (
                                        <span
                                            id="memo"
                                            name="memo"
                                            className="form-control-sm form-control-plaintext"
                                        >
                                            {invoice?.memo ?? ''}
                                        </span>
                                    )}
                                </FlexStartRow>

                                <FlexStartRow>
                                    <FormLabel
                                        htmlFor="jobLocation"
                                        text="Job Location"
                                    />

                                    <Input
                                        id="jobLocation"
                                        name="invoice.jobLocation"
                                        className="ml-3 w-auto"
                                        readOnly={false}
                                        value={
                                            (invoice ?? {}).jobLocation ?? ''
                                        }
                                        onChange={this.onChange}
                                        type="text"
                                    />
                                </FlexStartRow>

                                {Boolean(county) && (
                                    <FlexStartRow>
                                        <label
                                            htmlFor="usCountyId"
                                            className="control-label"
                                        >
                                            <CountyLabel {...county} name="County" />
                                        </label>

                                        <ValidatedSelect
                                            id="usCountyId"
                                            name="invoice.usCountyId"
                                            className="smallReactSelect form-control-sm ml-3"
                                            options={usCounties.filter(
                                                (c) => c.usStateId === county.usStateId,
                                            )}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            value={county}
                                            hideClear
                                            onChange={(selection) => {
                                                const { invoice } = this.state;

                                                if (selection) {
                                                    invoice.usCountyId = selection.id;
                                                } else {
                                                    invoice.usCountyId = null;
                                                }

                                                this.setState({
                                                    invoice,
                                                });
                                            }}
                                        />
                                    </FlexStartRow>    
                                )}

                                <FlexStartRow>
                                    <FormLabel
                                        htmlFor="invoiceJob"
                                        text="Operations Center"
                                    />

                                    {invoice?.invoiceStatusId
                                        <= InvoiceStatus.Draft && (
                                        <Input
                                            id="invoiceJob"
                                            name="invoice.invoiceJob"
                                            className="ml-3 w-auto"
                                            readOnly={false}
                                            value={
                                                (invoice ?? {}).invoiceJob ?? ''
                                            }
                                            onChange={this.onChange}
                                            type="text"
                                        />
                                    )}

                                    {invoice?.invoiceStatusId
                                        > InvoiceStatus.Draft && (
                                        <span
                                            id="invoiceJob"
                                            name="invoiceJob"
                                            className="form-control-sm form-control-plaintext"
                                        >
                                            {invoice?.invoiceJob ?? ''}
                                        </span>
                                    )}
                                </FlexStartRow>

                                <FlexStartRow>
                                    <FormLabel
                                        htmlFor="dispatchOfficeLocationId"
                                        text="Class"
                                    />

                                    {invoice?.invoiceStatusId
                                        <= InvoiceStatus.Draft && (
                                        <div className="ml-3 w-auto">
                                            <Select
                                                hideClear
                                                id="dispatchOfficeLocationId"
                                                name="invoice.dispatchOfficeLocationId"
                                                styles={
                                                    SuperCompactSelectStyles
                                                }
                                                className="react-select"
                                                options={dispatchLocations}
                                                value={
                                                    (
                                                        dispatchLocations ?? []
                                                    ).find(
                                                        (x) => x.value
                                                                === invoice.dispatchOfficeLocationId,
                                                    ) ?? ''
                                                }
                                                onChange={this.onSelectChange}
                                                isDisabled={false}
                                            />
                                        </div>
                                    )}

                                    {invoice?.invoiceStatusId
                                        > InvoiceStatus.Draft && (
                                        <span
                                            id="dispatchOfficeLocationId"
                                            name="invoice.dispatchOfficeLocationId"
                                            className="form-control-sm form-control-plaintext"
                                        >
                                            {invoice?.dispatchOfficeLocationName
                                                    ?? ''}
                                        </span>
                                    )}
                                </FlexStartRow>

                                <FlexStartRow>
                                    <FormLabel
                                        htmlFor="customer"
                                        text="Customer"
                                    />

                                    {invoice?.invoiceStatusId
                                        > InvoiceStatus.Draft
                                        && !invoice.overrideCustomerCompanyId
                                        > 0 && (
                                        <div
                                            className="ml-3 w-auto"
                                            style={{
                                                paddingTop: '6px',
                                            }}
                                        >
                                            {invoice?.customerCompanyName
                                                        ?? ''}
                                        </div>
                                    )}

                                    {invoice?.invoiceStatusId
                                        > InvoiceStatus.Draft
                                        && invoice.overrideCustomerCompanyId
                                        > 0 && (
                                        <>
                                            <div
                                                className="ml-3 w-auto"
                                                style={{
                                                    paddingTop: '6px',
                                                    color: 'red',
                                                }}
                                            >
                                                <strike>
                                                    {invoice?.customerCompanyName
                                                            ?? ''}
                                                </strike>
                                            </div>
                                            {' '}
                                            <div
                                                className="ml-3 w-auto"
                                                style={{
                                                    paddingTop: '6px',
                                                }}
                                            >
                                                {invoice?.overrideCustomerCompanyName
                                                        ?? ''}
                                            </div>
                                        </>
                                    )}

                                    {invoice?.invoiceStatusId
                                        <= InvoiceStatus.Draft && (
                                        <>
                                            {invoice.overrideCustomerCompanyId
                                                    > 0 && (
                                                <div
                                                    className="ml-3 w-auto"
                                                    style={{
                                                        paddingTop: '6px',
                                                        color: 'red',
                                                    }}
                                                >
                                                    <strike>
                                                        {invoice?.customerCompanyName
                                                                        ?? ''}
                                                    </strike>
                                                </div>
                                            )}

                                            <div
                                                className="ml-3 w-auto"
                                                style={{ paddingTop: '3px' }}
                                            >
                                                <Select
                                                    hideClear
                                                    id="overrideCustomerCompanyId"
                                                    name="invoice.overrideCustomerCompanyId"
                                                    styles={
                                                        SuperCompactSelectStyles
                                                    }
                                                    className="react-select"
                                                    options={customers}
                                                    value={
                                                        (customers ?? []).find(
                                                            (x) => (invoice.overrideCustomerCompanyId
                                                                        > 0
                                                                ? x.value
                                                                        === invoice.overrideCustomerCompanyId
                                                                : x.value
                                                                        === invoice.customerCompanyId),
                                                        ) ?? ''
                                                    }
                                                    onChange={
                                                        this
                                                            .onOverrideCustomerChanged
                                                    }
                                                    isDisabled={false}
                                                />
                                            </div>

                                            {invoice.overrideCustomerCompanyId
                                                    > 0 && (
                                                <>
                                                            &nbsp;&nbsp;
                                                    <button
                                                        type="button"
                                                        className="btn shadow-none"
                                                        title="Remove Override"
                                                        onClick={
                                                            this
                                                                .onOverrideDelete
                                                        }
                                                    >
                                                        <div className="mt-1">
                                                            <i className="fa fa-trash" />
                                                        </div>
                                                    </button>
                                                </>
                                            )}
                                        </>
                                    )}
                                </FlexStartRow>

                                <FlexStartRow>
                                    <FormLabel
                                        htmlFor="contract"
                                        text="Contract"
                                    />

                                    {invoice?.invoiceHasPO
                                        && invoice?.invoiceStatusId
                                        <= InvoiceStatus.Draft
                                        && !invoice?.useWorkRequest && (
                                        <Input
                                            id="purchaseOrderNumber"
                                            name="invoice.purchaseOrderNumber"
                                            className="ml-3 w-auto"
                                            readOnly={false}
                                            value={
                                                (invoice ?? {})
                                                    .purchaseOrderNumber
                                                    ?? ''
                                            }
                                            onChange={this.onChange}
                                            type="text"
                                        />
                                    )}

                                    {invoice?.invoiceHasPO
                                        && invoice?.invoiceStatusId
                                        > InvoiceStatus.Draft
                                        && !invoice?.useWorkRequest && (
                                        <span
                                            id="purchaseOrderNumber"
                                            name="purchaseOrderNumber"
                                            className="form-control-sm form-control-plaintext"
                                        >
                                            {invoice?.purchaseOrderNumber
                                                    ?? ''}
                                        </span>
                                    )}

                                    {invoice?.invoiceHasPO
                                        && invoice?.useWorkRequest && (
                                        <span
                                            id="contract"
                                            name="contract"
                                            className="form-control-sm form-control-plaintext"
                                        >
                                            {invoice?.contractNumber ?? ''}
                                        </span>
                                    )}

                                    {!invoice?.invoiceHasPO && (
                                        <>
                                            {!invoice.overrideCustomerCompanyId
                                                > 0 && (
                                                <span
                                                    id="contract"
                                                    name="contract"
                                                    className="form-control-sm form-control-plaintext"
                                                >
                                                    {invoice?.contractNumber
                                                            ?? ''}
                                                </span>
                                            )}

                                            {invoice.overrideCustomerCompanyId
                                                > 0
                                                && invoice?.invoiceStatusId
                                                > InvoiceStatus.Draft && (
                                                <>
                                                    <div
                                                        className="ml-3 w-auto"
                                                        style={{
                                                            paddingTop:
                                                                    '6px',
                                                            color: 'red',
                                                        }}
                                                    >
                                                        <strike>
                                                            {invoice?.contractNumber
                                                                    ?? ''}
                                                        </strike>
                                                    </div>
                                                    {' '}
                                                    <div
                                                        className="ml-3 w-auto"
                                                        style={{
                                                            paddingTop:
                                                                    '6px',
                                                        }}
                                                    >
                                                        {invoice?.overrideContractNumber
                                                                ?? ''}
                                                    </div>
                                                </>
                                            )}

                                            {invoice.overrideCustomerCompanyId
                                                > 0
                                                && invoice?.invoiceStatusId
                                                <= InvoiceStatus.Draft && (
                                                <>
                                                    <div
                                                        className="ml-3 w-auto"
                                                        style={{
                                                            paddingTop:
                                                                    '6px',
                                                            color: 'red',
                                                        }}
                                                    >
                                                        <strike>
                                                            {invoice?.contractNumber
                                                                    ?? ''}
                                                        </strike>
                                                    </div>

                                                    <div className="ml-3 w-auto">
                                                        <Select
                                                            hideClear
                                                            id="overrideContractId"
                                                            name="invoice.overrideContractId"
                                                            styles={
                                                                SuperCompactSelectStyles
                                                            }
                                                            placeholder="Contract..."
                                                            className="react-select"
                                                            options={
                                                                contractsFiltered
                                                            }
                                                            value={
                                                                (
                                                                    contractsFiltered
                                                                        ?? []
                                                                ).find(
                                                                    (x) => invoice.overrideContractId,
                                                                ) ?? ''
                                                            }
                                                            onChange={
                                                                this
                                                                    .onOverrideContractChanged
                                                            }
                                                            isDisabled={
                                                                false
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </FlexStartRow>

                                <FlexStartRow>
                                    <FormLabel htmlFor="terms" text="Terms" />
                                    <span
                                        id="terms"
                                        name="terms"
                                        className="form-control-sm form-control-plaintext"
                                    >
                                        {invoice?.terms ?? ''}
                                    </span>
                                </FlexStartRow>

                                <FlexStartRow>
                                    <FormLabel
                                        htmlFor="billingCompany"
                                        text="Billing Co."
                                    />
                                    <span
                                        id="billingCompany"
                                        name="billingCompany"
                                        className="form-control-sm form-control-plaintext"
                                    >
                                        {invoice?.billingCompanyName ?? ''}
                                    </span>
                                </FlexStartRow>

                                <div className="timesheet">
                                    <div
                                        style={{
                                            marginTop: '3px',
                                            marginLeft: '3px',
                                        }}
                                    >
                                        <SmallButton
                                            type="button"
                                            disabled={
                                                showTimesheetsReorderWidget
                                            }
                                            onClick={this.onReorderTimesheets}
                                        >
                                            <div
                                                style={{
                                                    paddingLeft: '4px',
                                                    paddingTop: '4px',
                                                    paddingRight: '6px',
                                                    paddingBottom: '4px',
                                                }}
                                            >
                                                <i className="fa fa-sort" />
                                                &nbsp;&nbsp;Reorder
                                            </div>
                                        </SmallButton>
                                    </div>

                                    <table className="table table-sm table-bordered table-striped payroll-table">
                                        <thead className="text-muted">
                                            <tr>
                                                <th className="timesheetNumber">
                              Timesheet #
                                                </th>
                                                <th className="dispatchLocation">
                              Dispatch Location
                                                </th>
                                                {invoice?.allowMultipleWONumbers && (
                                                    <th className="workOrderNumber">
                              Workorder #
                                                    </th>
                                                )}
                                                <th className="timesheetDateStart">
                              Date/Start
                                                </th>
                                                <th className="timesheetForeman">
                              Foreman
                                                </th>
                                                <th className="timesheetCrewLead">
                                                    {crewLead}
                                                </th>
                                                <th className="timesheetClientCancellation">
                              Client Cancellation
                                                </th>
                                                <th className="timesheetEmergency">
                              Emergency
                                                </th>
                                                <th className="timesheetBillingOverride">
                              Billing Overrides
                                                </th>
                                                <th className="timesheetSignatureOverride">
                              Signature Overrides
                                                </th>
                                                {invoice?.invoiceStatusId
                                                    <= InvoiceStatus.Draft && (
                                                    <>
                                                        <th className="timesheetView" />
                                                        <th className="timesheetDelete" />
                                                    </>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!(invoice.invoiceTimesheets ?? [])
                                                .length ? (
                                                    <tr>
                                                        <td
                                                            colSpan="11"
                                                            className="text-center"
                                                        >
                                  No timesheets found.
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    invoice.invoiceTimesheets.map(
                                                        (ts, i) => (
                                                            <tr
                                                                key={
                                                                    ts.timesheetId
                                                                }
                                                            >
                                                                <td className="timesheetNumber">
                                                                    {
                                                                        ts.timesheetNumber
                                                                    }
                                                                </td>

                                                                <td
                                                                    className="dispatchLocationName"
                                                                    style={{
                                                                        backgroundColor:
                                                                            ts.dispatchLocationName !== dispatchOfficeLocationName
                                                                                ? 'red'
                                                                                : 'auto',
                                                                    }}
                                                                >
                                                                    {
                                                                        ts.dispatchLocationName
                                                                    }
                                                                </td>

                                                                {invoice?.allowMultipleWONumbers && (
                                                                    <td className="workOrderNumber">
                                                                        {
                                                                            ts.workOrderNumber
                                                                        }
                                                                    </td>
                                                                )}
                                                                <td className="timesheetDateStart">
                                                                    {
                                                                        ts.jobStartTimeDisplay
                                                                    }
                                                                </td>
                                                                <td className="timesheetForeman">
                                                                    {
                                                                        ts.foremanName
                                                                    }
                                                                </td>
                                                                <td className="timesheetCrewLead">
                                                                    {
                                                                        ts.crewLeadName
                                                                    }
                                                                </td>
                                                                <td
                                                                    className="timesheetClientCancellation"
                                                                    style={{
                                                                        backgroundColor:
                                                                            ts.isCancel
                                                                                ? 'yellow'
                                                                                : 'auto',
                                                                    }}
                                                                >
                                                                    {ts.isCancel
                                                                        ? 'Yes'
                                                                        : 'No'}
                                                                </td>
                                                                <td className="timesheetEmergency">
                                                                    {ts.isEmergency
                                                                        ? 'Yes'
                                                                        : 'No'}
                                                                </td>
                                                                <td className="timesheetBillingOverride">
                                                                    {
                                                                        ts.billingOverrideStatus
                                                                    }
                                                                </td>
                                                                <td className="timesheetSignatureOverride">
                                                                    {ts.hasSignatureOverride
                                                                        ? 'Yes'
                                                                        : 'No'}
                                                                </td>
                                                                {invoice?.invoiceStatusId
                                                                    <= InvoiceStatus.Draft && (
                                                                    <>
                                                                        <td className="timesheetView text-center">
                                                                            <button
                                                                                type="button"
                                                                                className="btn shadow-none"
                                                                                title="View this timesheet"
                                                                                onClick={(
                                                                                    x,
                                                                                ) => {
                                                                                    this.onViewTimesheetClicked(
                                                                                        ts,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <span className="mt-1">
                                                                                    <i className="fa fa-eye" />
                                                                                </span>
                                                                            </button>
                                                                        </td>
                                                                        <td className="timesheetDelete text-center">
                                                                            <button
                                                                                type="button"
                                                                                className="btn shadow-none"
                                                                                title="Remove this timesheet"
                                                                                disabled={
                                                                                    (
                                                                                        invoice
                                                                                            ?? {}
                                                                                    )
                                                                                        .invoiceTimesheets
                                                                                        ?.length
                                                                                        == 1
                                                                                        && (
                                                                                            invoice
                                                                                            ?? {}
                                                                                        )
                                                                                            .invoiceStatusId
                                                                                        > InvoiceStatus.New
                                                                                }
                                                                                onClick={(
                                                                                    x,
                                                                                ) => {
                                                                                    this.onRemoveTimesheetClicked(
                                                                                        ts,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <span className="text-danger mt-1">
                                                                                    <i className="fa fa-trash" />
                                                                                </span>
                                                                            </button>
                                                                        </td>
                                                                    </>
                                                                )}
                                                            </tr>
                                                        ),
                                                    )
                                                )}
                                        </tbody>
                                    </table>
                                </div>

                                <Modal
                                    isOpen={false}
                                    toggle={this.toggleEmergencyConfirmModal}
                                >
                                    <ModalHeader
                                        toggle={
                                            this.toggleEmergencyConfirmModal
                                        }
                                    >
                        Emergency confirmation
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup />
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            color="primary"
                                            disabled={false}
                                            onClick={this.onEmergencyAssignment}
                                        >
                          Ok
                                        </Button>
                                        {' '}
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </CollapseUnderlineHeader>

                        <CollapseUnderlineHeaderWithIcon
                            headerText="Summary of Charge Type Groups"
                            isOpen={hourlyChargesOpen}
                            showIcon={isNew || invoice?.invoiceStatusId == InvoiceStatus.New}
                            onIconClick={this.onAddChargeTypeGroup}
                            toggleCollapse={() => this.setState({
                                hourlyChargesOpen: !hourlyChargesOpen,
                            })}
                        >
                            <div className="invoice">
                                <table className="table table-sm table-bordered table-striped payroll-table">
                                    <thead className="text-muted">
                                        <tr>
                                            <th className="invoiceHourlyCharge">
                            Charge Type Group
                                            </th>
                                            <th className="invoiceHourlyCharge">
                            Date
                                            </th>
                                            <th className="invoiceHourlyCharge">
                            Bill Rate
                                            </th>
                                            <th className="invoiceHourlyCharge">
                            Quantity
                                            </th>

                                            {(isNew || invoice?.invoiceStatusId == InvoiceStatus.New)
                                                && (
                                                    <>
                                                        <th className="invoiceHourlyCharge" />
                                                        <th className="invoiceHourlyCharge" />
                                                    </>
                                                )}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {!invoiceChargeTypeGroups.length ? (
                                            <tr>
                                                <td
                                                    colSpan="11"
                                                    className="text-center"
                                                >
                              No charge type groups found.
                                                </td>
                                            </tr>
                                        ) : (
                                            invoiceChargeTypeGroups.map(
                                                (
                                                    ctGroup,
                                                    charge_index,
                                                ) => {
                                                    const details = ctGroupCharges.filter((x) => x.invoiceChargeTypeGroupUID == ctGroup.invoiceChargeTypeGroupUID);

                                                    return (
                                                        <tr
                                                            key={`${uuid()}`}
                                                        >
                                                            <td className="invoiceHourlyCharge">

                                                                <CollapseTableElement
                                                                    headerText={ctGroup.chargeTypeName}
                                                                    isOpen={ctGroup.isOpen}
                                                                    toggleCollapse={() => {
                                                                        ctGroup.isOpen = !ctGroup.isOpen;
                                                                        this.setState({
                                                                            ctGroup: !ctGroup,
                                                                        });
                                                                    }}
                                                                >
                                                                    <table className="table table-sm table-bordered payroll-table" style={{ marginTop: '5px' }}>
                                                                        <thead className="text-muted">
                                                                            <tr>
                                                                                <th className="invoiceHourlyCharge">
                                              Charge
                                                                                </th>
                                                                                <th className="invoiceHourlyResource">
                                              Resource
                                                                                </th>
                                                                                <th className="invoiceHourlyStart">
                                              Start Time
                                                                                </th>
                                                                                <th className="invoiceHourlyStop">
                                              Stop Time
                                                                                </th>
                                                                                <th className="invoiceHourlyBill">
                                              Bill Qty
                                                                                </th>
                                                                                <th className="invoiceHourlyBill">
                                              Other CT Groups
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {!details.length ? (
                                                                                <tr>
                                                                                    <td
                                                                                        colSpan="11"
                                                                                        className="text-center"
                                                                                    >
                                                No charge type group detail.
                                                                                    </td>
                                                                                </tr>
                                                                            ) : (
                                                                                details.map((
                                                                                    ctDetail,
                                                                                    ctrDetailIndex,
                                                                                ) => (
                                                                                    <tr>
                                                                                        <td className="invoiceHourlyCharge">
                                                                                            {ctDetail.chargeTypeName}
                                                                                            {' '}
                                                  -
                                                                                            {ctDetail.calculatedBillingRateTypeName}

                                                                                        </td>
                                                                                        <td className="invoiceHourlyResource">
                                                                                            {
                                                                                                ctDetail.resourceName
                                                                                            }
                                                                                        </td>
                                                                                        <td className="invoiceHourlyStart">
                                                                                            {
                                                                                                ctDetail.startDisplay
                                                                                            }
                                                                                        </td>
                                                                                        <td className="invoiceHourlyStop">
                                                                                            {
                                                                                                ctDetail.endDisplay
                                                                                            }
                                                                                        </td>
                                                                                        <td className="invoiceHourlyBill">
                                                                                            {parseFloat(
                                                                                                ctDetail.billQty,
                                                                                            ).toFixed(2)}
                                                                                        </td>
                                                                                        <td className="invoiceHourlyBill">
                                                                                            {ctDetail.refOtherInvoices}
                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                            )}
                                                                        </tbody>
                                                                    </table>

                                                                </CollapseTableElement>

                                                            </td>
                                                            <td className="invoiceHourlyDate">
                                                                {
                                                                    ctGroup.dateDisplay
                                                                }
                                                            </td>
                                                            <td className="invoiceHourlyStart">
                                                                {
                                                                    parseFloat(ctGroup.billingRate).toFixed(2)
                                                                }
                                                            </td>
                                                            <td className="invoiceHourlyStop">
                                                                {
                                                                    parseFloat(ctGroup.chargeQty).toFixed(2)
                                                                }
                                                            </td>
                                                            {(isNew || invoice?.invoiceStatusId == InvoiceStatus.New)
                                                                && (
                                                                    <>
                                                                        <td className="timesheetView text-center" style={{ padding: 0 }}>
                                                                            <button
                                                                                type="button"
                                                                                className="btn shadow-none"
                                                                                title="Edit this group"
                                                                                onClick={() => this.onEditChargeTypeGroup(ctGroup)}
                                                                            >
                                                                                <span className="mt-1">
                                                                                    <i className="fa fa-edit" style={{ fontSize: '12px' }} />
                                                                                </span>
                                                                            </button>
                                                                        </td>
                                                                        <td className="timesheetDelete text-center" style={{ padding: 0 }}>
                                                                            <button
                                                                                type="button"
                                                                                className="btn shadow-none"
                                                                                title="Remove this group"
                                                                                onClick={() => {
                                                                                    this.onDeleteChargeTypeGroup(ctGroup);
                                                                                }}
                                                                            >
                                                                                <span className="text-danger mt-1">
                                                                                    <i className="fa fa-trash" style={{ fontSize: '12px' }} />
                                                                                </span>
                                                                            </button>
                                                                        </td>
                                                                    </>
                                                                )}
                                                        </tr>
                                                    );
                                                },
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </CollapseUnderlineHeaderWithIcon>

                        <CollapseUnderlineHeader
                            headerText="Summary of Billable Hourly Charges"
                            isOpen={chargeTypeGroupsOpen}
                            toggleCollapse={() => this.setState({
                                chargeTypeGroupsOpen: !chargeTypeGroupsOpen,
                            })}
                        >
                            <div className="invoice">
                                <table className="table table-sm table-bordered table-striped payroll-table">
                                    <thead className="text-muted">
                                        <tr>
                                            <th className="invoiceHourlyCharge">
                            Charge
                                            </th>
                                            <th className="invoiceHourlyResource">
                            Resource
                                            </th>
                                            <th className="invoiceHourlyDate">
                            Date
                                            </th>
                                            <th className="invoiceHourlyStart">
                            Start Time
                                            </th>
                                            <th className="invoiceHourlyStop">
                            Stop Time
                                            </th>
                                            <th className="invoiceHourlyCharge">
                            Charge Qty
                                            </th>
                                            <th className="invoiceHourlyBill">
                            Bill Qty
                                            </th>
                                            <th className="invoiceHourlyEmergency">
                            Emergency
                                            </th>
                                            <th className="invoiceHourlyOverride">
                            Bill Override
                                            </th>
                                            <th className="invoiceHourlyCalc">
                            Calc. Rate Type
                                            </th>
                                            <th className="invoiceHourlyRate">
                            Rate
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!charges.length ? (
                                            <tr>
                                                <td
                                                    colSpan="11"
                                                    className="text-center"
                                                >
                              No Billable Hourly Charges
                              found.
                                                </td>
                                            </tr>
                                        ) : (
                                            charges.map(
                                                (
                                                    hourlyCharge,
                                                    charge_index,
                                                ) => (
                                                    <tr
                                                        key={`${hourlyCharge.billingDetailId
                                                        }|${uuid()}`}
                                                    >
                                                        <td className="invoiceHourlyCharge">
                                                            {
                                                                hourlyCharge.chargeTypeName
                                                            }
                                                        </td>
                                                        <td className="invoiceHourlyResource">
                                                            {
                                                                hourlyCharge.resourceName
                                                            }
                                                        </td>
                                                        <td className="invoiceHourlyDate">
                                                            {
                                                                hourlyCharge.dateDisplay
                                                            }
                                                        </td>
                                                        <td className="invoiceHourlyStart">
                                                            {
                                                                hourlyCharge.startDisplay
                                                            }
                                                        </td>
                                                        <td className="invoiceHourlyStop">
                                                            {
                                                                hourlyCharge.endDisplay
                                                            }
                                                        </td>
                                                        <td className="invoiceHourlyCharge">
                                                            {parseFloat(
                                                                hourlyCharge.chargeQty,
                                                            ).toFixed(2)}
                                                        </td>
                                                        <td className="invoiceHourlyBill">
                                                            {parseFloat(
                                                                hourlyCharge.billQty,
                                                            ).toFixed(2)}
                                                        </td>
                                                        <td className="invoiceHourlyEmergency">
                                                            {hourlyCharge.emergency
                                                                ? 'Yes'
                                                                : 'No'}
                                                        </td>
                                                        <td className="invoiceHourlyOverride">
                                                            {
                                                                hourlyCharge.billingOverrideStatus
                                                            }
                                                        </td>
                                                        <td className="invoiceHourlyCalcRateType">
                                                            {
                                                                hourlyCharge.calculatedBillingRateTypeName
                                                            }
                                                        </td>
                                                        <td className="invoiceHourlyRate">
                                                            {`$${parseFloat(
                                                                hourlyCharge.billingRate,
                                                            ).toFixed(2)}`}
                                                        </td>
                                                    </tr>
                                                ),
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </CollapseUnderlineHeader>

                        <CollapseUnderlineHeader
                            headerText="Summary of Billable Flat Charge"
                            isOpen={flatChargesOpen}
                            toggleCollapse={() => this.setState({
                                flatChargesOpen: !flatChargesOpen,
                            })}
                        >
                            <div className="invoice">
                                <table className="table table-sm table-bordered table-striped payroll-table">
                                    <thead className="text-muted">
                                        <tr>
                                            <th className="invoiceFlatCharge">
                            Charge
                                            </th>
                                            <th className="invoiceFlatResource">
                            Resource
                                            </th>
                                            <th className="invoiceFlatDate">
                            Date
                                            </th>
                                            <th className="invoiceFlatCharge">
                            Charge Qty
                                            </th>
                                            <th className="invoiceFlatBill">
                            Bill Qty
                                            </th>
                                            <th className="invoiceFlatEmergency">
                            Emergency
                                            </th>
                                            <th className="invoiceFlatOverride">
                            Bill Override
                                            </th>
                                            <th className="invoiceFlatCalc">
                            Calc. Rate Type
                                            </th>
                                            <th className="invoiceFlatRate">
                            Rate
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!flatten(
                                            (
                                                invoice.invoiceTimesheets ?? []
                                            ).map((x) => x.invoiceCharges),
                                        ).filter(
                                            (x) => x.chargeTypeUnitsId
                                                == ChargeTypeUnits.Flat,
                                        ).length ? (
                                                <tr>
                                                    <td
                                                        colSpan="9"
                                                        className="text-center"
                                                    >
                              No Billable Flat Charges
                              found.
                                                    </td>
                                                </tr>
                                            ) : (
                                                flatten(
                                                    (
                                                        invoice.invoiceTimesheets
                                                    ?? []
                                                    ).map((x) => x.invoiceCharges),
                                                )
                                                    .filter(
                                                        (x) => x.chargeTypeUnitsId
                                                        == ChargeTypeUnits.Flat,
                                                    )
                                                    .map(
                                                        (
                                                            flatCharge,
                                                            charge_index,
                                                        ) => (
                                                            <tr
                                                                key={`${flatCharge.billingDetailId
                                                                }|${uuid()}`}
                                                            >
                                                                <td className="invoiceFlatCharge">
                                                                    {
                                                                        flatCharge.chargeTypeName
                                                                    }
                                                                </td>
                                                                <td className="invoiceFlatResource">
                                                                    {
                                                                        flatCharge.resourceName
                                                                    }
                                                                </td>
                                                                <td className="invoiceFlatDate">
                                                                    {
                                                                        flatCharge.dateDisplay
                                                                    }
                                                                </td>
                                                                <td className="invoiceFlatCharge">
                                                                    {parseFloat(
                                                                        flatCharge.chargeQty,
                                                                    ).toFixed(
                                                                        2,
                                                                    )}
                                                                </td>
                                                                <td className="invoiceFlatBill">
                                                                    {parseFloat(
                                                                        flatCharge.billQty,
                                                                    ).toFixed(
                                                                        2,
                                                                    )}
                                                                </td>
                                                                <td className="invoiceFlatEmergency">
                                                                    {flatCharge.emergency
                                                                        ? 'Yes'
                                                                        : 'No'}
                                                                </td>
                                                                <td className="invoiceFlatOverride">
                                                                    {flatCharge.override
                                                                        ? 'Yes'
                                                                        : 'No'}
                                                                </td>
                                                                <td className="invoiceFlatCalc">
                                                                    {
                                                                        flatCharge.BillingOverrideStatus
                                                                    }
                                                                </td>
                                                                <td className="invoiceFlatRate">
                                                                    {`$${parseFloat(
                                                                        flatCharge.billingRate,
                                                                    ).toFixed(
                                                                        2,
                                                                    )}`}
                                                                </td>
                                                            </tr>
                                                        ),
                                                    )
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </CollapseUnderlineHeader>
                        {invoice?.invoiceStatusId >= InvoiceStatus.Draft && (
                            <>
                                <CollapseUnderlineHeader
                                    headerText="Charge Details"
                                    isOpen={chargeDetailsOpen}
                                    toggleCollapse={() => this.setState({
                                        chargeDetailsOpen:
                                                !chargeDetailsOpen,
                                    })}
                                >
                                    <div className="invoice">
                                        <SmallButton
                                            type="button"
                                            disabled={showReorderWidget}
                                            onClick={
                                                this
                                                    .onReorderInvoiceChargeDetails
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faSort}
                                                className="mr-2"
                                            />
                        Reorder
                                        </SmallButton>
                                        <table className="table table-sm table-bordered table-striped timesheet-details-table">
                                            <thead className="text-muted">
                                                <tr>
                                                    <th className="isadjusted" />
                                                    {invoice.useCTRReport
                                                        == 'True' && (
                                                        <th className="serviceItem">
                                                          Service Item
                                                        </th>
                                                    )}
                                                    {invoice.allowMultipleWONumbers && (
                                                        <th className="serviceItem">
                              Workorder Number
                                                        </th>
                                                    )}
                                                    <th className="invoiceChargeQty">
                              System Qty
                                                    </th>
                                                    <th className="invoiceChargeQty">
                              Invoice Qty
                                                    </th>
                                                    <th className="invoiceChargeDescription">
                              System Description
                                                    </th>
                                                    <th className="invoiceChargeDescription">
                              Invoice Description
                                                    </th>
                                                    <th className="invoiceChargeRate">
                              System Rate
                                                    </th>
                                                    <th className="invoiceChargeRate">
                              Invoice Rate
                                                    </th>
                                                    <th className="invoiceChargeAmount">
                              System Amount
                                                    </th>
                                                    <th className="invoiceUserChargeAmount">
                              Invoice Amount
                                                    </th>
                                                    {associateInventoryItem && (
                                                        <th className="invoiceUserQuickBooksInventoryItem">
                              Inventory Item
                                                        </th>
                                                    )}
                                                    {associateInventoryItem && (
                                                        <th className="invoiceUserQuickBooksSalesAccount">
                              Sales Account
                                                        </th>
                                                    )}
                                                    <th
                                                        style={{ width: '5%' }}
                                                    />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!(
                                                    invoice.invoiceChargeDetails
                                                    ?? []
                                                ).length ? (
                                                        <tr>
                                                            <td
                                                                colSpan="4"
                                                                className="text-center"
                                                            >
                                No Charge Details
                                found.
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        invoice.invoiceChargeDetails.map(
                                                            (
                                                                chargeDetail,
                                                                charge_index,
                                                            ) => {
                                                                const readOnly = invoice?.invoiceStatusId == InvoiceStatus.Approved
                                    || invoice?.invoiceStatusId == InvoiceStatus.Exported;

                                                                if (!chargeDetail.removed) {
                                                                    chargeDetailTotal += chargeDetail.userChargeAmount;
                                                                }

                                                                if (chargeDetail.invoiceChargeDetailTypeId == InvoiceChargeDetailType.Header) {
                                                                    return (
                                                                        <tr
                                                                            key={chargeDetail.id}
                                                                            className={`timesheet-details-table-row${chargeDetail.removed
                                                                                ? ' strikeout'
                                                                                : ''
                                                                            }`}
                                                                        >
                                                                            <td>
                                                                                <span>
                                                                                    <Badge
                                                                                        color="danger"
                                                                                        className="p-1"
                                                                                    >
                                              HDR
                                                                                    </Badge>
                                                                                </span>
                                                                            </td>
                                                                            <td colSpan="4">
                                                                                <Input
                                                                                    type="textarea"
                                                                                    name="userDescription"
                                                                                    value={chargeDetail.userDescription ?? ''}
                                                                                    onChange={(
                                                                                        evt,
                                                                                    ) => {
                                                                                        const { value } = evt
                                                                                            .target;
                                                                                        const {
                                                                                            invoice,
                                                                                        } = {
                                                                                            ...this
                                                                                                .state,
                                                                                        };
                                                                                        invoice.invoiceChargeDetails[
                                                                                            charge_index
                                                                                        ].userDescription = value;
                                                                                        this.setState(
                                                                                            {
                                                                                                invoice,
                                                                                            },
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                            <td className="invoiceChargeDelete text-center">
                                                                                {invoice?.invoiceStatusId != InvoiceStatus.Exported && (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn shadow-none"
                                                                                        title="Remove this header"
                                                                                        onClick={() => {
                                                                                            this.onRemoveInvoiceChargeDetailClicked(
                                                                                                chargeDetail,
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <span className="text-danger mt-1">
                                                                                            <i className="fa fa-eraser" />
                                                                                        </span>
                                                                                    </button>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }

                                                                if (chargeDetail.invoiceChargeDetailTypeId == InvoiceChargeDetailType.Annotation) {
                                                                    return (
                                                                        <tr
                                                                            key={chargeDetail.id}
                                                                            className={`timesheet-details-table-row${chargeDetail.removed
                                                                                ? ' strikeout'
                                                                                : ''
                                                                            }`}
                                                                        >
                                                                            <td>
                                                                                <span>
                                                                                    <Badge
                                                                                        color="primary"
                                                                                        className="p-1"
                                                                                    >
                                              TXT
                                                                                    </Badge>
                                                                                </span>
                                                                            </td>
                                                                            <td colSpan="4">
                                                                                <Input
                                                                                    type="textarea"
                                                                                    name="userDescription"
                                                                                    value={chargeDetail.userDescription ?? ''}
                                                                                    onChange={(
                                                                                        evt,
                                                                                    ) => {
                                                                                        const { value } = evt
                                                                                            .target;
                                                                                        const {
                                                                                            invoice,
                                                                                        } = {
                                                                                            ...this
                                                                                                .state,
                                                                                        };
                                                                                        invoice.invoiceChargeDetails[
                                                                                            charge_index
                                                                                        ].userDescription = value;
                                                                                        this.setState(
                                                                                            {
                                                                                                invoice,
                                                                                            },
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                            <td className="invoiceChargeDelete text-center">
                                                                                {!!chargeDetail.isAdjustment && invoice?.invoiceStatusId
                                            != InvoiceStatus.Exported && (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn shadow-none"
                                                                                        title="Remove this annotation"
                                                                                        onClick={() => {
                                                                                            this.onRemoveInvoiceChargeDetailClicked(
                                                                                                chargeDetail,
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <span className="text-danger mt-1">
                                                                                            <i className="fa fa-eraser" />
                                                                                        </span>
                                                                                    </button>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }

                                                                if (chargeDetail.invoiceChargeDetailTypeId == InvoiceChargeDetailType.LineItem) {
                                                                    return (
                                                                        <tr
                                                                            key={chargeDetail.id}
                                                                            className={`timesheet-details-table-row${chargeDetail.removed
                                                                                ? ' strikeout'
                                                                                : ''
                                                                            }`}
                                                                        >
                                                                            <td>
                                                                                {!!chargeDetail.isAdjustment && (
                                                                                    <span className="invoice-lineitem-isadjusted">
                                                                                        <Badge className="p-1">
                                              ADJ
                                                                                        </Badge>
                                                                                    </span>
                                                                                )}
                                                                                {((!chargeDetail.isAdjustment && chargeDetail.chargeQty !== chargeDetail.userChargeQty)
                                            || chargeDetail.billingRate !== chargeDetail.userBillingRate) && (
                                                                                    <Badge
                                                                                        color="success"
                                                                                        className="p-1"
                                                                                    >
                                              EDT
                                                                                    </Badge>
                                                                                )}
                                                                            </td>
                                                                            {invoice.allowMultipleWONumbers && (
                                                                                <td width="225">
                                                                                    {readOnly && (
                                                                                        <span>{chargeDetail.workOrderNumber}</span>
                                                                                    )}

                                                                                    {!readOnly && (
                                                                                        <Input
                                                                                            type="text"
                                                                                            name="workOrderNumber"
                                                                                            value={chargeDetail.workOrderNumber ?? ''}
                                                                                            onChange={(
                                                                                                evt,
                                                                                            ) => {
                                                                                                const { value } = evt
                                                                                                    .target;
                                                                                                const {
                                                                                                    invoice,
                                                                                                } = {
                                                                                                    ...this
                                                                                                        .state,
                                                                                                };
                                                                                                invoice.invoiceChargeDetails[
                                                                                                    charge_index
                                                                                                ].workOrderNumber = value;
                                                                                                this.setState(
                                                                                                    {
                                                                                                        invoice,
                                                                                                    },
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </td>
                                                                            )}
                                                                            {invoice.useCTRReport == 'True' && readOnly && (
                                                                                <td>
                                                                                    {
                                                                                        invoice
                                                                                            .invoiceChargeDetails[
                                                                                                charge_index
                                                                                            ]
                                                                                            .serviceItem
                                                                                    }
                                                                                </td>
                                                                            )}

                                                                            {invoice.useCTRReport == 'True' && !readOnly && (
                                                                                <td width="250">
                                                                                    <Select
                                                                                        id={`serviceItem-${chargeDetail.id}`}
                                                                                        name={`serviceItem-${chargeDetail.id}`}
                                                                                        options={
                                                                                            serviceItems
                                                                                        }
                                                                                        value={
                                                                                            (
                                                                                                serviceItems
                                                              ?? []
                                                                                            ).find(
                                                                                                (
                                                                                                    s,
                                                                                                ) => s.value
                                                                  == invoice
                                                                      .invoiceChargeDetails[
                                                                          charge_index
                                                                      ]
                                                                      .serviceItem,
                                                                                            )
                                                          ?? ''
                                                                                        }
                                                                                        onChange={(
                                                                                            evt,
                                                                                        ) => {
                                                                                            const { value } = evt;
                                                                                            const {
                                                                                                invoice,
                                                                                            } = {
                                                                                                ...this
                                                                                                    .state,
                                                                                            };
                                                                                            invoice.invoiceChargeDetails[
                                                                                                charge_index
                                                                                            ].serviceItem = value;
                                                                                            this.setState(
                                                                                                {
                                                                                                    invoice,
                                                                                                },
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                            )}
                                                                            <td className="invoiceChargeQty">
                                                                                {parseFloat(
                                                                                    chargeDetail.chargeQty,
                                                                                ).toFixed(
                                                                                    2,
                                                                                )}
                                                                            </td>

                                                                            <td
                                                                                className="invoiceUserChargeQty"
                                                                                width="150"
                                                                            >
                                                                                {!readOnly && (
                                                                                    <NumericInput
                                                                                        className="form-control-sm text-right"
                                                                                        name="userChargeQty"
                                                                                        required
                                                                                        value={chargeDetail.userChargeQty ?? ''}
                                                                                        onChange={(
                                                                                            evt,
                                                                                        ) => {
                                                                                            const { value } = evt
                                                                                                .target;
                                                                                            const {
                                                                                                invoice,
                                                                                            } = {
                                                                                                ...this
                                                                                                    .state,
                                                                                            };
                                                                                            invoice.invoiceChargeDetails[
                                                                                                charge_index
                                                                                            ].userChargeQty = parseFloat(
                                                                                                value,
                                                                                            );
                                                                                            invoice.invoiceChargeDetails[
                                                                                                charge_index
                                                                                            ].userChargeAmount = parseFloat(
                                                                                                chargeDetail.userChargeQty,
                                                                                            )
                                              * parseFloat(
                                                  chargeDetail.userBillingRate,
                                              );
                                                                                            this.setState(
                                                                                                {
                                                                                                    invoice,
                                                                                                },
                                                                                            );
                                                                                        }}
                                                                                        type="number"
                                                                                        min="0"
                                                                                        step="0.5"
                                                                                        preventNegative
                                                                                    />
                                                                                )}
                                                                                {readOnly && (
                                                                                    <span>
                                                                                        {parseFloat(
                                                                                            chargeDetail.userChargeQty,
                                                                                        ).toFixed(
                                                                                            2,
                                                                                        )}
                                                                                    </span>
                                                                                )}
                                                                            </td>

                                                                            <td className="invoiceChargeDescription">
                                                                                {chargeDetail.description}
                                                                            </td>
                                                                            <td className="invoiceChargeDescription">
                                                                                {!readOnly && (
                                                                                    <Input
                                                                                        type="textarea"
                                                                                        name="userDescription"
                                                                                        value={chargeDetail.userDescription ?? ''}
                                                                                        onChange={(
                                                                                            evt,
                                                                                        ) => {
                                                                                            const { value } = evt
                                                                                                .target;
                                                                                            const {
                                                                                                invoice,
                                                                                            } = {
                                                                                                ...this
                                                                                                    .state,
                                                                                            };
                                                                                            invoice.invoiceChargeDetails[
                                                                                                charge_index
                                                                                            ].userDescription = value;
                                                                                            this.setState(
                                                                                                {
                                                                                                    invoice,
                                                                                                },
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                                {readOnly && (
                                                                                    <span>
                                                                                        {chargeDetail.userDescription}
                                                                                    </span>
                                                                                )}
                                                                            </td>
                                                                            <td className="invoiceBillingRate">
                                                                                {parseFloat(
                                                                                    chargeDetail.billingRate,
                                                                                ).toFixed(
                                                                                    2,
                                                                                )}
                                                                            </td>
                                                                            <td
                                                                                className="invoiceUserChargeRate"
                                                                                width="150"
                                                                            >
                                                                                {!readOnly && (
                                                                                    <NumericInput
                                                                                        className="form-control-sm text-right"
                                                                                        name="userBillingRate"
                                                                                        required
                                                                                        value={chargeDetail.userBillingRate ?? ''}
                                                                                        onChange={(
                                                                                            evt,
                                                                                        ) => {
                                                                                            const { value } = evt
                                                                                                .target;
                                                                                            const {
                                                                                                invoice,
                                                                                            } = {
                                                                                                ...this
                                                                                                    .state,
                                                                                            };
                                                                                            invoice.invoiceChargeDetails[
                                                                                                charge_index
                                                                                            ].userBillingRate = parseFloat(
                                                                                                value,
                                                                                            );
                                                                                            invoice.invoiceChargeDetails[
                                                                                                charge_index
                                                                                            ].userChargeAmount = parseFloat(
                                                                                                chargeDetail.userChargeQty,
                                                                                            )
                                                                                            * parseFloat(
                                                                                                chargeDetail.userBillingRate,
                                                                                            );
                                                                                            this.setState(
                                                                                                {
                                                                                                    invoice,
                                                                                                },
                                                                                            );
                                                                                        }}
                                                                                        type="number"
                                                                                        min="0"
                                                                                        step="0.5"
                                                                                        preventNegative
                                                                                    />
                                                                                )}
                                                                                {readOnly && (
                                                                                    <span>
                                                                                        {parseFloat(
                                                                                            chargeDetail.userBillingRate,
                                                                                        ).toFixed(
                                                                                            2,
                                                                                        )}
                                                                                    </span>
                                                                                )}
                                                                            </td>
                                                                            <td className="invoiceChargeAmount">
                                                                                {parseFloat(
                                                                                    chargeDetail.chargeAmount,
                                                                                ).toFixed(
                                                                                    2,
                                                                                )}
                                                                            </td>
                                                                            <td className="invoiceUserChargeAmount">
                                                                                {parseFloat(
                                                                                    chargeDetail.userChargeAmount,
                                                                                ).toFixed(
                                                                                    2,
                                                                                )}
                                                                            </td>
                                                                            {associateInventoryItem && (
                                                                                <td
                                                                                    className="invoiceUserQuickBooksInventoryItem"
                                                                                    width="150"
                                                                                >
                                                                                    {!readOnly && (
                                                                                        <Select
                                                                                            id={`userQuickBooksInventoryItem-${chargeDetail.id}`}
                                                                                            name={`userQuickBooksInventoryItem-${chargeDetail.id}`}
                                                                                            options={
                                                                                                inventoryItems
                                                                                            }
                                                                                            value={
                                                                                                (
                                                                                                    inventoryItems
                                                                                                ?? []
                                                                                                ).find(
                                                                                                    (
                                                                                                        s,
                                                                                                    ) => s.value
                                                                                                    == invoice
                                                                                                        .invoiceChargeDetails[
                                                                                                            charge_index
                                                                                                        ]
                                                                                                        .userQuickBooksInventoryItem,
                                                                                                )
                                                                                            ?? ''
                                                                                            }
                                                                                            onChange={(
                                                                                                evt,
                                                                                            ) => {
                                                                                                const { value } = evt;
                                                                                                const {
                                                                                                    invoice,
                                                                                                } = {
                                                                                                    ...this
                                                                                                        .state,
                                                                                                };
                                                                                                invoice.invoiceChargeDetails[
                                                                                                    charge_index
                                                                                                ].userQuickBooksInventoryItem = value;
                                                                                                this.setState(
                                                                                                    {
                                                                                                        invoice,
                                                                                                    },
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                    {readOnly && (
                                                                                        <span>
                                                                                            {
                                                                                                chargeDetail.userQuickBooksInventoryItem
                                                                                            }
                                                                                        </span>
                                                                                    )}
                                                                                </td>
                                                                            )}
                                                                            {associateInventoryItem && (
                                                                                <td
                                                                                    className="invoiceUserQuickBooksSalesAccount"
                                                                                    width="150"
                                                                                >
                                                                                    {!readOnly && (
                                                                                        <Select
                                                                                            id={`userQuickBooksSalesAccount-${chargeDetail.id}`}
                                                                                            name={`userQuickBooksSalesAccount-${chargeDetail.id}`}
                                                                                            options={
                                                                                                salesAccounts
                                                                                            }
                                                                                            value={
                                                                                                (
                                                                                                    salesAccounts
                                                                                                ?? []
                                                                                                ).find(
                                                                                                    (
                                                                                                        s,
                                                                                                    ) => s.value
                                                                                                    == invoice
                                                                                                        .invoiceChargeDetails[
                                                                                                            charge_index
                                                                                                        ]
                                                                                                        .userQuickBooksSalesAccount,
                                                                                                )
                                                                                            ?? ''
                                                                                            }
                                                                                            onChange={(
                                                                                                evt,
                                                                                            ) => {
                                                                                                const { value } = evt;
                                                                                                const {
                                                                                                    invoice,
                                                                                                } = {
                                                                                                    ...this
                                                                                                        .state,
                                                                                                };
                                                                                                invoice.invoiceChargeDetails[
                                                                                                    charge_index
                                                                                                ].userQuickBooksSalesAccount = value;
                                                                                                this.setState(
                                                                                                    {
                                                                                                        invoice,
                                                                                                    },
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                    {readOnly && (
                                                                                        <span>
                                                                                            {
                                                                                                chargeDetail.userQuickBooksSalesAccount
                                                                                            }
                                                                                        </span>
                                                                                    )}
                                                                                </td>
                                                                            )}
                                                                            <td className="invoiceChargeDelete text-center">
                                                                                {!chargeDetail.isAdjustment
                                                                                && !readOnly && (
                                                                                    <button
                                                                                        type="button"
                                                                                        className={`btn shadow-none details-remove-button ${chargeDetail.removed
                                                                                            ? ' removed'
                                                                                            : ''
                                                                                        }`}
                                                                                        title="Remove this invoiceCharge"
                                                                                        onClick={() => {
                                                                                            this.onRemoveInvoiceChargeDetailClicked(
                                                                                                chargeDetail,
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <span className="text-danger mt-1">
                                                                                            <i className="fa fa-trash" />
                                                                                        </span>
                                                                                    </button>
                                                                                )}
                                                                                {!!chargeDetail.isAdjustment
                                                                                && !readOnly && (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn shadow-none"
                                                                                        title="Remove this invoiceCharge"
                                                                                        onClick={() => {
                                                                                            this.onRemoveInvoiceChargeDetailClicked(
                                                                                                chargeDetail,
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <span className="text-danger mt-1">
                                                                                            <i className="fa fa-eraser" />
                                                                                        </span>
                                                                                    </button>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }

                                                                return null;
                                                            },
                                                        )
                                                    )}
                                                <tr>
                                                    <td
                                                        colSpan="8"
                                                        align="right"
                                                    >
                              Total:&nbsp;&nbsp;
                                                    </td>
                                                    <td>
                                                        {parseFloat(chargeDetailTotal).toFixed(2)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {!!(adjustments ?? []).length && (
                                        <div className="invoice">
                                            {true && (
                                                <>
                                                    <small className="text-warning font-weight-bold">
                          INVOICE CUSTOMIZATION
                                                    </small>
                                                    <table className="table table-sm table-bordered table-striped payroll-table">
                                                        <thead className="text-muted">
                                                            <tr>
                                                                <th className="isadjusted" />
                                                                {invoice.useCTRReport
                                                                    == 'True' && (
                                                                    <th className="serviceItem">
                                                                      Service
                                                                      Item
                                                                    </th>
                                                                )}
                                                                <th
                                                                    className="invoiceChargeQty"
                                                                    width="150"
                                                                >
                                Qty
                                                                </th>
                                                                <th className="invoiceChargeDescription">
                                Description
                                                                </th>
                                                                <th
                                                                    className="invoiceChargeRate"
                                                                    width="150"
                                                                >
                                Rate
                                                                </th>
                                                                <th className="invoiceChargeAmount">
                                Amount
                                                                </th>
                                                                {associateInventoryItem && (
                                                                    <th className="invoiceUserQuickBooksInventoryItem">
                                Inventory
                                Item
                                                                    </th>
                                                                )}
                                                                {associateInventoryItem && (
                                                                    <th className="invoiceUserQuickBooksSalesAccount">
                                Sales
                                Accountt
                                                                    </th>
                                                                )}
                                                                <th
                                                                    style={{
                                                                        width: '5%',
                                                                    }}
                                                                />
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!(
                                                                adjustments
                                                                ?? []
                                                            ).length ? (
                                                                    <tr>
                                                                        <td
                                                                            colSpan="4"
                                                                            className="text-center"
                                                                        >
                                  No
                                  adjustments
                                  found.
                                                                        </td>
                                                                    </tr>
                                                                ) : (
                                                                    adjustments.map(
                                                                        (
                                                                            adjustment,
                                                                            i,
                                                                        ) => {
                                                                            if (
                                                                                adjustment.invoiceChargeDetailTypeId
                                                                            == InvoiceChargeDetailType.Header
                                                                            ) {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span>
                                                                                                <Badge
                                                                                                    color="danger"
                                                                                                    className="p-1"
                                                                                                >
                                                HDR
                                                                                                </Badge>
                                                                                            </span>
                                                                                        </td>
                                                                                        <td
                                                                                            className="invoiceChargeDescription"
                                                                                            colSpan="2"
                                                                                        >
                                                                                            <Input
                                                                                                type="textarea"
                                                                                                name="description"
                                                                                                value={
                                                                                                    adjustment.description
                                                                                                ?? ''
                                                                                                }
                                                                                                onChange={(
                                                                                                    evt,
                                                                                                ) => {
                                                                                                    const { value } = evt
                                                                                                        .target;
                                                                                                    const {
                                                                                                        adjustments,
                                                                                                    } = {
                                                                                                        ...this
                                                                                                            .state,
                                                                                                    };
                                                                                                    adjustments[
                                                                                                        i
                                                                                                    ].description = value;
                                                                                                    this.setState(
                                                                                                        {
                                                                                                            adjustments,
                                                                                                        },
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </td>
                                                                                        <td className="invoiceChargeDelete text-center">
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn shadow-none"
                                                                                                title="Remove this Charge Detail"
                                                                                                onClick={() => {
                                                                                                    const {
                                                                                                        adjustments,
                                                                                                    } = {
                                                                                                        ...this
                                                                                                            .state,
                                                                                                    };
                                                                                                    adjustments.splice(
                                                                                                        i,
                                                                                                        1,
                                                                                                    );
                                                                                                    this.setState(
                                                                                                        {
                                                                                                            adjustments,
                                                                                                        },
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <span className="text-danger mt-1">
                                                                                                    <i className="fa fa-trash" />
                                                                                                </span>
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }

                                                                            if (
                                                                                adjustment.invoiceChargeDetailTypeId
                                                                            == InvoiceChargeDetailType.Annotation
                                                                            ) {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span>
                                                                                                <Badge
                                                                                                    color="primary"
                                                                                                    className="p-1"
                                                                                                >
                                                TXT
                                                                                                </Badge>
                                                                                            </span>
                                                                                        </td>
                                                                                        <td
                                                                                            className="invoiceChargeDescription"
                                                                                            colSpan="2"
                                                                                        >
                                                                                            <Input
                                                                                                type="textarea"
                                                                                                name="description"
                                                                                                value={
                                                                                                    adjustment.description
                                                                                                ?? ''
                                                                                                }
                                                                                                onChange={(
                                                                                                    evt,
                                                                                                ) => {
                                                                                                    const { value } = evt
                                                                                                        .target;
                                                                                                    const {
                                                                                                        adjustments,
                                                                                                    } = {
                                                                                                        ...this
                                                                                                            .state,
                                                                                                    };
                                                                                                    adjustments[
                                                                                                        i
                                                                                                    ].description = value;
                                                                                                    this.setState(
                                                                                                        {
                                                                                                            adjustments,
                                                                                                        },
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </td>
                                                                                        <td className="invoiceChargeDelete text-center">
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn shadow-none"
                                                                                                title="Remove this Charge Detail"
                                                                                                onClick={() => {
                                                                                                    const {
                                                                                                        adjustments,
                                                                                                    } = {
                                                                                                        ...this
                                                                                                            .state,
                                                                                                    };
                                                                                                    adjustments.splice(
                                                                                                        i,
                                                                                                        1,
                                                                                                    );
                                                                                                    this.setState(
                                                                                                        {
                                                                                                            adjustments,
                                                                                                        },
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <span className="text-danger mt-1">
                                                                                                    <i className="fa fa-trash" />
                                                                                                </span>
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }

                                                                            if (
                                                                                adjustment.invoiceChargeDetailTypeId
                                                                            == InvoiceChargeDetailType.LineItem
                                                                            ) {
                                                                                // Filter the staff by eligible app groups on the selected charge type.
                                                                                const charge_type_user_groups = (
                                                                                    (
                                                                                        adjustmentHelper.chargeType
                                                                                        ?? {}
                                                                                    )
                                                                                        .applicationGroup
                                                                                    ?? {}
                                                                                )
                                                                                    .applicationGroupUserGroups
                                                                                ?? [];
                                                                                const charge_type_user_groups_ids = charge_type_user_groups.map(
                                                                                    (
                                                                                        x,
                                                                                    ) => x.applicationUserGroupId,
                                                                                );
                                                                                const filtered_staff = staff.filter(
                                                                                    (
                                                                                        x,
                                                                                    ) => !!(
                                                                                        x.groupIds.filter(
                                                                                            (
                                                                                                value,
                                                                                            ) => charge_type_user_groups_ids.includes(
                                                                                                value,
                                                                                            ),
                                                                                        )
                                                                                            ?? []
                                                                                    )
                                                                                        .length,
                                                                                );

                                                                                // Filter the equipment by eligible types on the selected charge type.
                                                                                const charge_type_eq_types = (
                                                                                    adjustmentHelper.chargeType
                                                                                    ?? {}
                                                                                )
                                                                                    .equipmentTypes
                                                                                ?? [];
                                                                                const charge_type_eq_types_ids = charge_type_eq_types.map(
                                                                                    (
                                                                                        x,
                                                                                    ) => x.id,
                                                                                );
                                                                                const filtered_equipment = equipment.filter(
                                                                                    (
                                                                                        x,
                                                                                    ) => charge_type_eq_types_ids.includes(
                                                                                        x.equipmentTypeId,
                                                                                    ),
                                                                                );

                                                                                const chargeType = contract.contractChargeTypes.filter(
                                                                                    (
                                                                                        x,
                                                                                    ) => x.chargeTypeId
                                                                                        == (
                                                                                            adjustmentHelper.chargeType
                                                                                            ?? {}
                                                                                        )
                                                                                            .id,
                                                                                );

                                                                                let billingRates = [];
                                                                                if (
                                                                                    chargeType.length
                                                                                > 0
                                                                                && chargeType[0]
                                                                                    .billingRate
                                                                                != null
                                                                                ) {
                                                                                    const regDesc = chargeType[0]
                                                                                        .billingRate
                                                                                        .regularRateDescription
                                                                                        != null
                                                                                        && chargeType[0]
                                                                                            .billingRate
                                                                                            .regularRateDescription
                                                                                            .length
                                                                                        > 0
                                                                                        ? chargeType[0]
                                                                                            .billingRate
                                                                                            .regularRateDescription
                                                                                        : 'REG';
                                                                                    const otDesc = chargeType[0]
                                                                                        .billingRate
                                                                                        .overtimeRateDescription
                                                                                        != null
                                                                                        && chargeType[0]
                                                                                            .billingRate
                                                                                            .overtimeRateDescription
                                                                                            .length
                                                                                        > 0
                                                                                        ? chargeType[0]
                                                                                            .billingRate
                                                                                            .overtimeRateDescription
                                                                                        : 'OT';
                                                                                    const regQBItemDesc = chargeType[0]
                                                                                        .billingRate
                                                                                        .regularQuickBooksInventoryItem
                                                                                        != null
                                                                                        && chargeType[0]
                                                                                            .billingRate
                                                                                            .regularQuickBooksInventoryItem
                                                                                            .length
                                                                                        > 0
                                                                                        ? chargeType[0]
                                                                                            .billingRate
                                                                                            .regularQuickBooksInventoryItem
                                                                                        : '';
                                                                                    const otQBItemDesc = chargeType[0]
                                                                                        .billingRate
                                                                                        .overtimeQuickBooksInventoryItem
                                                                                        != null
                                                                                        && chargeType[0]
                                                                                            .billingRate
                                                                                            .overtimeQuickBooksInventoryItem
                                                                                            .length
                                                                                        > 0
                                                                                        ? chargeType[0]
                                                                                            .billingRate
                                                                                            .overtimeQuickBooksInventoryItem
                                                                                        : '';
                                                                                    const regQBSalesDesc = chargeType[0]
                                                                                        .billingRate
                                                                                        .regularQuickBooksSalesAccount
                                                                                        != null
                                                                                        && chargeType[0]
                                                                                            .billingRate
                                                                                            .regularQuickBooksSalesAccount
                                                                                            .length
                                                                                        > 0
                                                                                        ? chargeType[0]
                                                                                            .billingRate
                                                                                            .regularQuickBooksSalesAccount
                                                                                        : '';
                                                                                    const otQBSalesDesc = chargeType[0]
                                                                                        .billingRate
                                                                                        .overtimeQuickBooksSalesAccount
                                                                                        != null
                                                                                        && chargeType[0]
                                                                                            .billingRate
                                                                                            .overtimeQuickBooksSalesAccount
                                                                                            .length
                                                                                        > 0
                                                                                        ? chargeType[0]
                                                                                            .billingRate
                                                                                            .overtimeQuickBooksSalesAccount
                                                                                        : '';

                                                                                    billingRates = [
                                                                                        {
                                                                                            id: 1,
                                                                                            rate: chargeType[0]
                                                                                                .billingRate
                                                                                                .rate,
                                                                                            description:
                                                                                                regDesc,
                                                                                            qbInventoryItem:
                                                                                                regQBItemDesc,
                                                                                            qbSalesAccount:
                                                                                                regQBSalesDesc,
                                                                                        },
                                                                                        {
                                                                                            id: 2,
                                                                                            rate: chargeType[0]
                                                                                                .billingRate
                                                                                                .otRate,
                                                                                            description:
                                                                                                otDesc,
                                                                                            qbInventoryItem:
                                                                                                otQBItemDesc,
                                                                                            qbSalesAccount:
                                                                                                otQBSalesDesc,
                                                                                        },
                                                                                    ];
                                                                                }

                                                                                return (
                                                                                    <tr
                                                                                        key={
                                                                                            adjustment.id
                                                                                        }
                                                                                        className={`timesheet-details-table-row${adjustment.removed
                                                                                            ? ' strikeout'
                                                                                            : ''
                                                                                        }`}
                                                                                    >
                                                                                        <td>
                                                                                            <span className="invoice-lineitem-isadjusted">
                                                                                                <Badge className="p-1">
                                                ADJ
                                                                                                </Badge>
                                                                                            </span>
                                                                                        </td>

                                                                                        {invoice.useCTRReport
                                                                                        == 'True' && (
                                                                                            <td width="350">
                                                                                                <Select
                                                                                                    id={`serviceItemADJ-${adjustment.id}`}
                                                                                                    name={`serviceItemADJ-${adjustment.id}`}
                                                                                                    options={
                                                                                                        serviceItems
                                                                                                    }
                                                                                                    value={
                                                                                                        (
                                                                                                            serviceItems
                                                                                                            ?? []
                                                                                                        ).find(
                                                                                                            (
                                                                                                                s,
                                                                                                            ) => s.value
                                                                                                                == adjustments[
                                                                                                                    i
                                                                                                                ]
                                                                                                                    .serviceItem,
                                                                                                        )
                                                                                                        ?? ''
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        evt,
                                                                                                    ) => {
                                                                                                        const { value } = evt;
                                                                                              
                                                                                                        adjustments[
                                                                                                            i
                                                                                                        ].serviceItem = value;
                                                                                                        this.setState(
                                                                                                            {
                                                                                                                adjustments,
                                                                                                            },
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            </td>
                                                                                        )}

                                                                                        <td className="invoiceChargeQty">
                                                                                            <NumericInput
                                                                                                className="form-control-sm text-right"
                                                                                                name="chargeQty"
                                                                                                required
                                                                                                value={
                                                                                                    adjustment.chargeQty
                                                                                                ?? ''
                                                                                                }
                                                                                                onChange={(
                                                                                                    ev,
                                                                                                ) => this.onAdjustmentChanged(
                                                                                                    i,
                                                                                                    ev,
                                                                                                )}
                                                                                                type="number"
                                                                                                min="0"
                                                                                                step="0.5"
                                                                                                preventNegative
                                                                                            />
                                                                                        </td>
                                                                                        <td className="invoiceChargeDescription">
                                                                                            <div
                                                                                                style={{
                                                                                                    height: '0',
                                                                                                }}
                                                                                            >
                                                                                                <i
                                                                                                    title="Build Description"
                                                                                                    onClick={() => this.toggleAdjustmentsModal()}
                                                                                                    style={{
                                                                                                        position:
                                                                                                        'relative',
                                                                                                        width: '100%',
                                                                                                        textAlign:
                                                                                                        'right',
                                                                                                        top: '3px',
                                                                                                        right: '5px',
                                                                                                        cursor: 'pointer',
                                                                                                    }}
                                                                                                    className="fa fa-plus-circle"
                                                                                                />
                                                                                            </div>
                                                                                            <Modal
                                                                                                centered
                                                                                                isOpen={
                                                                                                    showAdjustmentsModal
                                                                                                }
                                                                                                toggle={
                                                                                                    this
                                                                                                        .toggleAdjustmentsModal
                                                                                                }
                                                                                            >
                                                                                                <ModalHeader
                                                                                                    toggle={
                                                                                                        this
                                                                                                            .toggleAdjustmentsModal
                                                                                                    }
                                                                                                >
                                                Adjustment
                                                Description
                                                                                                </ModalHeader>
                                                                                                <ModalBody>
                                                                                                    <FormGroup>
                                                                                                        <label>
                                                    Date
                                                                                                        </label>
                                                                                                        <input
                                                                                                            id="adjustmentDate"
                                                                                                            name="adjustmentDate"
                                                                                                            className="form-control"
                                                                                                            onChange={(
                                                                                                                ev,
                                                                                                            ) => {
                                                                                                                const val = ev
                                                                                                                    ?.target
                                                                                                                    ?.value;
                                                                                                                adjustmentHelper.date = moment(
                                                                                                                    val,
                                                                                                                ).format(
                                                                                                                    MomentDateFormat.MonthDayYearSlashesNoLeading,
                                                                                                                );
                                                                                                                this.setState(
                                                                                                                    {
                                                                                                                        adjustmentHelper,
                                                                                                                    },
                                                                                                                );
                                                                                                                this.buildAdjustmentDescription(
                                                                                                                    i,
                                                                                                                );
                                                                                                            }}
                                                                                                            type="date"
                                                                                                        />
                                                                                                    </FormGroup>

                                                                                                    <FormGroup>
                                                                                                        <FormCheckbox
                                                                                                            small
                                                                                                            id="useChargeTypeGroups"
                                                                                                            checked={
                                                                                                                adjustmentHelper.useChargeTypeGroups
                                                                                                            }
                                                                                                            onChange={(
                                                                                                                e,
                                                                                                            ) => {
                                                                                                                adjustmentHelper.useChargeTypeGroups = e.target.checked;
                                                                                                                this.setState(
                                                                                                                    {
                                                                                                                        adjustmentHelper,
                                                                                                                    },
                                                                                                                );
                                                                                                                // this.buildAdjustmentDescription(i);
                                                                                                            }}
                                                                                                            labelText="Charge Type Groups"
                                                                                                        />
                                                                                                    </FormGroup>

                                                                                                    {adjustmentHelper.useChargeTypeGroups && (
                                                                                                        <FormGroup>
                                                                                                            <label>
                                                    Charge
                                                    Type
                                                    Group
                                                                                                            </label>
                                                                                                            <ValidatedSelect
                                                                                                                styles={
                                                                                                                    CompactSelectStyles
                                                                                                                }
                                                                                                                minMenuHeight={
                                                                                                                    100
                                                                                                                }
                                                                                                                maxMenuHeight={
                                                                                                                    200
                                                                                                                }
                                                                                                                name="chargeTypeDropdown"
                                                                                                                hideClear
                                                                                                                options={
                                                                                                                    chargeTypeGroups
                                                                                                                }
                                                                                                                getOptionLabel={(
                                                                                                                    option,
                                                                                                                ) => option.invoiceDescription}
                                                                                                                getOptionValue={(
                                                                                                                    option,
                                                                                                                ) => option.id}
                                                                                                                onChange={(
                                                                                                                    selection,
                                                                                                                    event,
                                                                                                                ) => {
                                                                                                                    adjustmentHelper.chargeTypeGroup = selection;
                                                                                                                    adjustmentHelper.resource = '';
                                                                                                                    adjustmentHelper.billingRate = null;
                                                                                                                    this.setState(
                                                                                                                        {
                                                                                                                            adjustmentHelper,
                                                                                                                        },
                                                                                                                    );
                                                                                                                    this.buildAdjustmentDescription(
                                                                                                                        i,
                                                                                                                    );
                                                                                                                }}
                                                                                                            />
                                                                                                        </FormGroup>
                                                                                                    )}

                                                                                                    {!adjustmentHelper.useChargeTypeGroups && (
                                                                                                        <>
                                                                                                            <FormGroup>
                                                                                                                <label>
                                                      Charge
                                                                                                                </label>
                                                                                                                <ValidatedSelect
                                                                                                                    styles={
                                                                                                                        CompactSelectStyles
                                                                                                                    }
                                                                                                                    minMenuHeight={
                                                                                                                        100
                                                                                                                    }
                                                                                                                    maxMenuHeight={
                                                                                                                        200
                                                                                                                    }
                                                                                                                    name="chargeTypeDropdown"
                                                                                                                    hideClear
                                                                                                                    options={
                                                                                                                        chargeTypes
                                                                                                                    }
                                                                                                                    getOptionLabel={(
                                                                                                                        option,
                                                                                                                    ) => option.description}
                                                                                                                    getOptionValue={(
                                                                                                                        option,
                                                                                                                    ) => option.id}
                                                                                                                    onChange={(
                                                                                                                        selection,
                                                                                                                        event,
                                                                                                                    ) => {
                                                                                                                        adjustmentHelper.chargeType = selection;
                                                                                                                        adjustmentHelper.resource = '';
                                                                                                                        adjustmentHelper.billingRate = null;
                                                                                                                        this.setState(
                                                                                                                            {
                                                                                                                                adjustmentHelper,
                                                                                                                            },
                                                                                                                        );
                                                                                                                        this.buildAdjustmentDescription(
                                                                                                                            i,
                                                                                                                        );
                                                                                                                    }}
                                                                                                                />
                                                                                                            </FormGroup>

                                                                                                            {!!adjustmentHelper.chargeType
                                                                                                            && !!filtered_staff.length && (
                                                                                                                <FormGroup>
                                                                                                                    <label>
                                                                                                                Resource
                                                                                                                    </label>
                                                                                                                    <ValidatedSelect
                                                                                                                        styles={
                                                                                                                            CompactSelectStyles
                                                                                                                        }
                                                                                                                        minMenuHeight={
                                                                                                                            100
                                                                                                                        }
                                                                                                                        maxMenuHeight={
                                                                                                                            200
                                                                                                                        }
                                                                                                                        name="staffDropdown"
                                                                                                                        hideClear
                                                                                                                        options={
                                                                                                                            filtered_staff
                                                                                                                        }
                                                                                                                        getOptionLabel={(
                                                                                                                            option,
                                                                                                                        ) => option.name}
                                                                                                                        getOptionValue={(
                                                                                                                            option,
                                                                                                                        ) => option.id}
                                                                                                                        onChange={(
                                                                                                                            selection,
                                                                                                                            event,
                                                                                                                        ) => {
                                                                                                                            adjustmentHelper.resource = selection.name;
                                                                                                                            this.setState(
                                                                                                                                {
                                                                                                                                    adjustmentHelper,
                                                                                                                                },
                                                                                                                            );
                                                                                                                            this.buildAdjustmentDescription(
                                                                                                                                i,
                                                                                                                            );
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            )}

                                                                                                            {!!adjustmentHelper.chargeType
                                                                                                            && !!filtered_equipment.length
                                                                                                            > 0 && (
                                                                                                                <FormGroup>
                                                                                                                    <label>
                                                                                                                Resource
                                                                                                                    </label>
                                                                                                                    <ValidatedSelect
                                                                                                                        styles={
                                                                                                                            CompactSelectStyles
                                                                                                                        }
                                                                                                                        minMenuHeight={
                                                                                                                            100
                                                                                                                        }
                                                                                                                        maxMenuHeight={
                                                                                                                            200
                                                                                                                        }
                                                                                                                        name="equipmentDropdown"
                                                                                                                        hideClear
                                                                                                                        options={
                                                                                                                            filtered_equipment
                                                                                                                        }
                                                                                                                        getOptionLabel={(
                                                                                                                            option,
                                                                                                                        ) => option.description}
                                                                                                                        getOptionValue={(
                                                                                                                            option,
                                                                                                                        ) => option.id}
                                                                                                                        onChange={(
                                                                                                                            selection,
                                                                                                                            event,
                                                                                                                        ) => {
                                                                                                                            adjustmentHelper.resource = selection.description;
                                                                                                                            this.setState(
                                                                                                                                {
                                                                                                                                    adjustmentHelper,
                                                                                                                                },
                                                                                                                            );
                                                                                                                            this.buildAdjustmentDescription(
                                                                                                                                i,
                                                                                                                            );
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            )}

                                                                                                            <FormGroup>
                                                                                                                <label>
                                                      Rate
                                                                                                                </label>
                                                                                                                <ValidatedSelect
                                                                                                                    styles={
                                                                                                                        CompactSelectStyles
                                                                                                                    }
                                                                                                                    minMenuHeight={
                                                                                                                        100
                                                                                                                    }
                                                                                                                    maxMenuHeight={
                                                                                                                        200
                                                                                                                    }
                                                                                                                    name="rateDropdown"
                                                                                                                    hideClear
                                                                                                                    options={
                                                                                                                        billingRates
                                                                                                                    }
                                                                                                                    getOptionLabel={(
                                                                                                                        option,
                                                                                                                    ) => option.description}
                                                                                                                    getOptionValue={(
                                                                                                                        option,
                                                                                                                    ) => option.id}
                                                                                                                    onChange={(
                                                                                                                        selection,
                                                                                                                        event,
                                                                                                                    ) => {
                                                                                                                        adjustmentHelper.billingRate = selection;
                                                                                                                        this.setState(
                                                                                                                            {
                                                                                                                                adjustmentHelper,
                                                                                                                            },
                                                                                                                        );
                                                                                                                        this.buildAdjustmentDescription(
                                                                                                                            i,
                                                                                                                        );
                                                                                                                    }}
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </>
                                                                                                    )}
                                                                                                </ModalBody>
                                                                                                <ModalFooter
                                                                                                    style={{
                                                                                                        display:
                                                                                                        'block',
                                                                                                        textAlign:
                                                                                                        'center',
                                                                                                    }}
                                                                                                >
                                                                                                    <Button
                                                                                                        color="primary"
                                                                                                        onClick={
                                                                                                            this
                                                                                                                .onAddAdjustmentDescription
                                                                                                        }
                                                                                                    >
                                                  Close
                                                                                                    </Button>
                                                                                                </ModalFooter>
                                                                                            </Modal>
                                                                                            <Input
                                                                                                required
                                                                                                type="textarea"
                                                                                                name="description"
                                                                                                value={
                                                                                                    adjustment.description
                                                                                                ?? ''
                                                                                                }
                                                                                                onChange={(
                                                                                                    evt,
                                                                                                ) => {
                                                                                                    const { value } = evt
                                                                                                        .target;
                                                                                                    const {
                                                                                                        adjustments,
                                                                                                    } = {
                                                                                                        ...this
                                                                                                            .state,
                                                                                                    };
                                                                                                    adjustments[
                                                                                                        i
                                                                                                    ].description = value;
                                                                                                    this.setState(
                                                                                                        {
                                                                                                            adjustments,
                                                                                                        },
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                            <small className="invalid-feedback text-danger">
                                              Adjustment
                                              description
                                              is
                                              required.
                                                                                            </small>
                                                                                        </td>
                                                                                        <td className="invoiceChargeRate">
                                                                                            <NumericInput
                                                                                                className="form-control-sm text-right"
                                                                                                name="billingRate"
                                                                                                required
                                                                                                defaultValue={
                                                                                                    adjustment.billingRate
                                                                                                ?? ''
                                                                                                }
                                                                                                value={
                                                                                                    adjustment.billingRate
                                                                                                ?? ''
                                                                                                }
                                                                                                onChange={(
                                                                                                    ev,
                                                                                                ) => this.onAdjustmentChanged(
                                                                                                    i,
                                                                                                    ev,
                                                                                                )}
                                                                                                type="number"
                                                                                                min="0"
                                                                                                step="0.5"
                                                                                                preventNegative
                                                                                            />
                                                                                        </td>
                                                                                        <td className="invoiceChargeAmount">
                                                                                            {`$${parseFloat(
                                                                                                adjustment.chargeAmount,
                                                                                            ).toFixed(
                                                                                                2,
                                                                                            )}`}
                                                                                        </td>
                                                                                        {associateInventoryItem && (
                                                                                            <td
                                                                                                className="invoiceUserQuickBooksInventoryItem"
                                                                                                width="150"
                                                                                            >
                                                                                                <Select
                                                                                                    id={`userQuickBooksInventoryItemADJ-${adjustment.id}`}
                                                                                                    name={`userQuickBooksInventoryItemADJ-${adjustment.id}`}
                                                                                                    options={
                                                                                                        inventoryItems
                                                                                                    }
                                                                                                    value={
                                                                                                        (
                                                                                                            inventoryItems
                                                                                                        ?? []
                                                                                                        ).find(
                                                                                                            (
                                                                                                                s,
                                                                                                            ) => s.value
                                                                                                            == adjustments[
                                                                                                                i
                                                                                                            ]
                                                                                                                .userQuickBooksInventoryItem,
                                                                                                        )
                                                                                                    ?? ''
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        evt,
                                                                                                    ) => {
                                                                                                        const { value } = evt;
                                                                                                        const {
                                                                                                            adjustments,
                                                                                                        } = {
                                                                                                            ...this
                                                                                                                .state,
                                                                                                        };
                                                                                                        adjustments[
                                                                                                            i
                                                                                                        ].userQuickBooksInventoryItem = value;
                                                                                                        this.setState(
                                                                                                            {
                                                                                                                adjustments,
                                                                                                            },
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            </td>
                                                                                        )}
                                                                                        {associateInventoryItem && (
                                                                                            <td
                                                                                                className="invoiceUserQuickBooksSalesAccount"
                                                                                                width="150"
                                                                                            >
                                                                                                <Select
                                                                                                    id={`userQuickBooksSalesAccountADJ-${adjustment.id}`}
                                                                                                    name={`userQuickBooksSalesAccountADJ-${adjustment.id}`}
                                                                                                    options={
                                                                                                        salesAccounts
                                                                                                    }
                                                                                                    value={
                                                                                                        (
                                                                                                            salesAccounts
                                                                                                        ?? []
                                                                                                        ).find(
                                                                                                            (
                                                                                                                s,
                                                                                                            ) => s.value
                                                                                                            == adjustments[
                                                                                                                i
                                                                                                            ]
                                                                                                                .userQuickBooksSalesAccount,
                                                                                                        )
                                                                                                    ?? ''
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        evt,
                                                                                                    ) => {
                                                                                                        const { value } = evt;
                                                                                                        const {
                                                                                                            adjustments,
                                                                                                        } = {
                                                                                                            ...this
                                                                                                                .state,
                                                                                                        };
                                                                                                        adjustments[
                                                                                                            i
                                                                                                        ].userQuickBooksSalesAccount = value;
                                                                                                        this.setState(
                                                                                                            {
                                                                                                                adjustments,
                                                                                                            },
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            </td>
                                                                                        )}
                                                                                        <td className="invoiceChargeDelete text-center">
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn shadow-none"
                                                                                                title="Remove this Charge Detail"
                                                                                                onClick={() => {
                                                                                                    const {
                                                                                                        adjustments,
                                                                                                    } = {
                                                                                                        ...this
                                                                                                            .state,
                                                                                                    };
                                                                                                    adjustments.splice(
                                                                                                        i,
                                                                                                        1,
                                                                                                    );
                                                                                                    this.setState(
                                                                                                        {
                                                                                                            adjustments,
                                                                                                        },
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <span className="text-danger mt-1">
                                                                                                    <i className="fa fa-trash" />
                                                                                                </span>
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }

                                                                            return null;
                                                                        },
                                                                    )
                                                                )}
                                                        </tbody>
                                                    </table>
                                                </>
                                            )}
                                        </div>
                                    )}
                                    <FlexCenterRow>
                                        {invoice?.invoiceStatusId
                                            != InvoiceStatus.Approved
                                            && invoice?.invoiceStatusId
                                            != InvoiceStatus.Exported && (
                                            <>
                                                <Button
                                                    className="mb-2"
                                                    size="sm"
                                                    type="button"
                                                    color="secondary"
                                                    onClick={() => {
                                                        const {
                                                            adjustments,
                                                        } = {
                                                            ...this.state,
                                                        };
                                                        const newRecord = new InvoiceChargeDetailAdjustment();
                                                        newRecord.invoiceChargeDetailTypeId = InvoiceChargeDetailType.LineItem;
                                                        adjustments.push(
                                                            newRecord,
                                                        );
                                                        this.setState({
                                                            adjustments,
                                                        });
                                                    }}
                                                >
                                                    <i className="fa fa-pen-square fa-lg mr-2" />
                                                Add Adjustment
                                                </Button>
                                                    &nbsp;&nbsp;
                                                <Button
                                                    className="mb-2"
                                                    size="sm"
                                                    type="button"
                                                    color="secondary"
                                                    onClick={() => {
                                                        const {
                                                            adjustments,
                                                        } = {
                                                            ...this.state,
                                                        };
                                                        const newRecord = new InvoiceChargeDetailAdjustment();
                                                        newRecord.invoiceChargeDetailTypeId = InvoiceChargeDetailType.Header;

                                                        adjustments.push(
                                                            newRecord,
                                                        );
                                                        this.setState({
                                                            adjustments,
                                                        });
                                                    }}
                                                >
                                                    <i className="fa fa-heading fa-lg mr-2" />
                                                Add Header
                                                </Button>
                                                    &nbsp;&nbsp;
                                                <Button
                                                    className="mb-2"
                                                    size="sm"
                                                    type="button"
                                                    color="secondary"
                                                    onClick={() => {
                                                        const {
                                                            adjustments,
                                                        } = {
                                                            ...this.state,
                                                        };
                                                        const newRecord = new InvoiceChargeDetailAdjustment();
                                                        newRecord.invoiceChargeDetailTypeId = InvoiceChargeDetailType.Annotation;

                                                        adjustments.push(
                                                            newRecord,
                                                        );
                                                        this.setState({
                                                            adjustments,
                                                        });
                                                    }}
                                                >
                                                    <i className="fa fa-comment-dots fa-lg mr-2" />
                                                Add Text
                                                </Button>
                                            </>
                                        )}
                                    </FlexCenterRow>
                                </CollapseUnderlineHeader>

                                <CollapseUnderlineHeader
                                    headerText="Invoice Summary"
                                    isOpen={invoiceSummaryOpen}
                                    toggleCollapse={() => this.setState({
                                        invoiceSummaryOpen:
                                                !invoiceSummaryOpen,
                                    })}
                                >
                                    <div className="invoice">
                                        <table className="table table-sm table-bordered table-striped payroll-table">
                                            <thead className="text-muted">
                                                <tr>
                                                    <th className="invoiceRateType">
                              Rate Type Description
                                                    </th>
                                                    <th className="invoiceCalBillQty">
                              Calc Bill Qty
                                                    </th>
                                                    <th className="invoiceBillQty">
                              Bill Qty
                                                    </th>
                                                    <th className="invoiceVarianceQty">
                              Variance
                                                    </th>
                                                    <th className="invoiceCalcBillAmt">
                              Calc Bill Amt
                                                    </th>
                                                    <th className="invoiceBillAmt">
                              Bill Amt
                                                    </th>
                                                    <th className="invoiceVarianceAmt">
                              Variance
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!(
                                                    invoice.invoiceSummaries
                                                    ?? []
                                                ).length ? (
                                                        <tr>
                                                            <td
                                                                colSpan="7"
                                                                className="text-center"
                                                            >
                                No Invoice Summaries
                                found.
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        invoice.invoiceSummaries.map(
                                                            (
                                                                summary,
                                                                charge_index,
                                                            ) => (
                                                                <tr
                                                                    key={
                                                                        charge_index
                                                                    }
                                                                >
                                                                    <td className="invoiceRateType">
                                                                        {
                                                                            summary.rateTypeDescription
                                                                        }
                                                                    </td>
                                                                    <td className="invoiceCalBillQty">
                                                                        {parseFloat(
                                                                            summary.calcBillQty,
                                                                        ).toFixed(
                                                                            2,
                                                                        )}
                                                                    </td>
                                                                    <td className="invoiceBillQty">
                                                                        {parseFloat(
                                                                            summary.billQty,
                                                                        ).toFixed(
                                                                            2,
                                                                        )}
                                                                    </td>
                                                                    <td className="invoiceVarianceQty">
                                                                        {summary.varianceQty
                                                                            ? parseFloat(
                                                                                summary.varianceQty,
                                                                            ).toFixed(
                                                                                2,
                                                                            )
                                                                            : '---'}
                                                                    </td>
                                                                    <td className="invoiceCalcBillAmt">
                                                                        {`$${parseFloat(
                                                                            summary.calcBillAmount,
                                                                        ).toFixed(
                                                                            2,
                                                                        )}`}
                                                                    </td>
                                                                    <td className="invoiceBillAmt">
                                                                        {`$${parseFloat(
                                                                            summary.billAmount,
                                                                        ).toFixed(
                                                                            2,
                                                                        )}`}
                                                                    </td>
                                                                    <td className="invoiceVarianceAmt">
                                                                        {`${summary.varianceAmount
                                                                            ? `$${parseFloat(
                                                                                summary.varianceAmount,
                                                                            ).toFixed(
                                                                                2,
                                                                            )}`
                                                                            : '---'
                                                                        }`}
                                                                    </td>
                                                                </tr>
                                                            ),
                                                        )
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                </CollapseUnderlineHeader>
                            </>
                        )}
                        <FlexCenterRow className="pb-3">
                            {invoice?.invoiceStatusId
                                == InvoiceStatus.Approved && (
                                <Button
                                    type="button"
                                    className="mr-3"
                                    color="secondary"
                                    disabled={!!printing}
                                    onClick={this.onPrint}
                                >
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={
                                            printing ? faCircleNotch : faPrint
                                        }
                                        className="mr-2"
                                    />
                                  Print
                                </Button>
                            )}
                            {!isNew
                                && invoice?.invoiceStatusId
                                <= InvoiceStatus.New && (
                                <Button
                                    type="button"
                                    className="mr-3"
                                    color="success"
                                    disabled={!!showConfirmDraftModal}
                                    onClick={this.toggleConfirmDraftModal}
                                >
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={
                                            showConfirmDraftModal
                                                ? faCircleNotch
                                                : faSave
                                        }
                                        className="mr-2"
                                    />
                                    {showConfirmDraftModal
                                        ? 'Saving, Please Wait...'
                                        : 'Create Draft'}
                                </Button>
                            )}
                            {!isNew
                                && invoice?.invoiceStatusId
                                == InvoiceStatus.Draft && (
                                <Button
                                    type="button"
                                    className="mr-3"
                                    color="success"
                                    disabled={!!approving}
                                    onClick={this.onApprove}
                                >
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={
                                            approving
                                                ? faCircleNotch
                                                : faSave
                                        }
                                        className="mr-2"
                                    />
                                    {approving
                                        ? 'Approving, Please Wait...'
                                        : 'Approve Invoice'}
                                </Button>
                            )}

                            <Button
                                type="submit"
                                className="mr-3"
                                color="primary"
                                disabled={!!saving}
                            >
                                <FontAwesomeIcon
                                    size="lg"
                                    icon={saving ? faCircleNotch : faSave}
                                    className="mr-2"
                                />
                                {saving
                                    ? 'Saving, Please Wait...'
                                    : 'Save Updates'}
                            </Button>

                            {isNew && (
                                <Button
                                    type="button"
                                    className="mr-3"
                                    color="danger"
                                    disabled={!!cancelling}
                                    onClick={this.onClose}
                                >
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={
                                            cancelling ? faCircleNotch : faSave
                                        }
                                        className="mr-2"
                                    />
                                    {cancelling
                                        ? 'Cancelling, Please Wait...'
                                        : 'Cancel Updates'}
                                </Button>
                            )}

                            {!isNew
                                && invoice?.invoiceStatusId
                                == InvoiceStatus.Approved && (
                                <Button
                                    type="button"
                                    className="mr-3"
                                    color="success"
                                    disabled={!!reverting}
                                    onClick={this.onRevert}
                                >
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={faArrowAltCircleLeft}
                                        className="mr-2"
                                    />
                                    {showDeleteInvoiceModal
                                        ? 'Deleting, Please Wait...'
                                        : 'Return to Draft'}
                                </Button>
                            )}

                            {!isNew
                                && invoice?.invoiceStatusId
                                !== InvoiceStatus.New
                                && invoice?.invoiceStatusId
                                !== InvoiceStatus.Exported && (
                                <Button
                                    type="button"
                                    className="mr-3"
                                    color="warning"
                                    disabled={!!showRestartInvoiceModal}
                                    onClick={
                                        this.toggleRestartInvoiceModal
                                    }
                                >
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={faRecycle}
                                        className="mr-2"
                                    />
                                    {showRestartInvoiceModal
                                        ? 'Restarting, Please Wait...'
                                        : 'Restart Invoice'}
                                </Button>
                            )}

                            {!isNew
                                && invoice?.invoiceStatusId
                                !== InvoiceStatus.Exported && (
                                <Button
                                    type="button"
                                    className="mr-3"
                                    color="danger"
                                    disabled={!!showDeleteInvoiceModal}
                                    onClick={
                                        this.toggleDeleteInvoiceModal
                                    }
                                >
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={
                                            showDeleteInvoiceModal
                                                ? faCircleNotch
                                                : faSave
                                        }
                                        className="mr-2"
                                    />
                                    {showDeleteInvoiceModal
                                        ? 'Deleting, Please Wait...'
                                        : 'Delete Invoice'}
                                </Button>
                            )}
                        </FlexCenterRow>
                    </SlideForm>
                    <SlideForm
                        loading={false}
                        show={this.state.showTimesheetsReorderWidget}
                        id="reorderTimesheetsWidget"
                        formIcon={faFileContract}
                        formTitle="Reorder Timesheets"
                        ref={this.timesheetsWidgetRef}
                        onClose={() => {
                            this.setState(
                                (state) => (
                                    (state.showTimesheetsReorderWidget = false),
                                    state
                                ),
                            );
                        }}
                    >
                        <ReorderListWidget
                            entities={this.state.selectedSortableTimesheets}
                            entityName="sortableText"
                            customColors={false}
                            setUpdatedEntities={(items) => {
                                this.setState({
                                    selectedSortableTimesheets: items,
                                });
                            }}
                            ref={this.timesheetsWidgetRef}
                            onSaveCallback={() => {
                                const { invoice } = { ...this.state };
                                let updated = [...selectedSortableTimesheets];
                                updated = updated.map((x, index) => ({ ...x, ...{ order: (index += 1) } }));
                                invoice.invoiceTimesheets = updated;
                                this.setState({
                                    invoice,
                                    showTimesheetsReorderWidget: false,
                                });

                                this.sortChargeDetailsByTimesheetOrder();

                                this.save();
                            }}
                        />
                    </SlideForm>
                    <SlideForm
                        loading={false}
                        show={this.state.showAddChargeTypeGroupsForm}
                        id="addChargeTypeGroupForm"
                        formIcon={faFileContract}
                        formTitle="Add Charge Type Group"
                        ref={this.addChargeTypeGroupRef}
                        onClose={() => {
                            this.setState(
                                (state) => (
                                    (state.showAddChargeTypeGroupsForm = false),
                                    state
                                ),
                            );
                        }}
                    >

                        <FormGroup>
                            <label>
                    Job Date:
                            </label>

                            <FormGroup>
                                {editedGroupDetails?.length == 0
                                    && (
                                        <input
                                            className="form-control w-auto"
                                            id="chargeGroupDate"
                                            name="chargeGroupDate"
                                            value={moment(this.state.addCTGroupDate).format('YYYY-MM-DD')}
                                            onChange={(ev) => {
                                                const val = ev?.target?.value;
                                                this.setState((state) => ((state.addCTGroupDate = moment(val).format('M/D/YYYY')), state));
                                            }}
                                            required
                                            type="date"
                                        />
                                    )}

                                {editedGroupDetails?.length > 0
                                    && (
                                        <span
                                            id="invoiceDate"
                                            name="invoiceDate"
                                            className="form-control-sm form-control-plaintext"
                                            style={{ marginLeft: '2px' }}
                                        >
                                            {this.state.addCTGroupDate}
                                        </span>
                                    )}
                            </FormGroup>

                            <label>
                    Charge Group:
                            </label>

                            {editedGroupDetails?.length == 0
                                && (
                                    <ValidatedSelect
                                        minMenuHeight={100}
                                        maxMenuHeight={200}
                                        name="chargeTypeDropdown"
                                        hideClear
                                        options={chargeTypeGroups}
                                        getOptionLabel={(option) => option.invoiceDescription}
                                        getOptionValue={(option) => option.id}
                                        value={this.state.selectedChargeTypeGroup}
                                        onChange={(selection, event) => {
                                            this.setState(
                                                {
                                                    selectedChargeTypeGroup: selection,
                                                },
                                            );
                                        }}
                                    />
                                )}

                            {editedGroupDetails?.length > 0
                                && <span className="text-muted">{this.state.selectedChargeTypeGroup?.invoiceDescription}</span>}

                            {this.state.selectedChargeTypeGroup != null
                                && (
                                    <div className="invoice-details" style={{ marginTop: '7px', paddingTop: '4px', paddingBottom: '0px' }}>

                                        <FlexStartRow>
                                            <FormLabel
                                                text="Requirements"
                                            />
                                            {this.state.selectedChargeTypeGroup?.chargeTypeGroupChargeTypes?.map((ctGroup, index) => (
                                                <>
                                                    {index > 0
                                                        && <span style={{ marginTop: '5px' }}>,&nbsp;</span>}

                                                    <span
                                                        className="text-muted"
                                                        style={{ marginTop: '5px' }}
                                                    >
                                                        {ctGroup.chargeType.description}
                                          (
                                                        {ctGroup.quantity}
                                          )
                                                    </span>
                                                </>
                                            ))}

                                        </FlexStartRow>
                                    </div>
                                )}

                        </FormGroup>

                        {this.state.addCTGroupDate != null && this.state.selectedChargeTypeGroup != null
                            && (
                                <>
                                    <div>
                                        <button
                                            type="button"
                                            className="btn shadow-none"
                                            title="Add Charges"
                                            style={{ paddingLeft: '2px' }}
                                            onClick={() => {
                                                this.onAddChargeTypeGroupCharge_Add();
                                            }}
                                        >
                                            <span>
                                                <i className="fa fa-plus-circle" style={{ fontSize: '12px' }} />
                                            &nbsp;Add Charges
                                            </span>
                                        </button>
                                    </div>

                                    <table className="table table-sm table-bordered table-striped payroll-table">
                                        <thead className="text-muted">
                                            <tr>
                                                <th className="invoiceHourlyCharge">
                                      Charge
                                                </th>
                                                <th className="invoiceHourlyResource">
                                      Resource
                                                </th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!editedGroupDetails?.length ? (
                                                <tr>
                                                    <td
                                                        colSpan="11"
                                                        className="text-center"
                                                    >
                                        No Charges Assigned

                                                    </td>
                                                </tr>
                                            ) : (

                                                editedGroupDetails?.map(
                                                    (
                                                        ctDetail,
                                                        charge_index,
                                                    ) => (
                                                        <tr
                                                            key={`${ctDetail.billingDetailId
                                                            }|${uuid()}`}
                                                        >
                                                            <td className="invoiceHourlyCharge">
                                                                {ctDetail.chargeTypeName}
                                                                {' '}
                                            -
                                                                {ctDetail.calculatedBillingRateTypeName}
                                                            </td>
                                                            <td className="invoiceHourlyResource">
                                                                {
                                                                    ctDetail.resourceName
                                                                }
                                                            </td>
                                                            <td style={{ padding: 0 }}>
                                                                <button
                                                                    type="button"
                                                                    className="btn shadow-none"
                                                                    title="Remove this charge"
                                                                    onClick={() => {
                                                                        ctDetail.invoiceChargeTypeGroupUID = null;

                                                                        this.setState({
                                                                            ctDetail,
                                                                        });
                                                                    }}
                                                                >
                                                                    <span className="text-danger mt-1">
                                                                        <i className="fa fa-trash" style={{ fontSize: '12px' }} />
                                                                    </span>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ),
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </>
                            )}

                        <div />
                        <FormGroup>
                            <FormCheckbox
                                id="overrideChargeTypeRules"
                                checked={overrideChargeTypeRules}
                                onChange={(e) => {
                                    overrideChargeTypeRules = e.target.checked;
                                    this.setState(
                                        {
                                            overrideChargeTypeRules,
                                        },
                                    );
                                }}
                                labelText="Override charge type rules"
                            />
                        </FormGroup>

                        <FlexCenterRow className="pb-3">
                            <Button
                                disabled={!!saving}
                                color="success"
                                onClick={() => {
                                    this.onSaveChargeTypeGroup();
                                }}
                            >
                    Add
                            </Button>
                        </FlexCenterRow>

                    </SlideForm>

                    <SlideForm
                        loading={false}
                        show={this.state.showEditChargeTypeGroupsForm}
                        id="editChargeTypeGroupForm"
                        formIcon={faFileContract}
                        formTitle="Edit Charge Type Group"
                        ref={this.editChargeTypeGroupRef}
                        onClose={() => {
                            this.setState(
                                (state) => (
                                    (state.showEditChargeTypeGroupsForm = false),
                                    state
                                ),
                            );
                        }}
                    >

                        <FormGroup>
                            <div className="invoice-details" style={{ paddingTop: '4px', paddingBottom: '0px' }}>
                                <FlexStartRow>
                                    <FormLabel
                                        text="Charge Type Group"
                                    />
                                    <span
                                        className="text-muted"
                                        style={{ marginLeft: '16px', marginTop: '5px' }}
                                    >
                                        {this.state.selectedCTGroup?.chargeTypeName}
                                    </span>
                                </FlexStartRow>
                                <FlexStartRow>
                                    <FormLabel
                                        text="Job Date"
                                    />
                                    <span
                                        className="text-muted"
                                        style={{ marginLeft: '16px', marginTop: '5px' }}
                                    >
                                        {this.state.selectedCTGroup?.dateDisplay}
                                    </span>
                                </FlexStartRow>
                            </div>
                        </FormGroup>

                        <div>
                            <button
                                type="button"
                                className="btn shadow-none"
                                title="Add Charges"
                                style={{ paddingLeft: '2px' }}
                                onClick={() => {
                                    this.onAddChargeTypeGroupCharge_Edit();
                                }}
                            >
                                <span>
                                    <i className="fa fa-plus-circle" style={{ fontSize: '12px' }} />
                                    &nbsp;Add Charges
                                </span>
                            </button>
                        </div>

                        <table className="table table-sm table-bordered table-striped payroll-table">
                            <thead className="text-muted">
                                <tr>
                                    <th className="invoiceHourlyCharge">
                        Charge
                                    </th>
                                    <th className="invoiceHourlyResource">
                        Resource
                                    </th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {!editedGroupDetails?.length ? (
                                    <tr>
                                        <td
                                            colSpan="11"
                                            className="text-center"
                                        >
                          No Charges Assigned

                                        </td>
                                    </tr>
                                ) : (

                                    editedGroupDetails?.map(
                                        (
                                            ctDetail,
                                            charge_index,
                                        ) => (
                                            <tr
                                                key={`${ctDetail.billingDetailId
                                                }|${uuid()}`}
                                            >
                                                <td className="invoiceHourlyCharge">
                                                    {ctDetail.chargeTypeName}
                                                    {' '}
                              -
                                                    {ctDetail.calculatedBillingRateTypeName}
                                                </td>
                                                <td className="invoiceHourlyResource">
                                                    {
                                                        ctDetail.resourceName
                                                    }
                                                </td>
                                                <td style={{ padding: 0 }}>
                                                    <button
                                                        type="button"
                                                        className="btn shadow-none"
                                                        title="Remove this charge"
                                                        onClick={() => {
                                                            ctDetail.invoiceChargeTypeGroupUID = null;

                                                            this.setState({
                                                                ctDetail,
                                                            });
                                                        }}
                                                    >
                                                        <span className="text-danger mt-1">
                                                            <i className="fa fa-trash" style={{ fontSize: '12px' }} />
                                                        </span>
                                                    </button>
                                                </td>
                                            </tr>
                                        ),
                                    )
                                )}
                            </tbody>
                        </table>

                        <FormGroup>
                            <FormCheckbox
                                id="overrideChargeTypeRules"
                                checked={overrideChargeTypeRules}
                                onChange={(e) => {
                                    overrideChargeTypeRules = e.target.checked;
                                    this.setState(
                                        {
                                            overrideChargeTypeRules,
                                        },
                                    );
                                }}
                                labelText="Override charge type rules"
                            />
                        </FormGroup>

                        <FlexCenterRow className="pb-3">
                            <Button
                                disabled={!!saving}
                                color="success"
                                onClick={() => {
                                    this.onSaveChargeTypeGroup();
                                }}
                            >
                    Update
                            </Button>
                        </FlexCenterRow>

                    </SlideForm>
                    <SlideForm
                        loading={false}
                        show={this.state.showReorderWidget}
                        id="reorderInvoiceDetailsWidget"
                        formIcon={faFileContract}
                        formTitle="Reorder Invoice Details"
                        ref={this.invoiceDetailsWidgetRef}
                        onClose={() => {
                            this.setState(
                                (state) => (
                                    (state.showReorderWidget = false), state
                                ),
                            );
                        }}
                    >
                        <ReorderListWidget
                            entities={this.state.selectedSortableInvoiceDetails}
                            customColors
                            entityName={[
                                'id',
                                'workOrderNumber',
                                'userDescription',
                            ]}
                            entityColorIndex="invoiceChargeDetailTypeId"
                            entityColorMap={['success', 'danger', 'primary']}
                            setUpdatedEntities={(items) => {
                                this.setState({
                                    selectedSortableInvoiceDetails: items,
                                });
                            }}
                            ref={this.invoiceDetailsWidgetRef}
                            onSaveCallback={() => {
                                const { invoice } = { ...this.state };
                                let updated = [
                                    ...selectedSortableInvoiceDetails,
                                ];
                                updated = updated.map((x, index) => ({ ...x, ...{ order: (index += 1) } }));
                                invoice.invoiceChargeDetails = updated;
                                this.setState({
                                    invoice,
                                    showReorderWidget: false,
                                });

                                this.save();
                            }}
                        />
                    </SlideForm>
                    <Modal
                        backdrop="static"
                        keyboard={false}
                        isOpen={showAddTimesheetModal}
                        toggle={this.toggleAddTimesheetModal}
                    >
                        <ModalHeader>Add Timesheet</ModalHeader>
                        <ModalBody>
                            {!isNew
                                && invoice?.invoiceStatusId
                                == InvoiceStatus.Draft && (
                                <FormGroup>
                                    <FormText
                                        style={{ marginBottom: '20px' }}
                                    >
                                    Adding or removing any timesheets
                                    when in the draft stage will remove
                                    and recalculate all Invoice Charge
                                    Details and the Invoice Summary. The
                                    Invoice status will reset to New.
                                    </FormText>
                                </FormGroup>
                            )}
                            <FormGroup>
                                <label>
                      Select a timesheet (filtered by the parent
                      contract and work order number)
                                </label>
                            </FormGroup>
                            <FormGroup>
                                <label>Timesheet #</label>
                                <Select
                                    required
                                    placeholder="Select a Timesheet"
                                    id="selectedTimesheet"
                                    name="selectedTimesheet"
                                    styles={CompactSelectStyles}
                                    isClearable
                                    className="react-select"
                                    options={contractTimesheets}
                                    value={
                                        (contractTimesheets ?? []).find(
                                            (x) => selectedContractTimesheet.timesheetId
                                                === x.timesheetId,
                                        ) ?? ''
                                    }
                                    getOptionLabel={(option) => option.jobDateTimesheetNumberDisplay}
                                    getOptionValue={(option) => option.timesheetId}
                                    onChange={
                                        this.onSelectedContractTimesheetChanged
                                    }
                                />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            {isAddingContractTimesheet && (
                                <FontAwesomeIcon
                                    icon={faCircleNotch}
                                    className="fa-spin mr-2"
                                    size="sm"
                                />
                            )}
                            <Button
                                className="d-flex flex-row flex-nowrap align-items-center"
                                color={saving ? 'secondary' : 'primary'}
                                disabled={
                                    !!saving
                                    || !!loading
                                    || !!isAddingContractTimesheet
                                }
                                onClick={this.addContractTimesheet}
                            >
                                {!!this.state.saving && (
                                    <>
                                        <CircularProgress
                                            size={24}
                                            className="saving-button-progress text-success mr-2"
                                        />
                                        <span>Adding, please wait...</span>
                                    </>
                                )}
                                {!this.state.saving && (
                                    <span>Add Timesheet</span>
                                )}
                            </Button>
                            <Button
                                disabled={!!saving}
                                color="secondary"
                                onClick={() => {
                                    if (!saving) {
                                        this.setState({
                                            selectedContractTimesheet: {},
                                            showAddTimesheetModal: false,
                                        });
                                    }
                                }}
                            >
                    Close
                            </Button>
                        </ModalFooter>
                    </Modal>

                    <Modal
                        isOpen={showRemoveTimesheetModal}
                        toggle={this.toggleRemoveTimesheetModal}
                    >
                        <ModalHeader toggle={this.toggleRemoveTimesheetModal}>
                  Remove Timesheet
                        </ModalHeader>
                        <ModalBody>
                            <FormText style={{ marginBottom: '20px' }}>
                    Adding or removing any timesheets when in the
                    draft stage will remove and recalculate all
                    Invoice Charge Details and the Invoice Summary.
                    The Invoice status will reset to New.
                            </FormText>
                        </ModalBody>
                        <ModalFooter>
                            {deleting && (
                                <FontAwesomeIcon
                                    icon={faCircleNotch}
                                    className="fa-spin mr-2"
                                    size="sm"
                                />
                            )}
                            <Button
                                color="primary"
                                disabled={deleting}
                                onClick={this.onRemoveDraftTimesheet}
                            >
                    Ok
                            </Button>
                            {' '}
                        </ModalFooter>
                    </Modal>

                    <Modal
                        size="lg"
                        isOpen={showAddChargeTypeGroupModal}
                        toggle={this.toggleAddChargeTypeGroupModal}
                    >
                        <ModalHeader toggle={this.toggleAddChargeTypeGroupModal}>
                  Add Charges To Group
                        </ModalHeader>
                        <ModalBody>
                            <table className="table table-sm table-bordered table-striped payroll-table">
                                <thead className="text-muted">
                                    <tr>
                                        <th />
                                        <th className="invoiceHourlyCharge">
                          Job Date
                                        </th>
                                        <th className="invoiceHourlyCharge">
                          Charge
                                        </th>
                                        <th className="invoiceHourlyResource">
                          Resource
                                        </th>
                                        <th className="invoiceHourlyResource">
                          Charge Quantity
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {!this.state.editedUnusedCharges?.length ? (
                                        <tr>
                                            <td
                                                colSpan="11"
                                                className="text-center"
                                            >
                            No Charges Available
                                            </td>
                                        </tr>
                                    ) : (

                                        this.state.editedUnusedCharges?.map(
                                            (
                                                ctDetail,
                                                charge_index,
                                            ) => (
                                                <tr
                                                    key={`${ctDetail.billingDetailId
                                                    }|${uuid()}`}
                                                >
                                                    <td style={{ textAlign: 'center' }}>

                                                        <input
                                                            type="checkbox"
                                                            checked={ctDetail.isSelected == true}
                                                            onChange={(x) => {
                                                                ctDetail.isSelected = x.currentTarget.checked;

                                                                this.setState({
                                                                    ctDetail,
                                                                });
                                                            }}
                                                        />

                                                    </td>
                                                    <td className="invoiceHourlyResource">
                                                        {
                                                            ctDetail.dateDisplay
                                                        }
                                                    </td>
                                                    <td className="invoiceHourlyCharge">
                                                        {ctDetail.chargeTypeName}
                                                        {' '}
                                -
                                                        {ctDetail.calculatedBillingRateTypeName}
                                                    </td>
                                                    <td className="invoiceHourlyResource">
                                                        {
                                                            ctDetail.resourceName
                                                        }
                                                    </td>
                                                    <td className="invoiceHourlyResource">
                                                        {
                                                            ctDetail.chargeQty
                                                        }
                                                    </td>

                                                </tr>
                                            ),
                                        )
                                    )}
                                </tbody>
                            </table>

                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                disabled={deleting}
                                onClick={this.onAddChargesToGroup}
                            >
                    Add
                            </Button>
                            {' '}
                        </ModalFooter>
                    </Modal>

                    <Modal
                        isOpen={showDeleteInvoiceModal}
                        toggle={this.toggleDeleteInvoiceModal}
                    >
                        <ModalHeader toggle={this.toggleDeleteInvoiceModal}>
                  Invoice: Delete Invoice
                        </ModalHeader>
                        <ModalBody>
                            <FormText style={{ marginBottom: '20px' }}>
                    This action will delete this invoice, and
                    release timesheets back to a non invoice status.
                            </FormText>
                        </ModalBody>
                        <ModalFooter>
                            {deleting && (
                                <FontAwesomeIcon
                                    icon={faCircleNotch}
                                    className="fa-spin mr-2"
                                    size="sm"
                                />
                            )}
                            <Button
                                color="primary"
                                disabled={deleting}
                                onClick={this.onDelete}
                            >
                    Ok
                            </Button>
                            {' '}
                        </ModalFooter>
                    </Modal>

                    <Modal
                        backdrop="static"
                        isOpen={showRestartInvoiceModal}
                        toggle={this.toggleRestartInvoiceModal}
                    >
                        <ModalHeader toggle={this.toggleRestartInvoiceModal}>
                  Restart Invoice
                        </ModalHeader>
                        <ModalBody>
                            <p>
                    This action will "regenerate" the invoice and
                    charges back to a NEW state. The invoice number
                    assignment and timesheets will be retained.
                            </p>

                            <p style={{ color: 'red' }}>
                                <b>
                      WARNING: All manual invoice and charge edits
                      will be lost.
                                </b>
                            </p>
                        </ModalBody>
                        <ModalFooter>
                            {restarting && (
                                <FontAwesomeIcon
                                    icon={faRecycle}
                                    className="fa-spin mr-2"
                                    size="md"
                                />
                            )}
                            <Button
                                color="primary"
                                disabled={restarting}
                                onClick={this.onRestart}
                            >
                    Continue Restart
                            </Button>
                            {' '}
                        </ModalFooter>
                    </Modal>

                    <Modal
                        isOpen={showConfirmDraftModal}
                        toggle={this.toggleConfirmDraftModal}
                    >
                        <ModalHeader toggle={this.toggleConfirmDraftModal}>
                  Create Draft
                        </ModalHeader>
                        <ModalBody>
                            <FormText style={{ marginBottom: '20px' }}>
                    This action will generate Charge Details and the
                    Invoice Summary, please make sure all timesheets
                    are included.
                            </FormText>
                        </ModalBody>
                        <ModalFooter>
                            {drafting && (
                                <FontAwesomeIcon
                                    icon={faCircleNotch}
                                    className="fa-spin mr-2"
                                    size="sm"
                                />
                            )}
                            <Button
                                color="primary"
                                disabled={drafting}
                                onClick={this.onDraft}
                            >
                    Ok
                            </Button>
                            {' '}
                        </ModalFooter>
                    </Modal>

                    <TimesheetDetails
                        ref={this.timesheetDetailsRef}
                        show={this.state.timesheetDetailsOpen}
                        toggleShow={(open) => this.setState({ timesheetDetailsOpen: open })}
                        onClose={this.onTimesheetDetailsClosed}
                        onlyBillable
                        isBilling
                    />
                </>
            )
        );
    }
}
