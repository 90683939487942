import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { LinearProgress } from '@mui/material';
import { withRouter } from 'react-router-dom';
import {
    createDataSource, createGridOptions, DataGrid, indexCellRenderer, LinkCellRenderer, TextFilterDefaults,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, { agGridConstants, ApiRoutes, AppNavPaths } from '../Common';
import { PageHeading, PageWrap } from '../common/forms/FormElements';
import { util } from '../Util';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';

class RoleIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() { return this.populateState(); }

    componentWillUnmount() { return this.setState = (state, callback) => { }; }

    onViewRole = (id) => this.props.history.push(`${AppNavPaths.Role}/${id}`);

    populateState = async () => {
        const [permissions] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.permissions()),
        ]);

        const gridOptions = createGridOptions(this);

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
        };

        gridOptions.columnDefs = [
            {
                flex: 0,
                width: 80,
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'DisplayName',
                sortable: true,
                headerName: 'Role Name',
                field: 'roleName',
                filter: agGridConstants.columnFilterTypes.text,
                floatingFilter: true,
                filterParams: TextFilterDefaults,
                sort: { direction: 'asc', priority: 0 },
                cellRenderer: 'nameRenderer',
                cellRendererParams: {
                    clicked: this.onViewRole,
                    nameField: 'roleName',
                    idField: 'id',
                    title: 'View this Group',
                },
            },
            {
                colId: 'RoleClaims.ApplicationClaimId',
                sortable: false,
                headerName: 'Permissions',
                field: 'permissionsFlat',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: {
                    labelText: 'Filter by type',
                    options: permissions,
                    optionsLabel: 'label',
                    optionsValue: 'value',
                },
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    options: permissions,
                    optionsLabel: 'label',
                    optionsValue: 'value',
                },
            },
        ];

        const ds = createDataSource(ApiRoutes.role.search(), gridOptions);

        this.setState({
            loading: false,
            gridOptions,
            dataSource: ds,
        });
    };

    render() {
        if (this.state.loading) return (<LinearProgress variant="indeterminate" color="secondary" />);
        const { rowData, gridOptions } = this.state;

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon icon={faWarehouse} className="mr-2 text-muted" />
                    <span>Roles</span>
                </PageHeading>
                <DataGridToolbar
                    entity="group"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    onAdd={() => this.props.history.push(`${AppNavPaths.RoleNew}`)}
                    addLabel="Add Role"
                />
                <DataGrid
                    domLayout="normal"
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                />
            </PageWrap>
        );
    }
}
export default withRouter(RoleIndex);
