import React from 'react';
import './DispatchBoard.scss';
import { CardBody, CardTitle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cls from 'classnames';
import {
    faHardHat,
    faMinusCircle,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import { Droppable } from '@hello-pangea/dnd';
import { ContractTimesheetResponsibilityType } from '../tenant/Tenant';
import { ConfirmationStatus } from '../cancelConfirmation/CancelConfirmation';
import { LocalizationKeys as l } from '../Common';

export default class StaffJobAssignment extends React.Component {
    render() {
        const {
            job, day, group, jobIndex, assignmentIndex, strings,
        } = {
            ...this.props,
        };
        const crewLead = strings[l.crewLead];
        const dayInt = day.getDay();
        const dropId = `staff_${jobIndex}_${dayInt}_${assignmentIndex}`;
        return (
            <div key={dropId} className="jobAssignment">
                <Droppable droppableId={dropId} style={{ transform: 'none' }}>
                    {(provided, snapshot) => (
                        <div
                            className="assignment-placeholder-row"
                            style={{ height: '50px !important' }}
                        >
                            <label className="dropPlaceholder">
                                <FontAwesomeIcon
                                    icon={faUser}
                                    className="mr-1"
                                />
                            </label>
                            <div
                                className={cls('jobAssignmentDropTarget', {
                                    dragging: snapshot.isDraggingOver,
                                    show:
                                        !group.assignment
                                        || !!snapshot.isDraggingOver,
                                })}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {!!group.assignment && (
                                    <div className="card staffCard compact">
                                        <CardBody>
                                            <CardTitle className="text-left d-flex flex-row justify-content-between align-items-center">
                                                <span className="d-flex flex-row flex-fill justify-content-between align-items-center">
                                                    <label className="mr-1 dropPlaceholder">{`${group.name}`}</label>
                                                    <div className="d-flex flex-column">
                                                        <div className="d-flex flex-row justify-content-end align-items-center">
                                                            {job.contractTimesheetResponsibilityTypeId
                                                                === ContractTimesheetResponsibilityType.CrewLead && (
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faHardHat
                                                                    }
                                                                    onClick={() => {
                                                                        if (
                                                                            job
                                                                                .assignments[
                                                                                    dayInt
                                                                                ]
                                                                                .canEdit
                                                                        ) {
                                                                            this.props.setCrewLead(
                                                                                group.jobAssignmentId,
                                                                                job.id,
                                                                            );
                                                                        }
                                                                    }}
                                                                    className={cls(
                                                                        'mr-1 cursor-pointer crewLeadBadge',
                                                                        {
                                                                            isCrewLead:
                                                                                !!group.isCrewLead,
                                                                        },
                                                                    )}
                                                                    title={
                                                                        group.isCrewLead
                                                                            ? crewLead
                                                                            : `Mark this employee as the ${crewLead}`
                                                                    }
                                                                />
                                                            )}
                                                            <span className="text-overflow-ellipsis mr-2">
                                                                {
                                                                    group
                                                                        .assignment
                                                                        .name
                                                                }
                                                            </span>
                                                        </div>
                                                        <span
                                                            className={`text-right assignment-status-label mr-2 ${group.assignment.confirmationStatusName
                                                                .replace(
                                                                    ' ',
                                                                    '',
                                                                )
                                                                .toLowerCase()}`}
                                                        >
                                                            {
                                                                group.assignment
                                                                    .confirmationStatusName
                                                            }
                                                        </span>
                                                    </div>
                                                </span>
                                                {
                                                    // job.canEdit is backed by whether or not the crew has submitted a timesheet.
                                                    // if we have a timesheet for this crew for this day, we cannot remove the assignment.
                                                    group.assignment
                                                        .confirmationStatusId
                                                        != ConfirmationStatus.CancelPending
                                                        && !!job.assignments[
                                                            dayInt
                                                        ].canEdit && (
                                                        <FontAwesomeIcon
                                                            title="Remove this personnel"
                                                            icon={
                                                                faMinusCircle
                                                            }
                                                            className="text-danger cursor-pointer"
                                                            onClick={() => this.props.onRemoveAssignment(
                                                                job,
                                                                group,
                                                                dayInt,
                                                            )}
                                                        />
                                                    )
                                                }
                                            </CardTitle>
                                        </CardBody>
                                    </div>
                                )}
                                {
                                    // map qualifying group and roles for display
                                    !group.assignment && (
                                        <div className="dropPlaceholderWrapper">
                                            <label className="dropPlaceholder mr-1">
                                                {group.name}
                                            </label>
                                            {group.assignableGroups.map((g) => (
                                                <span
                                                    title={g.groupName}
                                                    key={g.id}
                                                    className="badge badge-lg badge-secondary mr-1"
                                                >
                                                    {g.groupName
                                                        .charAt(0)
                                                        .toUpperCase()}
                                                </span>
                                            ))}
                                        </div>
                                    )
                                }
                                {provided.placeholder}
                            </div>
                        </div>
                    )}
                </Droppable>
            </div>
        );
    }
}
