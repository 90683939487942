import React from 'react';
import { Button } from 'reactstrap';
import { FlexStartRow, FlexColumnStart } from './FormElements';

export class EmailRecipient {
    constructor(label, email) {
        this.label = label;
        this.email = email;
    }
}

const EmailRecipients = (props) => {
    const {
        emailAddresses,
        onEmailAdded,
        onEmailChanged,
        onEmailRemoved,
        currentEmailAddress,
    } = { ...props };
    return (
        <>
            {
                !props.readOnly
                && (
                    <FlexStartRow className="pl-2">
                        <input
                            id="email"
                            style={{ maxWidth: '400px' }}
                            placeholder="ex: person@provider.com"
                            autoComplete="off"
                            type="email"
                            className="form-control mr-2"
                            onChange={onEmailChanged}
                            value={currentEmailAddress ?? ''}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    e.preventDefault();
                                    onEmailAdded();
                                }
                            }}
                        />
                        <Button
                            type="button"
                            size="sm"
                            color="primary"
                            disabled={!currentEmailAddress}
                            onClick={onEmailAdded}
                        >
                    Add
                        </Button>
                    </FlexStartRow>
                )
            }
            {
                !!(emailAddresses ?? []).length
                && (
                    <FlexStartRow className="mt-2 ml-2 p-2 border">
                        <FlexColumnStart className="mr-2">
                            <i className="fa fa-lg fa-envelope mr-2 mb-1" />
                        </FlexColumnStart>
                        <FlexColumnStart>
                            <FlexStartRow className="flex-wrap">
                                {
                                    (emailAddresses ?? []).map((x, i) => (
                                        <span
                                            key={i}
                                            style={{ fontSize: '85%', fontWeight: 'normal' }}
                                            className="badge badge-lg badge-email p-1 mr-2 mb-1"
                                        >
                                            <span>{x}</span>
                                            {
                                                !props.readOnly && (!(props.readOnlyAddresses ?? []).includes(x))
                                            && (
                                                <i
                                                    className="fa fa-minus-circle ml-2 cursor-pointer"
                                                    onClick={() => onEmailRemoved(x)}
                                                />
                                            )
                                            }
                                        </span>
                                    ))
                                }
                            </FlexStartRow>
                        </FlexColumnStart>
                    </FlexStartRow>
                )
            }
        </>
    );
};

export default EmailRecipients;
