import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    Col,
    Row,
    FormGroup,
    Container,
    Input,
    Button,
    Table,
    List,
} from 'reactstrap';
import { CircularProgress } from '@mui/material';
import CommonContext, { ApiRoutes } from '../Common';
import { util } from '../Util';
import authService from '../api-authorization/AuthorizeService';
import {
    AppPageForm, FormLabel, onFieldChange, toasty,
} from '../common/forms/FormElements';
import CustomCircularProgress from '../common/CustomCircularProgress';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import './ExecutionHistory.scss';

class AddJobCityViewModel {
    city = '';

    stateId = '';
}

class AddJobCity extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            formValidated: false,
            loading: true,
            saving: false,
            AddJobCityVM: new AddJobCityViewModel(),
            usageHistory: [],
            isValidated: false,
            usStates: [],
            selectedStateOption: null,
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    onChange = onFieldChange;

    onSubmit = async () => {
        const { AddJobCityVM } = this.state;

        this.setState({ saving: true });

        try {
            await util.fetch.post(
                ApiRoutes.adminTool.addJobCity(),
                AddJobCityVM,
            );

            this.setState({
                AddJobCityVM: new AddJobCityViewModel(),
                selectedStateOption: null,
                isValidated: false,
            });

            toasty.success(`New City ${AddJobCityVM?.city} Added`);

            // Refresh the table after successful submission
            await this.getAdminToolHistory();
        } catch (err) {
            toasty.error(
                'Error: Add New City Failed. Please Check Form Inputs',
            );
            await this.getAdminToolHistory();
        } finally {
            this.setState({ saving: false });
        }
    };

    onUSStateChanged = (selectedOption) => {
        this.setState((prevState) => ({
            AddJobCityVM: {
                ...prevState.AddJobCityVM,
                state: selectedOption ? selectedOption.value : null,
            },
            selectedStateOption: selectedOption,
        }));
    };

    getAdminToolHistory = async () => {
        const [usageHistory] = await Promise.all([
            util.fetch.js(ApiRoutes.adminTool.getAdminToolHistory(5)),
        ]);

        this.setState({ usageHistory });
    };

    getUSStates = async () => {
        const [usStates] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.USStates()),
        ]);

        this.setState({ usStates }, this.setInitialSelectedStateOption);
    };

    setInitialSelectedStateOption = () => {
        const { usStates, AddJobCityVM } = this.state;
        if (usStates && AddJobCityVM.state) {
            const selectedOption = usStates.find(
                (option) => option.value === AddJobCityVM.state,
            );
            this.setState({ selectedStateOption: selectedOption });
        }
    };

    formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();
        if (isAuthenticated) {
            this.setState({ loading: true });
            await this.getAdminToolHistory();
            await this.getUSStates();
            this.setInitialSelectedStateOption();
            this.setState({ loading: false });
        }
    }

    render() {
        const {
            loading,
            saving,
            isValidated,
            AddJobCityVM,
            usageHistory,
            usStates,
            selectedStateOption,
        } = this.state;

        if (loading) {
            return (
                <div className="confirmationContainer h-100 pt-2 d-flex flex-row align-items-center justify-content-center">
                    <CustomCircularProgress
                        color="secondary"
                        variant="indeterminate"
                        label={
                            this.props.match.params.id
                                ? 'Getting Tool History...'
                                : 'Loading form...'
                        }
                    />
                </div>
            );
        }

        if (!(this.context ?? {}).user) return null;

        return (
            <AppPageForm
                formShown={this.context.formIsOpen}
                formId="adminToolList"
                formName="adminToolList"
                formHeading="Admin Tool: Add New Job City"
                formRef={this.formRef}
                saving={saving}
                isValidated={isValidated}
                onSubmit={this.onSubmit}
                setIsValidated={(value) => {
                    this.setState({ isValidated: value });
                }}
            >
                <Container>
                    <Row>
                        <Col className="mt-3 mb-2" xs="12">
                            <h3>Add Job City</h3>
                        </Col>

                        <Col className="bg-light border" xs="12">
                            <div>
                                <FormGroup className="mt-3">
                                    <FormLabel
                                        htmlFor="city"
                                        text="City"
                                        required
                                    />
                                    <Input
                                        id="city"
                                        name="AddJobCityVM.city"
                                        placeholder="City"
                                        onChange={this.onChange}
                                        value={AddJobCityVM.city}
                                        required
                                    />
                                    <small className="invalid-feedback text-danger">
                    City is Required.
                                    </small>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="state"
                                        text="State"
                                        required
                                    />
                                    <ValidatedSelect
                                        id="state"
                                        name="state"
                                        required
                                        options={usStates}
                                        value={selectedStateOption}
                                        onChange={this.onUSStateChanged}
                                        validationMessage="A State selection is required."
                                    />
                                </FormGroup>
                                <div className="d-flex justify-content-center">
                                    <Button
                                        className="btn mr-2 mt-3 mb-2"
                                        color="primary"
                                        type="submit"
                                        disabled={saving}
                                    >
                                        {!saving && <span>Submit</span>}
                                        {saving && (
                                            <>
                                                <CircularProgress
                                                    size={24}
                                                    className="saving-button-progress text-success mr-2"
                                                />
                                                <span>
                        Submitting, please wait...
                                                </span>
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </Col>

                        <Col xs="12" className="execution-history">
                            <h3 className="mt-4">Execution History</h3>

                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>User</th>
                                        <th>Details</th>
                                        <th>Execution Date & Time</th>
                                        <th>Result</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {usageHistory.map((record) => {
                                        const errorMessage = record.output?.errorMessage;

                                        return (
                                            <tr key={record.id}>
                                                <td>{record.id}</td>
                                                <td>{record.userName}</td>
                                                <td>
                                                    <div>
                                                        <List type="unstyled">
                                                            {record.input.inputCity && (
                                                                <li>
                                                                    <strong>City:</strong>
                                                                    {' '}
                                                                    {record.input.inputCity}
                                                                </li>
                                                            )}
                                                            {record.input.inputState && (
                                                                <li>
                                                                    <strong>State:</strong>
                                                                    {' '}
                                                                    {record.input.inputState}
                                                                </li>
                                                            )}
                                                        </List>
                                                    </div>

                                                    {!!errorMessage && (
                                                        <div>
                                                            <strong>Error:</strong>
                                                            {' '}
                                                            {errorMessage}
                                                        </div>
                                                    )}
                                                    <div>
                                                        {record.output?.message && (
                                                            <div>
                                                                <strong>Message:</strong>
                                                                {' '}
                                                                {record.output.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                                <td>{this.formatDate(record.executionDateTime)}</td>
                                                <td>{record.isSucceed ? 'Succeed' : 'Failed'}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </AppPageForm>
        );
    }
}

export default withRouter(AddJobCity);
