import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { LinearProgress } from '@mui/material';
import { withRouter } from 'react-router-dom';
import {
    createDataSource, createGridOptions, DataGrid, booleanCellRenderer, LinkCellRenderer, TextFilterDefaults, indexCellRenderer,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { PageHeading, PageWrap } from '../common/forms/FormElements';
import { util } from '../Util';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';

class ChargeTypeIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rowData: [],
            selectedChargeType: {},
            selectedException: null,
            selectedRow: null,
        };
        this.onAddChargeType = this.onAddChargeType.bind(this);
        this.onEditChargeType = this.onEditChargeType.bind(this);
        this.yesNoCellRenderer = this.yesNoCellRenderer.bind(this);
    }

    componentDidMount() { return this.populateState(); }

    componentWillUnmount() { return this.setState = (state, callback) => { }; }

    onAddChargeType = () => {
        this.props.history.push(`${AppNavPaths.ChargeTypeNew}`);
    };

    onEditChargeType = (id) => {
        this.props.history.push(`${AppNavPaths.ChargeType}/${id}`);
    };

    onSelectionChanged = (event) => {
        const selection = event.api.getSelectedRows();
        this.setState({ selectedRow: selection.length ? selection[0] : null });
    };

    async populateState() {
        const [groups, equipmentTypes, units] = await Promise.all([
            util.fetch.js(ApiRoutes.auth.groups()),
            util.fetch.js(ApiRoutes.typeAheads.equipmentTypes()),
            util.fetch.js(ApiRoutes.chargeTypeUnit.all()),
        ]);

        const gridOptions = createGridOptions(this);

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
        };

        const unitsFilterParams = {
            suppressFilterButton: true,
            options: units,
            optionsLabel: 'description',
            optionsValue: 'id',
        };
        const groupFilterParams = {
            suppressFilterButton: true,
            options: groups,
            optionsLabel: 'groupName',
            optionsValue: 'id',
        };
        const equipmentFilterParams = {
            suppressFilterButton: true,
            options: equipmentTypes,
            optionsLabel: 'description',
            optionsValue: 'id',
        };
        const billableFilterParams = {
            suppressFilterButton: true,
            options: [{ name: 'Yes', id: 'true' }, { name: 'No', id: 'false' }],
            optionsLabel: 'name',
            optionsValue: 'id',
        };

        gridOptions.columnDefs = [
            {
                flex: 0,
                maxWidth: 80,
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'Description',
                sortable: true,
                headerName: 'Description',
                field: 'description',
                sort: { direction: 'asc', priority: 0 },
                cellRenderer: 'nameRenderer',
                cellRendererParams: {
                    clicked: this.onEditChargeType,
                    nameField: 'description',
                    idField: 'id',
                    title: 'View this Charge Type',
                },
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilter: true,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'Units.Description',
                sortable: true,
                headerName: 'Charge Units',
                field: 'unitsName',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: unitsFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: unitsFilterParams,
            },
            {
                colId: 'Group',
                sortable: false,
                headerName: 'Eligible Roles',
                field: 'rolesFlat',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: groupFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: groupFilterParams,
            },
            {
                colId: 'EquipmentType',
                headerName: 'Eligible Equipment',
                sortable: false,
                field: 'equipmentFlat',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: equipmentFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: equipmentFilterParams,
            },
            {
                colId: 'IsBillable',
                sortable: true,
                headerName: 'Billable?',
                field: 'isBillable',
                cellRenderer: this.yesNoCellRenderer,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: billableFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: billableFilterParams,
            },
        ];

        const dataSource = createDataSource(ApiRoutes.chargeType.search(), gridOptions);

        this.setState((state) => ({
            loading: false,
            gridOptions,
            dataSource,
        }));
    }

    yesNoCellRenderer = booleanCellRenderer;

    render() {
        if (this.state.loading) return (<LinearProgress variant="indeterminate" color="secondary" />);

        const { rowData, gridOptions } = this.state;

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon icon={faFileInvoiceDollar} className="mr-2 text-muted" />
                    <span>Charge Types</span>
                </PageHeading>
                <DataGridToolbar
                    entity="chargetype"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    onAdd={this.onAddChargeType}
                    addLabel="Add Charge Type"
                />
                <DataGrid
                    domLayout="normal"
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                />
            </PageWrap>
        );
    }
}
export default withRouter(ChargeTypeIndex);
