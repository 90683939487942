import { v4 as uuid } from 'uuid';

export class BillingRate {
    id = uuid();

    rate = null;

    otRate = null;

    altRate = null;

    effectiveDate = new Date();

    expirationDate = null;

    current = false;

    qBInventoryItemId = null;

    quickbooksInventoryItemName = null;
}
