import React, { Component } from 'react';
import cls from 'classnames';
import { FormGroup } from 'reactstrap';
import Select from 'react-select';
import { FormLabel } from '../forms/FormElements';

export default class DataGridSelectFilter extends Component {
    constructor(props) {
        super(props);
        this.selectRef = React.createRef();
        this.state = { selectedValue: props.isMulti ? [] : null };
        this.valueGetter = this.props.valueGetter;
        this.onChange = this.onChange.bind(this);
    }

    onChange = (selection) => {
        if (this.state.selectedValue !== selection) {
            this.setState(
                {
                    selectedValue: selection,
                },
                () => {
                    this.props.filterChangedCallback();
                },
            );
        }
    };

    // for now, floating filter is single select since React Select refuses to cooperate in AgGrid layout.
    onFloatingFilterChanged = (value) => {
        let newVal = (this.props.options ?? []).find((x) => value == x[this.props.optionsValue]);

        if (this.props.isMulti) newVal = [newVal];

        this.onChange(newVal);
    };

    getModel() {
        let value = null;

        if (this.props.isMulti) value = (this.state.selectedValue ?? []).map((sv) => sv[this.props.optionsValue]);
        else value = this.state.selectedValue ? this.state.selectedValue[this.props.optionsValue] : null;

        return { value };
    }

    setModel(model) {
        this.setState(
            { selectedValue: (model ?? {}).value ?? '' },
            () => {
                this.props.filterChangedCallback();
            },
        );
    }

    afterGuiAttached(params) {
        this.focus();
    }

    focus() {
        window.setTimeout(() => {
            const container = this.selectRef.current;
            if (container) {
                container.focus();
            }
        });
    }

    isFilterActive = () => !!this.state.selectedValue;

    render() {
        let reactSelectClassNames = [];
        reactSelectClassNames = cls('react-select', this.props.className);
        return (
            <FormGroup className="p-2" style={{ minWidth: '300px' }}>
                <FormLabel text={this.props.labelText} />
                <Select
                    styles={{
                        // Fixes the overlapping problem of the component
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    disabled={!!this.props.disableFilter}
                    isMulti={this.props.isMulti}
                    isClearable
                    className={reactSelectClassNames}
                    ref={this.selectRef}
                    value={this.state.selectedValue ?? ''}
                    options={this.props.options}
                    getOptionLabel={(option) => option[this.props.optionsLabel]}
                    getOptionValue={(option) => option[this.props.optionsValue]}
                    key={this.props.optionsValue}
                    onChange={this.onChange}
                />
            </FormGroup>
        );
    }
}
