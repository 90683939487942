import { ApiRoutes } from './Common';
import { util } from './Util';

export default class Strings {
    // 2022-08-11 - M. Nicol
    // Ties into: https://github.com/stefalda/localized-strings/blob/master/README.md
    // Uses several checks to get language as shown in getInterfaceLanguage() here:
    // https://github.com/stefalda/localized-strings/blob/master/src/utils.js
    static async getStrings() {
    // Example format:
    // {
    //  en: {
    //      CrewLead: "Driver",
    //      AnotherString: "Some String"
    //  },
    //  it: {
    //      CrewLead: "<Italian word for 'Driver'>",
    //      AnotherString: "<Italian translation>"
    //  }
    // }
        const strings = await util.fetch.js(ApiRoutes.localization.strings());
        return strings;
    }
}
