import {
    faCheckCircle, faFileAlt, faImage, faMinusCircle, faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
// import { Link } from "react-router-dom";
import cls from 'classnames';
import { LinearProgress } from '@mui/material';
import * as moment from 'moment';
import { util } from '../../Util';
import { SupportedFileExtensions } from '../../Common';

const FileLink = (props) => {
    const { path, name, extension } = { ...props.file };

    if (!path) return null;

    const ext = extension ?? (path.substring(path.lastIndexOf('.'), path.length) || path);
    const modifiedDate = moment(props.file.lastModified);
    const modified = modifiedDate.toDate() >= new Date() ? 'just now' : modifiedDate.fromNow();

    return (
        <div className={cls('border rounded mb-1 p-2 d-flex flex-column mr-2')}>
            <small className="d-flex flex-row justify-space-between">
                <div className="flex-fill">
                    {!!props.preview && <FontAwesomeIcon icon={faPlusCircle} className="text-success mr-2" size="lg" />}
                    {!!SupportedFileExtensions.image.includes(ext) && <FontAwesomeIcon size="lg" icon={faImage} className="mr-2" />}
                    {!!SupportedFileExtensions.document.includes(ext) && <FontAwesomeIcon size="lg" icon={faFileAlt} className="mr-2" />}
                    {!!props.preview && <span>{`${path}`}</span>}
                    {!props.preview && <span className="site-link cursor-pointer" onClick={props.onDownload}>{`${name}`}</span>}
                </div>
                <div className="flex-fill d-flex flex-row justify-content-end align-items-center">
                    {!props.viewOnly && !props.preview && props.file.lastModified && <span className="ml-3 text-success">{`Added ${modified}`}</span>}
                    {!!props.showFileSize && <span className="ml-2">{util.file.printFileSize(props.file.size, true)}</span>}
                    {!props.viewOnly && !!((props.progress ?? 0) < 100) && <FontAwesomeIcon icon={faMinusCircle} size="lg" className="text-danger ml-3 cursor-pointer" onClick={props.remove} />}
                    {((props.progress ?? 0) >= 100) && <FontAwesomeIcon icon={faCheckCircle} size="lg" className="text-success ml-3" />}
                </div>
            </small>
            {!props.viewOnly && ((props.progress ?? 0) < 100) && <LinearProgress className="file-upload-progress" variant="determinate" value={props.progress ?? 0} />}
        </div>
    );
};

export default FileLink;
