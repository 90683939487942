import React from 'react';
import {
    faFileInvoiceDollar,
    faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Row, Button } from 'reactstrap';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createClientSideGridOptions } from '../common/dataGrid/DataGrid';
import CommonContext, { ApiRoutes } from '../Common';
import { util } from '../Util';
import SlideForm from '../common/forms/SlideForm';

export default class PayScheduleSelect extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            contractId: '',
            paySchedules: [],
            loading: true,
            omissions: [],
            refreshing: false,
            rowData: [],
            selectedRows: [],
            show: false,
        };
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.setupGrid = this.setupGrid.bind(this);
    }

    componentDidMount() {
        this.populateState();
    }

    onAdd = () => {
        const { selectedRows } = { ...this.state };
        this.setState({ show: false }, () => {
            this.props.onAddCallback(selectedRows);
        });
    };

    onClose = () => {
        this.setState({ show: false, formValidated: false });
    };

    onSave = () => {};

    onSelectionChanged = (event) => {
        const selections = event.api.getSelectedRows() ?? [];
        this.setState({ selectedRows: selections });
    };

    onSubmit = () => {};

    getPaySchedules = async (id, omissions) => {
        let paySchedules = await util.fetch.js(ApiRoutes.paySchedule.all());

        paySchedules = paySchedules.filter((ps) => !omissions.includes(ps.id)) ?? [];
        this.state.gridApi.setRowData(paySchedules);
        this.state.gridApi.refreshCells();
        this.setState({ selectedRows: [], refreshing: false, loading: false });
    };

    setupGrid = async () => {
        const gridOptions = createClientSideGridOptions(this);

        gridOptions.defaultColDef = { sortable: true, flex: 1 };
        gridOptions.headerHeight = 18;
        gridOptions.rowMultiSelectWithClick = true;
        gridOptions.overlayNoRowsTemplate = "No pay schedules to display. You've either selected all the available pay schedules, or no pay schedules were found.";

        gridOptions.columnDefs = [
            {
                minwidth: 150,
                maxWidth: 150,
                colId: 'Description',
                sortable: true,
                headerName: 'Description',
                field: 'governingBody',
                sort: { direction: 'asc', priority: 0 },
            },
            {
                minwidth: 150,
                flex: 0,
                colId: 'State',
                sortable: true,
                headerName: 'State',
                field: 'state',
            },
            {
                sortable: false,
                headerName: 'Counties',
                field: 'counties',
            },
            {
                minwidth: 80,
                maxWidth: 80,
                sortable: false,
                headerName: 'Reg',
                field: 'reg',
            },
            {
                minwidth: 80,
                maxWidth: 80,
                sortable: false,
                headerName: 'OT',
                field: 'ot',
            },
            {
                minwidth: 80,
                maxWidth: 80,
                sortable: false,
                headerName: 'Fringe',
                field: 'fringe',
            },
        ];

        return { gridOptions };
    };

    open = async (id, omissions) => {
        const contractId = id;
        await this.getPaySchedules(contractId, omissions);
        this.setState({
            show: true,
            contractId,
            omissions: [...omissions],
        });
    };

    async populateState() {
        const { gridOptions } = await this.setupGrid();

        gridOptions.rowData = [];

        this.setState({
            loading: false,
            gridOptions,
        });
    }

    render() {
        const { gridOptions, formValidated, selectedRows } = this.state;
        const selectionCount = (selectedRows ?? []).length
            ? ` (${selectedRows.length})`
            : '';

        return (
            <SlideForm
                size="col-xl-6 col-md-12 col-sm-12"
                loading={this.state.loading}
                show={this.state.show}
                id={this.props.id}
                formIcon={faFileInvoiceDollar}
                formTitle="Add Pay Schedules to Contract"
                ref={this.formRef}
                setIsValidated={(value) => {
                    this.setState({ formValidated: value });
                }}
                isValidated={formValidated}
                onClose={this.onClose}
            >
                <div className="w-100 d-flex flex-row align-items-center pt-1 pb-1">
                    <div className="flex-fill d-flex flex-row align-items-center">
                        <FontAwesomeIcon
                            icon={faQuestionCircle}
                            className="mr-1"
                        />
                        <small>
              You may multiselect by clicking on individual rows.
                        </small>
                    </div>
                </div>
                <div className="pt-0 flex-fill ag-theme-alpine datatable">
                    <AgGridReact gridOptions={gridOptions} />
                </div>
                <Row className="d-flex flex-row justify-content-end pt-2 pl-0 pr-0 ml-0 mr-0 text-right w-100">
                    <Button
                        disabled={!(selectedRows ?? []).length}
                        size="sm"
                        type="button"
                        color="primary"
                        onClick={this.onAdd}
                    >
                        {`Add Selected${selectionCount}`}
                    </Button>
                </Row>
            </SlideForm>
        );
    }
}
