export const AdjustmentTypeOptionsValues = {
    Add: 'ADD',
};

export const AdjustmentTypeOptions = [
    { value: AdjustmentTypeOptionsValues.Add, label: 'Add Charge', className: 'fa-plus-circle text-success' },
    { value: AdjustmentTypeOptionsValues.Adjust, label: 'Adjust Charge', className: 'fa-minus-circle text-danger' },
];

export class TimesheetDetailsAdjustment {
    id = null;

    startDate = null;

    start = null;

    endDate = null;

    end = null;

    isBreak = false;

    isPaidBreak = false;

    resourceName = '';

    resourceId = null;

    chargeTypeName = '';

    chargeTypeId = null;

    quantity = null;

    unitsName = '';

    isBillable = false;

    billable = '';

    show = true;

    notes = '';

    resources = [];

    adjustmentType = AdjustmentTypeOptionsValues.Add;
}
