import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';

export default class DataGridSelectFloatingFilter extends Component {
    constructor(props) {
        super(props);
        this.selectRef = React.createRef();
        this.state = { selectedValue: this.props.initialFilterValue ?? '' };
        this.valueChanged = this.valueChanged.bind(this);
    }

    componentDidMount() {
    // Have to do this as it will not actually set default in search model it seems.
        if (this.props.initialFilterValue) {
            const value = this.props.initialFilterValue;
            this.setState(() => this.props.parentFilterInstance((instance) => {
                !!instance.applyModel && instance.applyModel(); // RLC: you must call apply model or only the UI will be changed.
                instance.onFloatingFilterChanged(value);
            }));
        }
    }

    onParentModelChanged = (newVal, api) => {
        const valueMapped = (newVal?.value ?? '');
        this.setState({ selectedValue: valueMapped });
    };

    afterGuiAttached(params) {
        this.focus();
    }

    focus() {
        window.setTimeout(() => {
            const container = this.selectRef.current;
            if (container) {
                container.focus();
            }
        });
    }

    valueChanged = (event) => {
        const { value } = event.target;
        this.setState({ selectedValue: value }, () => this.props.parentFilterInstance((instance) => {
            !!instance.applyModel && instance.applyModel(); // RLC: you must call apply model or only the UI will be changed.
            instance.onFloatingFilterChanged(value);
        }));
    };

    render() {
        return (
            <FormGroup
                className="pl-0 pt-3 pb-0"
                style={{ zIndex: 1, width: '100%', overflow: 'visible' }}
            >

                <select
                    ref={this.selectRef}
                    disabled={!!this.props.disableFilter}
                    className="form-control form-control-sm"
                    style={{ padding: '1px 4px' }}
                    onChange={this.valueChanged}
                    value={this.state.selectedValue ?? ''}
                >
                    <option value="">All</option>
                    {(this.props.options ?? []).map((o, i) => (
                        <option
                            key={i}
                            value={o[this.props.optionsValue]}
                        >
                            {o[this.props.optionsLabel]}
                        </option>
                    ))}
                </select>

            </FormGroup>
        );
    }
}
