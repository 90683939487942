export class UserGroup {
    id = null;

    companyId = null;

    groupName = '';

    description = '';

    userGroups = [];
}
