import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { CircularProgress } from '@mui/material';
import { YesNoBadge } from '../common/forms/FormElements';
import CommonContext from '../Common';

export class QBIIContractChargeTypeGrid extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            showForm: false,
        };
    }

    componentDidMount() {
        this.populateState();
    }

    async populateState() {
        this.setState({
            loading: false,
        });
    }

    render() {
        if (this.state.loading) {
            return (<CircularProgress color="secondary" variant="indeterminate" />);
        }
        const { contractChargeTypes } = this.props;

        return (
            <Container fluid>
                <Row>
                    <Col className="ml-0 mr-0">
                        <table className="table table-sm table-bordered">
                            <thead>
                                <tr>
                                    <th rowSpan="2">Company</th>
                                    <th rowSpan="2">Operations Center</th>
                                    <th rowSpan="2">Contract Number</th>
                                    <th rowSpan="2">Charge Type</th>
                                    <th rowSpan="2">Effective Date</th>
                                    <th rowSpan="2">Expiration Date</th>
                                    <th rowSpan="2">Is Active?</th>
                                    <th colSpan="3">Rates</th>
                                </tr>
                                <tr>
                                    <th>Normal</th>
                                    <th>Overtime</th>
                                    <th>Alternative</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!(contractChargeTypes ?? []).length
                                    ? <tr><td colSpan="10" className="text-center">No Contract Charge Types Found.</td></tr>
                                    : contractChargeTypes.map((cct, i) => (
                                        <tr key={cct.id}>
                                            <td className="d-flex flex-column">{cct.company}</td>
                                            <td>{cct.operationsCenterCompany}</td>
                                            <td>{cct.contractNumber}</td>
                                            <td>{cct.chargeType}</td>
                                            <td>{cct.effectiveDate}</td>
                                            <td>{cct.expirationDate}</td>
                                            <td><YesNoBadge value={cct.isActive} /></td>
                                            <td>{cct.rate}</td>
                                            <td>{cct.overtimeRate}</td>
                                            <td>{cct.alternativeRate}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Container>
        );
    }
}
