import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { CircularProgress } from '@mui/material';
import { util } from '../Util';
import { Can } from '../Can';
import { SmallButton } from '../common/forms/FormElements';
import CommonContext, { ApiRoutes } from '../Common';

export class EmployeeLongTermStatusInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            longTermStatuses: [],
        };
    }

    componentDidMount() {
        this.populateState();
    }

    getLongTermStatusName = (employeeLongTermStatus) => {
        if (!employeeLongTermStatus) {
            return '';
        }

        const { longTermStatuses } = this.state;

        const longTermStatus = longTermStatuses.find(
            (l) => l.id == employeeLongTermStatus.longTermStatusId,
        );

        return longTermStatus.name;
    };

    async populateState() {
        const [longTermStatuses] = await Promise.all([
            util.fetch.js(ApiRoutes.longTermStatus.all()),
        ]);

        this.setState({ longTermStatuses, loading: false });
    }

    render() {
        const { employeeLongTermStatuses } = this.props;

        if (this.state.loading) {
            return (
                <CircularProgress color="secondary" variant="indeterminate" />
            );
        }

        return (
            <>
                <Row className="pb-1">
                    <Col>
                        <div className="d-flex flex-row align-items-center justify-content-end">
                            <Can I="edit" a="employee">
                                <CommonContext.Consumer>
                                    {(value) => (
                                        <SmallButton
                                            disabled={!!value.formIsOpen}
                                            type="button"
                                            onClick={
                                                this.props
                                                    .onAddEmployeeLongTermStatus
                                            }
                                        >
                                            <i className="fa fa-plus-circle fa-md mr-2" />
                      Add Long-Term Status
                                        </SmallButton>
                                    )}
                                </CommonContext.Consumer>
                            </Can>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <table className="table table-sm table-bordered">
                            <thead className="text-muted">
                                <tr>
                                    <th>Name</th>
                                    <th>Effective Date</th>
                                    <th>Expiration Date</th>
                                    <Can I="edit" a="employeelongtermstatus">
                                        <th
                                            className="text-center"
                                            style={{ width: '30px' }}
                                        />
                                    </Can>
                                </tr>
                            </thead>
                            <tbody>
                                {!(employeeLongTermStatuses ?? []).length ? (
                                    <tr>
                                        <td colSpan="4" className="text-center">
                      No long term statuses found.
                                        </td>
                                    </tr>
                                ) : (
                                    employeeLongTermStatuses.map(
                                        (selectedEmployeeLongTermStatus, i) => {
                                            const longTermStatusName = this.getLongTermStatusName(
                                                selectedEmployeeLongTermStatus,
                                            );

                                            return (
                                                <tr
                                                    key={
                                                        selectedEmployeeLongTermStatus.id
                                                    }
                                                >
                                                    <td>
                                                        {longTermStatusName}
                                                    </td>
                                                    <td>
                                                        {util.date.getShort(
                                                            selectedEmployeeLongTermStatus.startDate,
                                                        )}
                                                    </td>
                                                    <td>
                                                        {util.date.getShort(
                                                            selectedEmployeeLongTermStatus.endDate,
                                                        ) ?? 'Current'}
                                                    </td>
                                                    <Can
                                                        I="edit"
                                                        a="employeelongtermstatus"
                                                    >
                                                        <CommonContext.Consumer>
                                                            {(value) => (
                                                                <td>
                                                                    <Button
                                                                        disabled={
                                                                            !!value.formIsOpen
                                                                        }
                                                                        type="button"
                                                                        color="outline-primary"
                                                                        onClick={() => this.props.onEditEmployeeLongTermStatus(
                                                                            selectedEmployeeLongTermStatus,
                                                                        )}
                                                                        size="sm"
                                                                    >
                                    Edit
                                                                    </Button>
                                                                </td>
                                                            )}
                                                        </CommonContext.Consumer>
                                                    </Can>
                                                </tr>
                                            );
                                        },
                                    )
                                )}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </>
        );
    }
}

EmployeeLongTermStatusInfo.contextType = CommonContext;
