export class LongTermStatus {
    id = -1;

    name = '';

    hideFromDispatchBoard = false;

    allowDispatch = false;

    requiresEndDate = false;
}
