import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    Col,
    Row,
    FormGroup,
    Container,
    Input,
    Button,
    Table,
    List,
} from 'reactstrap';
import { CircularProgress } from '@mui/material';
import CommonContext, { ApiRoutes } from '../Common';
import { util } from '../Util';
import authService from '../api-authorization/AuthorizeService';
import {
    AppPageForm, FormLabel, onFieldChange, toasty,
} from '../common/forms/FormElements';
import CustomCircularProgress from '../common/CustomCircularProgress';
import './ExecutionHistory.scss';

class BaseMoveTimesheet {
    timesheetNumber = '';

    contractNumber = '';
}

class MoveTimesheet extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            formValidated: false,
            loading: true,
            saving: false,
            moveTimesheet: new BaseMoveTimesheet(),
            usageHistory: [],
            isValidated: false,
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    onChange = onFieldChange;

    onSubmit = async () => {
        const { moveTimesheet } = { ...this.state };
        moveTimesheet.userId = this.context.user.employeeId;

        this.setState({ saving: true });

        try {
            await util.fetch.post(ApiRoutes.adminTool.moveTimesheetToJob(), moveTimesheet);

            this.setState({
                moveTimesheet: {
                    ...this.state.moveTimesheet,
                    timesheetNumber: '',
                    contractNumber: '',
                },
                isValidated: false,
            });

            toasty.success('Move Timesheet Succeed');

            // Refresh the table after successful submission
            await this.getAdminToolHistory();
        } catch (err) {
            toasty.error('Error: Move Timesheet Failed. Please Check Timesheet and Contract Number');
            await this.getAdminToolHistory();
        } finally {
            this.setState({ saving: false });
        }
    };

    getAdminToolHistory = async () => {
        const [usageHistory] = await Promise.all([
            util.fetch.js(ApiRoutes.adminTool.getAdminToolHistory(1)),
        ]);

        this.setState({ usageHistory, loading: false });
    };

    formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    open = async () => {
        this.setState(this.baseState);

        await this.populateState();
    };

    async populateState() {
        const { moveTimesheet } = { ...this.state };

        const isAuthenticated = await authService.isAuthenticated();
        if (isAuthenticated) {
            this.setState({ loading: true });
            await this.getAdminToolHistory();

            this.setState({ moveTimesheet });
        }
    }

    render() {
        const {
            loading, saving, isValidated, moveTimesheet, usageHistory,
        } = this.state;

        if (loading) {
            return (
                <div className="confirmationContainer h-100 pt-2 d-flex flex-row align-items-center justify-content-center">
                    <CustomCircularProgress
                        color="secondary"
                        variant="indeterminate"
                        label={
                            this.props.match.params.id
                                ? 'Getting Tool History...'
                                : 'Loading form...'
                        }
                    />
                </div>
            );
        }

        if (!(this.context ?? {}).user) return null;

        return (
            <AppPageForm
                formShown={this.context.formIsOpen}
                formId="adminToolList"
                formName="adminToolList"
                formHeading="Admin Tool: Move Timesheet To a New Job"
                formRef={this.formRef}
                saving={saving}
                isValidated={isValidated}
                onSubmit={this.onSubmit}
                setIsValidated={(value) => {
                    this.setState({ isValidated: value });
                }}
            >
                <Container>
                    <Row>
                        <Col
                            className="mt-2 mb-2"
                            xs="12"
                        >
                            <h3>
                Move Timesheet between Jobs
                            </h3>
                        </Col>

                        <Col
                            className="bg-light border"
                            xs="12"
                        >
                            <div>
                                <FormGroup className="mt-3">
                                    <FormLabel htmlFor="timesheetNum" text="Timesheet Number (From)" required />
                                    <Input
                                        id="timesheetNum"
                                        name="moveTimesheet.timesheetNumber"
                                        placeholder="Timesheet Number"
                                        onChange={this.onChange}
                                        value={moveTimesheet.timesheetNumber}
                                        required
                                    />
                                    <small className="invalid-feedback text-danger">
                    Timesheet Number is Required.
                                    </small>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="contractNum" text="Contract Number (To)" required />
                                    <Input
                                        id="contractNum"
                                        name="moveTimesheet.contractNumber"
                                        placeholder="Contract Number"
                                        onChange={this.onChange}
                                        value={moveTimesheet.contractNumber}
                                        required
                                    />
                                    <small className="invalid-feedback text-danger">
                    Contract Number is required.
                                    </small>
                                </FormGroup>
                                <div className="d-flex justify-content-center">
                                    <Button
                                        className="btn mr-2 mt-3 mb-2"
                                        color="primary"
                                        type="submit"
                                        disabled={
                                            !!saving
                                        }
                                    >
                                        {' '}
                                        {!saving && (
                                            <span>Submit</span>
                                        )}
                                        {!!saving && (
                                            <>
                                                <CircularProgress
                                                    size={24}
                                                    className="saving-button-progress text-success mr-2"
                                                />
                                                <span>Submitting, please wait...</span>
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </Col>

                        <Col xs="12" className="execution-history">
                            <h3 className="mt-4">
                Execution History
                            </h3>

                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>User</th>
                                        <th>Details</th>
                                        <th>Execution Date & Time</th>
                                        <th>Result</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {usageHistory.map((record) => {
                                        const errorMessage = record.output.message;

                                        return (
                                            <tr key={record.id}>
                                                <td>{record.id}</td>
                                                <td>{record.userName}</td>
                                                <td>
                                                    <div>
                                                        <strong>From:</strong>
                                                        <List type="unstyled">
                                                            {record.input.from_TimesheetNumber && (
                                                                <li>
                                                                    <strong>Timesheet:</strong>
                                                                    {' '}
                                                                    {record.input.from_TimesheetNumber}
                                                                </li>
                                                            )}
                                                            {record.input.from_JobNumber && (
                                                                <li>
                                                                    <strong>Job:</strong>
                                                                    {' '}
                                                                    {record.input.from_JobNumber}
                                                                </li>
                                                            )}
                                                            {record.input.from_ContractNumber && (
                                                                <li>
                                                                    <strong>Contract:</strong>
                                                                    {' '}
                                                                    {record.input.from_ContractNumber}
                                                                </li>
                                                            )}
                                                        </List>
                                                    </div>

                                                    {errorMessage ? (
                                                        <div>
                                                            <strong>To:</strong>
                                                            <List type="unstyled">
                                                                <li>
                                                                    <strong>Contract:</strong>
                                                                    {' '}
                                                                    {record.input.to_ContractNumber}
                                                                </li>
                                                            </List>
                                                            <strong>Error:</strong>
                                                            {' '}
                                                            {errorMessage}
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <strong>To:</strong>
                                                            <List type="unstyled">
                                                                {record.output.to_TimesheetNumber && (
                                                                    <li>
                                                                        <strong>Timesheet:</strong>
                                                                        {' '}
                                                                        {record.output.to_TimesheetNumber}
                                                                    </li>
                                                                )}
                                                                {record.output.to_JobNumber && (
                                                                    <li>
                                                                        <strong>Job:</strong>
                                                                        {' '}
                                                                        {record.output.to_JobNumber}
                                                                    </li>
                                                                )}
                                                                {record.output.to_ContractNumber && (
                                                                    <li>
                                                                        <strong>Contract:</strong>
                                                                        {' '}
                                                                        {record.output.to_ContractNumber}
                                                                    </li>
                                                                )}
                                                            </List>
                                                        </div>
                                                    )}
                                                </td>
                                                <td>{this.formatDate(record.executionDateTime)}</td>
                                                <td>{record.isSucceed ? 'Succeed' : 'Failed'}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </AppPageForm>
        );
    }
}

export default withRouter(MoveTimesheet);
