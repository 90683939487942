import React from 'react';
import './DailyRosterView.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { LinearProgress } from '@mui/material';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { ListGroup, ListGroupItem } from 'reactstrap';
import cls from 'classnames';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    TextFilterDefaults,
    LinkCellRenderer,
    ButtonCellRenderer,
    DateFilterDefaults,
    DateRangeReadOnlyFloatingFilterComponent,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, {
    ApiRoutes,
    ApplicationPermissions,
    AppNavPaths,
} from '../Common';
import {
    FlexBetweenRow,
    FlexCenterRow,
    FlexColumnStart,
    FlexStartRow,
    /* FormCheckbox, */ PageHeading,
    PageWrap,
} from '../common/forms/FormElements';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import { getTenantUserProfile } from '../common/TenantUserProfile';
import authService from '../api-authorization/AuthorizeService';
import { util } from '../Util';

class DailyRosterView extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingData: false,
            rowData: [],
            selectedRow: null,
            showEmployeeDetails: false,
            selectedEmployee: null,
            assignedOnly: false,
            cancellationsOnly: false,
            dailyAttendanceEvents: [],
            longTermStatuses: [],
            showLongTermStatusCountOnRoster: false,
            jobAssignmentCancellationTypes: [],
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        return this.setState = (state, callback) => {

        };
    }

    onCloseView = () => this.setState({ selectedEmployee: null, showEmployeeDetails: false });

    onViewJob = (id) => {
        this.props.history.push(`${AppNavPaths.Job}/${id}`);
    };

    getDailyAttendanceCount = (dailyAttendanceEventId) => {
        const { scoreboardData } = this.state;

        if (
            !scoreboardData
            || !scoreboardData.dailyAttendanceEventCounts
            || !scoreboardData.dailyAttendanceEventCounts.length
        ) {
            return 0.0;
        }

        const dailyAttendanceEventCount = scoreboardData.dailyAttendanceEventCounts.find(
            (dae) => dae.dailyAttendanceEventId == dailyAttendanceEventId,
        );

        return dailyAttendanceEventCount
            ? dailyAttendanceEventCount.count
            : 0.0;
    };

    getDisplayJobAssignmentCancellationTypes = () => {
        const { jobAssignmentCancellationTypes } = this.state;

        if (!jobAssignmentCancellationTypes) {
            return [];
        }

        return jobAssignmentCancellationTypes.filter(
            (jact) => jact.includeOnRosterView,
        );
    };

    getJobAssignmentCancellationTypeCount = (
        jobAssignmentCancellationTypeId,
    ) => {
        const { scoreboardData } = this.state;

        if (
            !scoreboardData
            || !scoreboardData.jobAssignmentCancellationTypeCounts
            || !scoreboardData.jobAssignmentCancellationTypeCounts.length
        ) {
            return 0.0;
        }

        const jobAssignmentCancellationTypeCount = scoreboardData.jobAssignmentCancellationTypeCounts.find(
            (jact) => jact.jobAssignmentCancellationTypeId
                    == jobAssignmentCancellationTypeId,
        );

        return jobAssignmentCancellationTypeCount
            ? jobAssignmentCancellationTypeCount.count
            : 0.0;
    };

    getlongTermStatusCount = (longTermStatusId) => {
        const { scoreboardData } = this.state;

        if (
            !scoreboardData
            || !scoreboardData.longTermStatusCounts
            || !scoreboardData.longTermStatusCounts.length
        ) {
            return 0.0;
        }

        const longTermStatusCount = scoreboardData.longTermStatusCounts.find(
            (lts) => lts.longTermStatusId == longTermStatusId,
        );

        return longTermStatusCount ? longTermStatusCount.count : 0.0;
    };

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (isAuthenticated) {
            let [
                tenantUserProfile,
                dispatchLocations,
                customers,
                dailyAttendanceEvents,
                tenantTenantSettings,
                longTermStatuses,
                allJobAssignmentCancellationTypes,
            ] = await Promise.all([
                getTenantUserProfile(),
                util.fetch.js(ApiRoutes.typeAheads.dispatchLocations()),
                util.fetch.js(ApiRoutes.typeAheads.customers()),
                util.fetch.js(ApiRoutes.dailyAttendanceEvent.all()),
                util.fetch.js(ApiRoutes.tenant.settings()),
                util.fetch.js(ApiRoutes.longTermStatus.all()),
                util.fetch.js(ApiRoutes.jobAssignmentCancellationType.all()),
            ]);

            const jobAssignmentCancellationTypes = allJobAssignmentCancellationTypes.filter(
                (jact) => !jact.treatAsUnassigned,
            );

            const tenantSettings = tenantTenantSettings && tenantTenantSettings.tenantSettings
                ? tenantTenantSettings.tenantSettings
                : {};

            const dailyAttendanceEventsEnabled = Boolean(
                tenantSettings.attendanceEventsEnabled,
            );

            const showLongTermStatusCountOnRoster = Boolean(
                tenantSettings.showLongTermStatusCountOnRoster,
            );

            if (!dailyAttendanceEventsEnabled) {
                dailyAttendanceEvents = [];
            }

            const longTermStatusesEnabled = Boolean(
                tenantSettings.employeeLongTermStatusManagementEnabled,
            );

            if (!longTermStatusesEnabled) {
                longTermStatuses = [];
            }

            const { currentUser, userPermissions } = { ...tenantUserProfile };
            const hasJobAccess = userPermissions.includes(ApplicationPermissions.job_create)
                || userPermissions.includes(ApplicationPermissions.job_edit)
                || userPermissions.includes(ApplicationPermissions.job_edit);

            const gridOptions = createGridOptions(this);
            const context = this;

            gridOptions.components = {
                selectFilter: DataGridSelectFilter,
                selectFloatingFilter: DataGridSelectFloatingFilter,
                linkRenderer: LinkCellRenderer,
                buttonRenderer: ButtonCellRenderer,
            };

            const dispatchFilterParams = {
                suppressFilterButton: true,
                labelText: 'Filter by Dispatch',
                options: dispatchLocations,
                optionsLabel: 'label',
                optionsValue: 'value',
                initialFilterValue: currentUser.dispatchCompanyId,
            };

            const dailyAttendanceEventParams = dailyAttendanceEvents.map(
                (dae) => ({
                    label: dae.name,
                    value: `dailyAttendanceEvent-${dae.id}`,
                }),
            );

            const longTermStatusParams = longTermStatuses.map((lts) => ({
                label: lts.name,
                value: `longTermStatus-${lts.id}`,
            }));

            const jobAssignmentCancellationTypeParams = jobAssignmentCancellationTypes
                .filter((jact) => jact.includeOnRosterView)
                .map((jact) => ({
                    label: jact.description,
                    value: `jobAssignmentCancellationType-${jact.id}`,
                }));

            const statusFilterParams = {
                suppressFilterButton: true,
                labelText: 'Filter by Status',
                options: [
                    { label: 'Unassigned', value: 'Unassigned' },
                    { label: 'Assigned', value: 'Assigned' },
                    { label: 'Cancelled', value: 'Cancelled' },
                    { label: 'Unbillable Cancel', value: 'Unbillable Cancel' },
                    ...jobAssignmentCancellationTypeParams,
                    ...longTermStatusParams,
                    ...dailyAttendanceEventParams,
                ],
                optionsLabel: 'label',
                optionsValue: 'value',
            };

            const customerFilterParams = {
                suppressFilterButton: true,
                labelText: 'Filter by Customer',
                options: customers,
                optionsLabel: 'label',
                optionsValue: 'value',
            };

            gridOptions.columnDefs = [
                {
                    colId: 'DispatchLocation',
                    headerName: 'Dispatching',
                    sortable: true,
                    field: 'dispatchLocation',
                    tooltipField: 'dispatchLocation',
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: { ...dispatchFilterParams },
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: { ...dispatchFilterParams },
                },
                {
                    colId: 'Employee',
                    headerName: 'Employee',
                    field: 'employee',
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'CustomerCompanyId',
                    headerName: 'Customer',
                    sortable: true,
                    field: 'customer',
                    tooltipField: 'customer',
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: { ...customerFilterParams },
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: { ...customerFilterParams },
                },
                {
                    colId: 'JobNumber',
                    minWidth: 180,
                    flex: 1,
                    headerName: 'Job Number',
                    field: 'jobNumber',
                    sortable: false,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    cellRenderer: hasJobAccess ? 'linkRenderer' : null,
                    cellRendererParams: !hasJobAccess
                        ? null
                        : {
                            clicked: this.onViewJob,
                            nameField: 'jobNumber',
                            idField: 'jobId',
                            title: 'Go to this Job',
                        },
                },
                {
                    width: 160,
                    minWidth: 160,
                    colId: 'DateRange',
                    headerName: 'Date',
                    headerClass: 'assignment-date-from',
                    field: 'assignmentDateTime',
                    sortable: true,
                    filter: 'agDateColumnFilter',
                    filterParams: {
                        ...DateFilterDefaults,
                        inRangeInclusive: true,
                        closeOnApply: true,
                        browserDatePicker: true,
                        suppressFilterButton: true,
                    },
                    floatingFilter: true,
                    floatingFilterComponent:
                        DateRangeReadOnlyFloatingFilterComponent,
                    floatingFilterComponentParams: {},
                    cellRenderer: (params) => {
                        if (!params.data) return '';

                        return moment(params?.value).isValid
                            ? moment(
                                params.data.assignmentDateTime
                                      ?? params.data.date,
                            ).format('M/D/YY')
                            : ' - ';
                    },
                },
                {
                    flex: 0,
                    width: 100,
                    minWidth: 100,
                    colId: 'StartTime',
                    headerName: 'Start',
                    headerClass: 'text-center',
                    field: 'assignmentDateTime',
                    sortable: false,
                    cellRenderer: (params) => {
                        if (params.value === undefined) return '';

                        return params.value
                            ? moment(params.value).format('h:mm A')
                            : ' - ';
                    },
                },
                {
                    flex: 0,
                    width: 120,
                    minWidth: 120,
                    headerName: '# Equipment',
                    headerClass: 'text-center',
                    field: 'numEquipment',
                    sortable: false,
                    cellRenderer: (params) => {
                        if (isNaN(params.value)) return '';
                        return params.value;
                    },
                },
                {
                    flex: 0,
                    width: 150,
                    colId: 'Status',
                    headerName: 'Status',
                    headerClass: 'text-center',
                    field: 'status',
                    sortable: true,
                    tooltipField: 'status',
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: { ...statusFilterParams },
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: { ...statusFilterParams },
                    cellRenderer: (params) => {
                        if (!params.value) return '';
                        let badgeClass = 'badge-secondary';
                        switch (params.value) {
                        case 'Assigned':
                            badgeClass = 'badge-success';
                            break;
                        case 'Cancelled':
                            badgeClass = 'badge-warning';
                            break;
                        case 'Call Off':
                        case 'No Show':
                            badgeClass = 'badge-danger';
                            break;
                        default:
                            badgeClass = 'badge-default';
                        }
                        return (
                            <span
                                style={{ width: '100px' }}
                                className={`badge badge-lg text-left ${badgeClass} p-2`}
                            >
                                {params.value}
                            </span>
                        );
                    },
                },
            ];

            gridOptions.overlayNoRowsTemplate = 'No roster information found for today.';
            // gridOptions.overlayLoadingTemplate = '<h4 class="d-flex flex-row align-items-center gridLoadingOverlay"><i class="fa fa-spin text-danger fa-lg fa-circle-notch mr-2"></i>Loading roster, please wait...</h4>';
            gridOptions.showLoadingOverlay = false;
            gridOptions.setLoading = (b) => {
                this.setState({ loadingData: b });
            };
            gridOptions.onRowClicked = this.rowClicked;
            gridOptions.rowHeight = 32;

            gridOptions.useLoading = true;
            gridOptions.loadingTemplate = '<span className="ag-overlay-loading-center "><i class="fa-spin fa fa-md fa-circle-notch"></i> Loading ...</span>';

            gridOptions.onGridReady = (params) => {
                // default date filter values
                const today = moment()
                    .startOf('day')
                    .format('YYYY-MM-DD HH:mm:ss');
                const dateRangeFilterComponent = params.api.getFilterInstance('DateRange');

                dateRangeFilterComponent.setModel({
                    filterType: 'date',
                    type: 'inRange',
                    dateFrom: today,
                    dateTo: today,
                });

                params.api.onFilterChanged();
                gridOptions.api = params.api;
                params.api.setDatasource(context.state.dataSource, gridOptions);
                context.setState({ gridApi: params.api });

                // Since ag grid seemingly has no way to access the default date picker and
                // set a minimum other than year, get at it through the DOM.  Should be safe with
                // React, since we are simply setting an HTML5 validation prop on the input.
                const targetNode = document.querySelector('.ag-root-wrapper');
                const observerOptions = {
                    childList: true,
                    attributes: false,
                    // Omit (or set to false) to observe only changes to the parent node
                    subtree: true,
                };
                const observer = new MutationObserver(
                    (mutationList, observer) => {
                        const records = mutationList.filter(
                            (x) => x.type === 'childList' && !!x.addedNodes.length,
                        );
                        for (const record of records) {
                            if (record.addedNodes) {
                                for (const node of record.addedNodes) {
                                    if (
                                        !!node.classList
                                        && node.classList.contains(
                                            'ag-popup-child',
                                        )
                                    ) {
                                        const fromInput = node.querySelector(
                                            '.ag-filter-from input',
                                        );
                                        !!fromInput
                                            && fromInput.setAttribute(
                                                'min',
                                                '2021-07-01',
                                            ); // we have no audit data past system inception date.
                                    }
                                }
                            }
                        }
                    },
                );
                observer.observe(targetNode, observerOptions);
            };

            // API returns an object with counts as well as rowData for the grid.
            // Save counts to the state, and return the rowData to the grid.
            gridOptions.postProcessData = (data) => {
                context.setState({
                    rowData: data,
                    showEmployeeDetails: false,
                    selectedEmployee: null,
                    scoreboardData: { ...data.counts } ?? null,
                });

                return { ...data.result };
            };

            const dataSource = createDataSource(
                ApiRoutes.employees.roster(),
                gridOptions,
            );

            this.setState({
                loading: false,
                gridOptions,
                dataSource,
                dailyAttendanceEvents,
                longTermStatuses,
                showLongTermStatusCountOnRoster,
                jobAssignmentCancellationTypes,
            });
        }
    }

    rowClicked = async (params) => {
        const view = params?.node?.data;
        if (view?.employeeId) {
            const viewData = await util.fetch.post(
                ApiRoutes.employee.rosterView(view.employeeId),
                { date: view.date },
            );

            const assignment_rows = [
                ...viewData.filter((x) => x.type === 'Assignment'),
            ];
            const cancel_rows = [
                ...viewData.filter((x) => x.type === 'Cancellation'),
            ];

            const assignments = [
                ...new Set(
                    assignment_rows.map((x) => ({
                        assignmentDate: x.assignmentDateTime,
                        confirmationStatus: x.confirmationStatus,
                        confirmedOn: x.confirmedOn,
                        customerId: x.customerId,
                        customer: x.customer,
                        dispatchCompanyId: x.dispatchCompanyId,
                        dispatchLocation: x.dispatchLocation,
                        employee: x.employee,
                        employeeDispatchCompanyId: x.employeeDispatchCompanyId,
                        employeeDispatchLocation: x.employeeDispatchLocation,
                        employeeId: x.employeeDispatchLocation,
                        employeeUserId: x.employeeUserId,
                        equipment:
                            [
                                ...new Set(
                                    assignment_rows.map(
                                        (x) => `${x.equipment} (${x.equipmentType})`,
                                    ),
                                ),
                            ] ?? [],
                        groupName: x.groupName,
                    })),
                ),
            ];

            const cancellations = [
                ...new Set(
                    cancel_rows.map((x) => ({
                        assignmentDate: x.assignmentDateTime,
                        cancellationReason: x.cancellationReason,
                        cancelledOn: x.cancelledOn,
                        customerId: x.customerId,
                        customer: x.customer,
                        dispatchCompanyId: x.dispatchCompanyId,
                        dispatchLocation: x.dispatchLocation,
                    })),
                ),
            ];

            const viewDataProcessed = {
                employee: viewData[0].employee,
                assignments,
                cancellations,
            };

            this.setState({
                showEmployeeDetails: true,
                selectedEmployee: { ...viewDataProcessed },
            });
        }
    };

    render() {
        const {
            rowData,
            gridOptions,
            selectedEmployee,
            scoreboardData,
            dailyAttendanceEvents,
            showLongTermStatusCountOnRoster,
            longTermStatuses,
            loading,
            loadingData,
            saving,
            gridApi,
            dataSource,
            gridStatus,
            showEmployeeDetails,
        } = {
            ...this.state,
        };

        if (loading) {
            return <LinearProgress variant="indeterminate" color="secondary" />;
        }

        const displayJobAssignmentCancellationTypes = this.getDisplayJobAssignmentCancellationTypes();

        const assignmentCount = (selectedEmployee?.assignments ?? []).length;
        const cancelCount = (selectedEmployee?.cancellations ?? []).length;

        const crewCountClassAddendum = !dailyAttendanceEvents || !dailyAttendanceEvents.length
            ? 'pb-3'
            : '';

        return (
            <PageWrap id="dailyRosterViewPage">
                <PageHeading>
                    <FontAwesomeIcon
                        icon={faCalendarDay}
                        className="mr-2 text-muted"
                    />
                    <span>Roster View</span>
                </PageHeading>
                <div className="d-flex flex-row flex-nowrap flex-fill">
                    <div className="roster-left">
                        <DataGridToolbar
                            loading={loadingData}
                            hideAdd
                            hideExcelButton
                            gridApi={gridApi}
                            gridOptions={gridOptions}
                            dataSource={dataSource}
                            serverExport={{
                                apiPath: ApiRoutes.employees.rosterExport(),
                                filePrefix: 'RosterView',
                            }}
                            serverExportDisabled={
                                !!saving || !!loading || !!loadingData
                            }
                        />
                        {!!scoreboardData && (
                            <FlexCenterRow
                                className={cls('pt-2', crewCountClassAddendum)}
                            >
                                <span
                                    style={{ minWidth: '100px' }}
                                    className="mr-2 text-muted font-weight-bold p-2"
                                >
                Crew Counts:
                                </span>
                                <span
                                    style={{ minWidth: '100px' }}
                                    className="mr-3 badge badge-lg badge-secondary p-2"
                                >
                Unassigned
                                    <span className="ml-2">
                                        {(
                                            scoreboardData.unassigned / 2
                                        ).toFixed(2)}
                                    </span>
                                </span>
                                <span
                                    style={{ minWidth: '100px' }}
                                    className="mr-3 badge badge-lg badge-success p-2"
                                >
                Assigned
                                    <span className="ml-2">
                                        {(scoreboardData.assigned / 2).toFixed(
                                            2,
                                        )}
                                    </span>
                                </span>
                                <span
                                    style={{ minWidth: '100px' }}
                                    className="mr-3 badge badge-lg badge-warning p-2"
                                >
                Cancelled
                                    <span className="ml-2">
                                        {(scoreboardData.cancelled / 2).toFixed(
                                            2,
                                        )}
                                    </span>
                                </span>
                                <span
                                    style={{ minWidth: '100px' }}
                                    className="mr-3 badge badge-lg badge-warning p-2"
                                >
                Unbillable Cancel
                                    <span className="ml-2">
                                        {(
                                            scoreboardData.unbillableCancel / 2
                                        ).toFixed(2)}
                                    </span>
                                </span>
                                {displayJobAssignmentCancellationTypes.map(
                                    (jact, i) => {
                                        const jobAssignmentCancellationTypeCount = this.getJobAssignmentCancellationTypeCount(
                                            jact.id,
                                        );

                                        return (
                                            <span
                                                key={`jact${i}`}
                                                style={{ minWidth: '100px' }}
                                                className="mr-3 badge badge-lg badge-danger p-2"
                                            >
                                                {jact.description}
                                                <span className="ml-2">
                                                    {jobAssignmentCancellationTypeCount.toFixed(
                                                        2,
                                                    )}
                                                </span>
                                            </span>
                                        );
                                    },
                                )}
                            </FlexCenterRow>
                        )}
                        {showLongTermStatusCountOnRoster
                            && longTermStatuses
                            && longTermStatuses.length > 0 && (
                            <FlexCenterRow>
                                <span
                                    style={{ minWidth: '100px' }}
                                    className="mr-2 text-muted font-weight-bold p-2"
                                >
                                Long Term Statuses:
                                </span>
                                {longTermStatuses.map((lts) => (
                                    <span
                                        style={{ minWidth: '100px' }}
                                        className="mr-3 badge badge-lg badge-secondary p-2"
                                        key={`longTermStatus${lts.id}`}
                                    >
                                        {lts.name}
                                  :
                                        <span className="ml-2">
                                            {this.getlongTermStatusCount(
                                                lts.id,
                                            ).toFixed(2)}
                                        </span>
                                    </span>
                                ))}
                            </FlexCenterRow>
                        )}
                        {dailyAttendanceEvents
                            && dailyAttendanceEvents.length > 0 && (
                            <FlexCenterRow className="pb-3">
                                <span
                                    style={{ minWidth: '100px' }}
                                    className="mr-2 text-muted font-weight-bold p-2"
                                >
                                Daily Attendance Events:
                                </span>
                                {dailyAttendanceEvents.map((dae) => (
                                    <span
                                        style={{ minWidth: '100px' }}
                                        className="mr-3 badge badge-lg badge-secondary p-2"
                                        key={dae.id}
                                    >
                                        {dae.name}
                                  :
                                        <span className="ml-2">
                                            {this.getDailyAttendanceCount(
                                                dae.id,
                                            ).toFixed(2)}
                                        </span>
                                    </span>
                                ))}
                            </FlexCenterRow>
                        )}
                        <DataGrid
                            loading={loadingData}
                            domLayout="normal"
                            rowData={rowData}
                            gridOptions={gridOptions}
                            gridStatus={gridStatus}
                        />
                    </div>
                    <div
                        className={`roster-right${
                            showEmployeeDetails ? ' show' : ''
                        }`}
                    >
                        <FlexBetweenRow className="p-3 border-bottom">
                            <span className="view-header-text">
                                <span className="fa fa-lg fa-user mr-2" />
                                {selectedEmployee?.employee}
                            </span>
                            <span
                                onClick={() => this.onCloseView()}
                                title="Close Details"
                                className="cursor-pointer fa fa-lg fa-times text-muted"
                            />
                        </FlexBetweenRow>
                        <FlexColumnStart className="p-3 overflow-y-auto">
                            <FlexStartRow className="mb-1">
                                <span className="text-success">
                                    <strong>Equipment Assignments</strong>
                                </span>
                            </FlexStartRow>
                            <FlexColumnStart>
                                <ListGroup>
                                    {(selectedEmployee?.assignments ?? []).map(
                                        (asn, asnIndex) => (
                                            <ListGroupItem key={asnIndex}>
                                                <FlexColumnStart>
                                                    <FlexBetweenRow
                                                        className="pl-1 pb-1 flex-nowrap"
                                                        key={asnIndex}
                                                    >
                                                        <FlexStartRow>
                                                            <span className="mr-1">
                                                                {`${moment(
                                                                    asn.assignmentDate,
                                                                ).format(
                                                                    'h:mm A',
                                                                )} /`}
                                                            </span>
                                                            <strong>
                                                                {
                                                                    asn.customer
                                                                }
                                                            </strong>
                                                            <span className="badge badge-secondary ml-2">
                                                                {
                                                                    asn.groupName
                                                                }
                                                            </span>
                                                        </FlexStartRow>
                                                        <span
                                                            className={`assignment-status-label font-weight-bold ${asn.confirmationStatus.toLowerCase()}`}
                                                        >
                                                            {
                                                                asn.confirmationStatus
                                                            }
                                                        </span>
                                                    </FlexBetweenRow>
                                                    {!!(asn.equipment ?? [])
                                                        .length && (
                                                        <FlexColumnStart className="pl-3">
                                                            <FlexStartRow className="pb-1">
                                Equipment:
                                                            </FlexStartRow>
                                                            <ListGroup className="pl-3">
                                                                {asn.equipment.map(
                                                                    (
                                                                        e,
                                                                        e_inx,
                                                                    ) => (
                                                                        <div
                                                                            key={
                                                                                e_inx
                                                                            }
                                                                            className="p-1"
                                                                        >
                                                                            <span className="fa fa-chevron-right mr-2" />
                                                                            {
                                                                                e
                                                                            }
                                                                        </div>
                                                                    ),
                                                                )}
                                                            </ListGroup>
                                                        </FlexColumnStart>
                                                    )}
                                                    {/*    <small className="pl-3 w-100 text-left text-muted mt-2">{`Updated on ${asn.confirmedOn} by ${asn.lastModifiedByName}`}</small> */}
                                                </FlexColumnStart>
                                            </ListGroupItem>
                                        ),
                                    )}
                                    {!assignmentCount && (
                                        <ListGroupItem>
                                            <FlexCenterRow className="pl-1 text-center">
                      No equipment assignments for
                      this date.
                                            </FlexCenterRow>
                                        </ListGroupItem>
                                    )}
                                </ListGroup>
                                <FlexStartRow className="mb-1 mt-3">
                                    <span className="text-warning mr-1">
                                        <strong>Cancellations</strong>
                                    </span>
                  /
                                    <span className="text-danger ml-1 mr-1">
                                        <strong>Call Offs</strong>
                                    </span>
                  /
                                    <span className="text-danger ml-1">
                                        <strong>No Shows</strong>
                                    </span>
                                </FlexStartRow>
                                <FlexColumnStart>
                                    <ListGroup>
                                        {(
                                            selectedEmployee?.cancellations
                                            ?? []
                                        ).map((canc, cancIndex) => (
                                            <ListGroupItem key={cancIndex}>
                                                <FlexColumnStart className="pb-2">
                                                    <FlexBetweenRow
                                                        className="pb-1 flex-nowrap"
                                                        key={cancIndex}
                                                    >
                                                        <FlexStartRow>
                                                            <span className="mr-1">
                                                                {`${moment(
                                                                    canc.assignmentDate,
                                                                ).format(
                                                                    'h:mm A',
                                                                )} /`}
                                                            </span>
                                                            <strong>
                                                                {canc.customer}
                                                            </strong>
                                                        </FlexStartRow>
                                                        <span className="badge badge-danger">
                                                            {
                                                                canc.cancellationReason
                                                            }
                                                        </span>
                                                    </FlexBetweenRow>
                                                    <small className="w-100 text-left text-muted">
                                                        {`on ${moment(
                                                            canc.cancelledOn,
                                                        ).format(
                                                            'M/D/YY, h:mm A',
                                                        )}`}
                                                    </small>
                                                </FlexColumnStart>
                                            </ListGroupItem>
                                        ))}
                                        {!cancelCount && (
                                            <ListGroupItem>
                                                <FlexCenterRow className="pl-1 text-center">
                        No cancellations for this
                        date.
                                                </FlexCenterRow>
                                            </ListGroupItem>
                                        )}
                                    </ListGroup>
                                </FlexColumnStart>
                            </FlexColumnStart>
                        </FlexColumnStart>
                    </div>
                </div>
            </PageWrap>
        );
    }
}
export default withRouter(DailyRosterView);
