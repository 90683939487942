import React from 'react';
import cls from 'classnames';
import DailyAttendanceEventCard from './DailyAttendanceEventCard';
import { RemoveDailyAttendanceEvent } from './DailyAttendanceEvent';

const DailyAttendanceDispatchSink = (props) => {
    const {
        dailyAttendanceEvents,
        employeeDailyAttendanceEvents,
        selectedWeekRangeDays,
        areAttendanceEventsEnabled,
        loading,
        staffLoading,
        resourcesLoading,
        staffTabActive,
        jobsLoading,
    } = props;

    if (
        loading
          || staffLoading
          || resourcesLoading
          || !staffTabActive
          || !areAttendanceEventsEnabled
          || !dailyAttendanceEvents
          || !dailyAttendanceEvents.length
          || !selectedWeekRangeDays
          || selectedWeekRangeDays.length != 1
    ) {
        return null;
    }

    return (
        <div
            id="dispatchBottom"
            className={cls({
                'pointer-events-none': jobsLoading,
            })}
        >
            <div className="dailyAttendanceEventsSink">
                <legend className="dailyAttendanceEventLegend">
          Attendance
                </legend>

                <div className="dailyAttendanceEventsContainer">
                    {dailyAttendanceEvents
                        .concat([new RemoveDailyAttendanceEvent()])
                        .map((dae) => (
                            <DailyAttendanceEventCard
                                key={`dailyAttendanceEvent-${dae.id}`}
                                dailyAttendanceEvent={dae}
                                employeeDailyAttendanceEvents={
                                    employeeDailyAttendanceEvents
                                }
                            />
                        ))}
                </div>
            </div>
        </div>
    );
};

export default DailyAttendanceDispatchSink;
