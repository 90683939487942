import React from 'react';
import { faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, Input } from 'reactstrap';
import { BaseFormViewModel } from '../common/ViewModel';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import {
    FormLabel,
    onFieldChange,
    onReactSelectChanged,
    toasty,
    ValidationErrorMessage,
} from '../common/forms/FormElements';
import { Equipment } from './Equipment';
import CommonContext, { ApiRoutes } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';

export class EquipmentForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        const stateBase = {
            equipment: this.props.equipment ?? new Equipment(),
            equipmentTypes: [],
            dispatchLocations: [],
            equipmentStatuses: [],
            employeesAssignable: [],
            equipmentAssignable: [],
            equipmentAssignableTypes: [],
            showEmployeeAssignment: false,
            showEquipmentAssignment: false,
            ...new BaseFormViewModel(),
        };

        this.state = stateBase;
        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount() {
        const { show } = this.props;

        if (show) {
            this.open();
        }
    }

    onChange = onFieldChange;

    onClose = () => {
        this.resetForm();
        this.props.toggleShow(false);
        this.context.setFormOpened(false);
        this.props.onClose();
    };

    onDelete = async (e) => {
        const { equipment } = this.state;
        const response = await util.fetch.delete(
            ApiRoutes.equipment.delete(equipment.id),
        );
        if (response) {
            this.toggleShow(false);
            this.onClose(response);
        }
    };

    onDispatchLocationChanged = async (selectedItem) => {
        const { equipment, equipmentAssignableTypes } = { ...this.state };
        let employeesAssignable = [];
        let equipmentAssignable = [];

        equipment.dispatchLocationId = selectedItem == null ? '' : selectedItem.value ?? '';

        if (equipment.dispatchLocationId > 0 && equipment.equipmentTypeId > 0) {
            const [dispatchEmployees] = await Promise.all([
                util.fetch.get(
                    ApiRoutes.employees.byDispatchLocationAndEquipmentType(
                        equipment.dispatchLocationId,
                        equipment.equipmentTypeId,
                    ),
                ),
            ]);

            // Only allow assignment to assignable types as retrieved from equipment type dropdown cascade
            const dispatchEquipment = [
                ...equipmentAssignableTypes.filter(
                    (x) => x.dispatchLocationId === equipment.dispatchLocationId,
                ),
            ];

            employeesAssignable = dispatchEmployees ?? [];
            equipmentAssignable = dispatchEquipment ?? [];
        }

        this.setState({
            equipment,
            employeesAssignable,
            equipmentAssignable,
        });
    };

    onEquipmentTypeChanged = async (selectedItem) => {
        const eq = this.state.equipment;
        let employeeAssignment = false;
        let equipmentAssignment = false;
        const employeesAssignable = [];
        let equipmentAssignableTypes = [];
        const hasGroups = !!((selectedItem ?? {}).groups ?? []).length;
        const hasRequiredEquipment = !!((selectedItem ?? {}).requiredEquipment ?? []).length > 0;

        // Check max assigned

        employeeAssignment = !!hasGroups && !hasRequiredEquipment;
        equipmentAssignment = !hasGroups && !!hasRequiredEquipment;

        if (hasGroups) {
            // let data = await util.fetch.post(ApiRoutes.employees.byGroups(), selectedItem.groups);
            // employeesAssignable = data ?? [];
        }

        if (hasRequiredEquipment) {
            const type = selectedItem.requiredEquipment.map((e) => e.id)[0];
            const data = await util.fetch.js(ApiRoutes.equipment.byType(type));
            equipmentAssignableTypes = data ?? [];
        }

        eq.equipmentTypeId = selectedItem == null ? '' : selectedItem.id ?? '';
        eq.dispatchLocationId = null; // reset the dispatch location, to reset cascade options/assignable equipment.

        this.setState({
            equipment: eq,
            showEmployeeAssignment: employeeAssignment,
            showEquipmentAssignment: equipmentAssignment,
            employeesAssignable,
            equipmentAssignableTypes,
            equipmentAssignable: [], // reset assignable equipment awaiting dispatch location selection
        });
    };

    onSelectChange = onReactSelectChanged;

    onSubmit = async (e) => {
        const { equipment } = this.state;
        this.setState((state) => ({ errors: {}, saving: true }));

        // Is this POST or PUT?
        const url = equipment.id
            ? ApiRoutes.equipment.update(equipment.id)
            : ApiRoutes.equipment.create();
        const fetch_cte = equipment.id ? util.fetch.put : util.fetch.post;

        const response = await fetch_cte(url, equipment).catch(
            this.handleSaveError,
        );

        if (response) {
            this.onClose();
            toasty.success(
                'Equipment Saved',
                `Equipment [${equipment.description}] saved successfully.`,
            );
        }
    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    open = async (equipmentId) => {
        this.setState({ loading: true });

        await this.populateState();

        let equipment = null;

        this.resetForm();

        if (equipmentId) {
            equipment = await util.fetch.js(
                ApiRoutes.equipment.byId(equipmentId),
            );
        } else {
            equipment = new Equipment();
        }

        this.setState({ equipment }, async () => {
            this.props.toggleShow(true);
            this.context.setFormOpened(true);

            if (equipmentId) {
                // step through cascaded options on the way in
                const { equipmentTypes, dispatchLocations } = { ...this.state };
                const eqType = equipmentTypes.find(
                    (x) => x.id === equipment.equipmentTypeId,
                );
                const dispatchLoc = dispatchLocations.find(
                    (x) => x.value === equipment.dispatchLocationId,
                );

                // These need to be triggered in this order, otherwise the dispatchLocationId may get nulled erroneously
                // TODO This conflicting logic should be fixed
                await this.onEquipmentTypeChanged({ ...eqType });
                await this.onDispatchLocationChanged({ ...dispatchLoc });
            }

            this.setState({ loading: false });
        });
    };

    async populateState() {
        const [equipmentTypes, dispatchLocations, equipmentStatuses] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.equipmentTypes()),
            util.fetch.js(ApiRoutes.typeAheads.dispatchLocations()),
            util.fetch.js(ApiRoutes.typeAheads.equipmentTypeStatuses()),
        ]);

        this.setState((state) => ({
            equipmentTypes,
            dispatchLocations,
            equipmentStatuses,
        }));
    }

    resetForm = () => {
        this.setState({
            showEmployeeAssignment: false,
            showEquipmentAssignment: false,
            formValidated: false,
        });
    };

    render() {
        const {
            equipmentTypes,
            dispatchLocations,
            formValidated,
            equipmentStatuses,
            validationMessage,
            employeesAssignable,
            equipmentAssignable,
        } = this.state;

        const eq = this.state.equipment;

        return (
            <SlideForm
                loading={this.state.loading}
                show={this.props.show}
                id="equipmentForm"
                formIcon={faToolbox}
                formTitle={
                    !!parseInt(eq.id ?? 0) > 0
                        ? 'Edit Equipment'
                        : 'Add Equipment'
                }
                ref={this.formRef}
                setIsValidated={(value) => {
                    this.setState({ formValidated: value });
                }}
                isValidated={formValidated}
                onSubmit={this.onSubmit}
                onClose={this.onClose}
                onSave={this.onSave}
                onDelete={this.onDelete}
                errors={this.state.errors}
                onClearErrors={this.onClearErrors}
                validationMessage={validationMessage}
            >
                <FormGroup>
                    <FormLabel
                        htmlFor="equipmentTypeId"
                        text="Equipment Type"
                        required
                    />
                    <ValidatedSelect
                        id="equipmentTypeId"
                        name="equipmentTypeId"
                        required
                        options={equipmentTypes}
                        value={
                            equipmentTypes.find(
                                (i) => i.id === eq.equipmentTypeId,
                            ) ?? ''
                        }
                        getOptionLabel={(option) => option.description}
                        getOptionValue={(option) => option.id}
                        onChange={this.onEquipmentTypeChanged}
                        validationMessage="The equipment type is required."
                    />
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="identifier"
                        text="Number"
                        required
                    />
                    <Input
                        id="identifier"
                        name="equipment.identifier"
                        value={eq.identifier ?? ''}
                        onChange={this.onChange}
                        placeholder="Enter number"
                        type="text"
                        required
                    />
                    <ValidationErrorMessage>
            Number is required.
                    </ValidationErrorMessage>
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="description"
                        text="Description"
                        required
                    />
                    <Input
                        id="description"
                        name="equipment.description"
                        value={eq.description ?? ''}
                        onChange={this.onChange}
                        placeholder="Enter description"
                        type="text"
                        required
                    />
                    <ValidationErrorMessage>
            Description is required.
                    </ValidationErrorMessage>
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="dispatchLocationId"
                        text="Dispatching"
                        required
                    />
                    <ValidatedSelect
                        id="dispatchLocationId"
                        name="equipment.dispatchLocationId"
                        required
                        options={dispatchLocations}
                        value={
                            dispatchLocations.find(
                                (i) => i.value === eq.dispatchLocationId,
                            ) ?? ''
                        }
                        onChange={this.onDispatchLocationChanged}
                        validationMessage="Dispatching Office is required."
                    />
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="equipmentStatusId"
                        text="Status"
                        required
                    />
                    <ValidatedSelect
                        id="equipmentStatusId"
                        name="equipmentStatusId"
                        required
                        options={equipmentStatuses}
                        value={
                            equipmentStatuses.find(
                                (i) => i.value === eq.equipmentStatusId,
                            ) ?? ''
                        }
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        onChange={(sel) => {
                            const value = (sel ?? {}).value ?? '';
                            this.setState(
                                (state) => (
                                    (state.equipment.equipmentStatusId = value),
                                    state
                                ),
                            );
                        }}
                        validationMessage="Status is required."
                    />
                </FormGroup>

                {this.state.showEmployeeAssignment && (
                    <FormGroup>
                        <FormLabel
                            htmlFor="employeeAssignmentId"
                            text="Employee Assigned"
                        />
                        <ValidatedSelect
                            id="employeeAssignmentId"
                            name="employeeAssignmentId"
                            options={employeesAssignable}
                            value={
                                employeesAssignable.find(
                                    (i) => i.id === eq.employeeAssignedId,
                                ) ?? ''
                            }
                            getOptionLabel={(option) => option.fullName}
                            getOptionValue={(option) => option.id}
                            onChange={(sel) => {
                                const value = (sel ?? {}).id ?? '';
                                this.setState(
                                    (state) => (
                                        (state.equipment.employeeAssignedId = value),
                                        state
                                    ),
                                );
                            }}
                        />
                    </FormGroup>
                )}

                {this.state.showEquipmentAssignment && (
                    <FormGroup>
                        <FormLabel
                            htmlFor="equipmentAssignmentId"
                            text="Equipment Assigned"
                        />
                        <ValidatedSelect
                            id="equipmentAssignmentId"
                            name="equipmentAssignmentId"
                            isDisabled={
                                !(this.state.equipment ?? {}).dispatchLocationId
                            }
                            options={equipmentAssignable}
                            value={
                                equipmentAssignable.find(
                                    (i) => i.id === eq.equipmentAssignedId,
                                ) ?? ''
                            }
                            getOptionLabel={(option) => option.description}
                            getOptionValue={(option) => option.id}
                            onChange={(sel) => {
                                const value = (sel ?? {}).id ?? '';
                                this.setState(
                                    (state) => (
                                        (state.equipment.equipmentAssignedId = value),
                                        state
                                    ),
                                );
                            }}
                        />
                    </FormGroup>
                )}
            </SlideForm>
        );
    }
}
