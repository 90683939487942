import React from 'react';
import { LinearProgress } from '@mui/material';
import { withRouter } from 'react-router-dom';
import {
    createDataSource, createGridOptions, DataGrid, LinkCellRenderer, TextFilterDefaults, indexCellRenderer,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { PageHeading, PageWrap } from '../common/forms/FormElements';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import { util } from '../Util';

class USCountyGroupsIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rowData: [],
        };

        this.onAddCountyGroup = this.onAddCountyGroup.bind(this);
        this.onEditCountyGroup = this.onEditCountyGroup.bind(this);
    }

    componentDidMount() { return this.populateState(); }

    onAddCountyGroup = () => {
        this.props.history.push(`${AppNavPaths.USCountyGroupNew}`);
    };

    onEditCountyGroup = (id) => {
        this.props.history.push(`${AppNavPaths.USCountyGroup}/${id}`);
    };

    async populateState() {
        const [groupTypes] = await Promise.all([
            util.fetch.js(ApiRoutes.USCounties.groupTypes()),
        ]);

        const gridOptions = createGridOptions(this);

        const groupTypeFilterParams = {
            suppressFilterButton: true,
            options: groupTypes,
            optionsLabel: 'description',
            optionsValue: 'id',
        };

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
        };

        gridOptions.columnDefs = [
            {
                flex: 0,
                maxWidth: 80,
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'Name',
                sortable: true,
                headerName: 'Name',
                field: 'description',
                sort: { direction: 'asc', priority: 0 },
                cellRenderer: 'nameRenderer',
                cellRendererParams: {
                    clicked: this.onEditCountyGroup,
                    nameField: 'description',
                    idField: 'id',
                    title: 'View this County Group',
                },
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilter: true,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'GroupType',
                headerName: 'Type',
                sortable: true,
                flex: 1.5,
                field: 'groupType',
                tooltipField: 'groupType',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: groupTypeFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: groupTypeFilterParams,
            },
        ];

        const dataSource = createDataSource(ApiRoutes.USCounties.groups(), gridOptions);

        this.setState((state) => ({
            loading: false,
            gridOptions,
            dataSource,
        }));
    }

    render() {
        if (this.state.loading) return (<LinearProgress variant="indeterminate" color="secondary" />);

        const { rowData, gridOptions } = this.state;

        return (
            <PageWrap>
                <PageHeading>
                    <span>County Groups/Zones</span>
                </PageHeading>
                <DataGridToolbar
                    entity="county_group"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    onAdd={this.onAddCountyGroup}
                    addLabel="Add County Group"
                    hideExcelButton
                />
                <DataGrid
                    domLayout="normal"
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                />
            </PageWrap>
        );
    }
}
export default withRouter(USCountyGroupsIndex);
